import { selector } from "recoil";
import { micState } from "./session.atom";

function compareRequestOrder(a, b) {
  // First, compare objects based on the requestOrder property
  if (a.requestOrder !== b.requestOrder) {
    // If a's requestOrder is zero, move it to the end
    if (a.requestOrder === 0) return 1;
    // If b's requestOrder is zero, move it to the end
    if (b.requestOrder === 0) return -1;
    // Otherwise, compare normally based on requestOrder
    return a.requestOrder - b.requestOrder;
  }

  // If requestOrder is equal, sort alphabetically based on the name property
  return a.name.localeCompare(b.name);
}

const updateOrder = (array) => {
  const newArray = array.map((obj) => ({ ...obj }));
  // Sort the new array without modifying the original objects
  newArray.sort(compareRequestOrder);
  return newArray;
};

export const micsByRequestOrder = selector({
  key: "sortByRequestorder",
  get: ({ get }) => {
    const mics = get(micState);
    if (mics.length > 0) {
      if (mics.some((mic) => mic.requestOrder > 0)) {
        const sorted = updateOrder(mics);
        return sorted;
      } else {
        return mics;
      }
    } else {
      return mics;
    }
  },
});
