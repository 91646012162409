import { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { useSetRecoilState } from "recoil";

// atoms
import {
  prevTopicState,
  menuChoiceState,
  testModeState,
  speakerStatusState,
  speakerTimeNumberState,
  refreshState,
  topicPreviewState,
  endingNotesModalState,
} from "../atoms/globals.atom";
import {
  activeTopicState,
  activeDetailsState,
  countdownState,
  durationState,
  micRequestState,
  loggedInState,
  motionStatusState,
  motionedByState,
  voteStatusState,
  memberVotedState,
  votingResultsState,
  fsVotingResultsState,
  liveVotingResultsState,
  enableLoginState,
  micState,
  sessionState,
  voterState,
  submittedVotesState,
  liveRollCallState,
  topicSpeakersState,
  liveSpeakerState,
  activeMotionState,
  sessionBoardState,
  topicMotionsList,
  podiumMicState,
} from "../atoms/session.atom";
import { topicSectionState } from "../atoms/settings.atom";
import { userState, loggedOutState } from "../atoms/user.atom";
import { motionNotesState } from "../atoms/notes.atom";

export const useSocket = (server, location) => {
  const socketRef = useRef();
  const setActiveTopic = useSetRecoilState(activeTopicState);
  const setCountdown = useSetRecoilState(countdownState);
  const setDuration = useSetRecoilState(durationState);
  const setMicRequest = useSetRecoilState(micRequestState);
  const setLoggedIn = useSetRecoilState(loggedInState);
  const setMotionStatus = useSetRecoilState(motionStatusState);
  const setMotionedBy = useSetRecoilState(motionedByState);
  const setVoteStatus = useSetRecoilState(voteStatusState);
  const setMemberVoted = useSetRecoilState(memberVotedState);
  const setVotingResults = useSetRecoilState(votingResultsState);
  const setFsVotingResults = useSetRecoilState(fsVotingResultsState);
  const setLiveVoteResults = useSetRecoilState(liveVotingResultsState);
  const setEnableLogin = useSetRecoilState(enableLoginState);
  const setActiveDetails = useSetRecoilState(activeDetailsState);
  const setPrevTopic = useSetRecoilState(prevTopicState);
  const setMenuChoice = useSetRecoilState(menuChoiceState);
  const setMicState = useSetRecoilState(micState);
  const setTestMode = useSetRecoilState(testModeState);
  const setSession = useSetRecoilState(sessionState);
  const setUser = useSetRecoilState(userState);
  const setVoterState = useSetRecoilState(voterState);
  const setSubmittedVotes = useSetRecoilState(submittedVotesState);
  const setSpeakerStatus = useSetRecoilState(speakerStatusState);
  const setSpeakerTimerNumber = useSetRecoilState(speakerTimeNumberState);
  const setRefreshPage = useSetRecoilState(refreshState);
  const setLiveRollCall = useSetRecoilState(liveRollCallState);
  const setTopicSpeakers = useSetRecoilState(topicSpeakersState);
  const setLiveSpeaker = useSetRecoilState(liveSpeakerState);
  const setTopicPreview = useSetRecoilState(topicPreviewState);
  const setActiveMotion = useSetRecoilState(activeMotionState);
  const setTopicSection = useSetRecoilState(topicSectionState);
  const setEndingNotesModal = useSetRecoilState(endingNotesModalState);
  const setSessionBoard = useSetRecoilState(sessionBoardState);
  const setLoggedOut = useSetRecoilState(loggedOutState);
  const setTopicMotionList = useSetRecoilState(topicMotionsList);
  const setMotionNotes = useSetRecoilState(motionNotesState);
  const setPodiumMic = useSetRecoilState(podiumMicState);

  useEffect(() => {
    if (server !== undefined) {
      function nextTopic() {
        setTopicPreview(false);
        setMotionStatus();
        setActiveMotion();
        setMotionedBy([]);
        setMemberVoted();
        setVoteStatus(false);
        setVotingResults({});
        setSubmittedVotes([]);
        setTopicMotionList([]);
        setMotionNotes("");
      }

      function handleVotingResults(value) {
        setVotingResults(value);
        setFsVotingResults(value);
        setLiveVoteResults(value);
      }

      function handleMeetingEnded() {
        // setRefresh(true);
        setTimeout(() => {
          setMicState([]);
          setSession();
          setUser();
        }, [1000]);
        setTimeout(() => {
          setActiveTopic();
          setActiveDetails();
          setPrevTopic();
          setDuration("0 min");
          setMicRequest();
          setActiveMotion();
          setMotionStatus();
          setLoggedIn([]);
          setMotionStatus();
          setVoteStatus(false);
          setMemberVoted();
          setVotingResults({});
          setFsVotingResults({});
          setLiveVoteResults({});
          setEnableLogin(false);
          setMotionedBy([]);
          setMenuChoice(2);
          setSubmittedVotes([]);
          // setMicState([]);
          setVoterState([]);
          setEndingNotesModal(false);
          setSessionBoard();
          setTopicMotionList([]);
          setMotionNotes("");
        }, [2000]);
      }

      socketRef.current = io(`${server}/session`, {
        extraHeaders: {
          authorization: "YWdlbmRhbGluazpBZ2VuZGFMaW5r",
        },
      });

      socketRef.current.on("connect", async () => {
        // console.log("connected");
        if (location !== "/setup") {
          socketRef.current.emit("cache");
        }
      });

      socketRef.current.on("settingsRefresh", () => {
        setRefreshPage(true);
        setTimeout(() => {
          setRefreshPage(false);
        }, [200]);
      });

      socketRef.current.on("showLogin", (payload) => {
        setSessionBoard(payload.board);
        setEnableLogin(payload.status);
        setTestMode(payload.testMode);
        setLiveRollCall(true);
      });

      socketRef.current.on("rollCallDisable", (payload) => {
        setLiveRollCall(false);
      });

      socketRef.current.on("manualLogin", (payload) => {
        let newArray = [];
        let newArray2 = [];
        for (const member of payload.loggedIn) {
          let data = {};
          data.id = member._id;
          data.photoUrl = member.photoUrl;
          data.name = member.firstName + " " + member.lastName;
          data.status = member.micStatus;
          data.loggedIn = member.loggedIn;
          data.seat = member.seat;
          data.district = member.district;
          data.requestOrder = member.requestOrder;
          data.adminRole = member.adminRole;
          data.bypassTimer = member.bypassTimer;
          data.managerRole = member.managerRole;
          data.title = member.title;
          data.round = member.round;
          newArray.push(data);
          if (member.votingRole) {
            let votingData = {};
            votingData.id = member._id;
            votingData._id = member._id;
            votingData.photoUrl = member.photoUrl;
            votingData.name = member.firstName + " " + member.lastName;
            votingData.voted = "none";
            votingData.loggedIn = member.loggedIn;
            votingData.district = member.district;
            votingData.adminRole = member.adminRole;
            newArray2.push(votingData);
          }
        }
        setMicState(newArray);
        setVoterState(newArray2);
      });

      socketRef.current.on("addSpeaker", (payload) => {
        setTopicSpeakers(payload.topic.speakers);
      });

      socketRef.current.on("podiumButton", (payload) => {
        if (payload === "podiumOn") {
          setPodiumMic(true);
        } else {
          setPodiumMic(false);
        }
      });

      socketRef.current.on("endingNotes", (payload) => {
        // console.log("endingNotes");
        setEndingNotesModal(true);
      });

      socketRef.current.on("agendaSelection", (payload) => {
        nextTopic();
        setActiveTopic(payload.id);
        setActiveDetails(payload.data);
        setTopicSection(0);
        setSubmittedVotes([]);
        if (payload.data.speakers.length > 0) {
          setTopicSpeakers(payload.data.speakers);
        } else {
          setTopicSpeakers([]);
        }
        if (payload.prevTopic) {
          setPrevTopic(payload.prevTopic);
        }
        if (payload.mics?.length > 0) {
          setMicState(payload.mics);
        }
      });

      socketRef.current.on("updateSpeakers", (payload) => {
        // used for manager who is picking topics to update with the most current speaker list
        setTopicSpeakers(payload.speakers);
        if (payload.mics?.length > 0) {
          setMicState(payload.mics);
        }
      });

      socketRef.current.on("consentSelected", (payload) => {
        nextTopic();
        setActiveTopic(payload.id);
        setActiveDetails(payload.data);
        setTopicSection(0);
        if (payload.data.speakers.length > 0) {
          setTopicSpeakers(payload.data.speakers);
        } else {
          setTopicSpeakers([]);
        }
        if (payload.prevTopic) {
          setPrevTopic(payload.prevTopic);
        }
      });

      socketRef.current.on("timerCount", (payload) => {
        setCountdown(payload.time);
      });

      socketRef.current.on("speakerCall", (payload) => {
        setSpeakerStatus(true);
        setLiveSpeaker(payload.speaker);
      });

      // event used for memberTimer setting
      socketRef.current.on("memberCall", (payload) => {
        setSpeakerStatus(true);
        setLiveSpeaker(payload.user);
      });

      socketRef.current.on("liveTimerClear", (payload) => {
        setSpeakerStatus(false);
        setLiveSpeaker();
      });

      socketRef.current.on("speakerRemove", (payload) => {
        setTopicSpeakers(payload.speaker);
      });

      socketRef.current.on("serverTimer", (payload) => {
        setCountdown(payload.time);
        setSpeakerTimerNumber(payload.number);
      });

      socketRef.current.on("speakerTimer", (payload) => {
        setSpeakerStatus(payload.status);
        if (!payload.status) {
          setLiveSpeaker();
        }
      });

      socketRef.current.on("duration", (payload) => {
        setDuration(payload.time);
      });

      socketRef.current.on("memberMicRequest", (payload) => {
        setMicRequest(payload);
      });

      socketRef.current.on("micStatusUpdate", (payload) => {
        let newArray = [];
        for (const member of payload.mics) {
          let data = {};
          data.id = member._id;
          data.photoUrl = member.photoUrl;
          data.name = member.firstName + " " + member.lastName;
          data.status = member.micStatus;
          data.loggedIn = member.loggedIn;
          data.seat = member.seat;
          data.district = member.district;
          data.requestOrder = member.requestOrder;
          data.adminRole = member.adminRole;
          data.bypassTimer = member.bypassTimer;
          data.managerRole = member.managerRole;
          data.title = member.title;
          data.round = member.round;
          newArray.push(data);
        }
        setMicState(newArray);
      });

      socketRef.current.on("micEndVote", (payload) => {
        setMicState(payload.mics);
      });

      socketRef.current.on("newMemberLogIn", (payload) => {
        let newArray = [];
        let newArray2 = [];
        for (const member of payload.loggedIn) {
          let data = {};
          data.id = member._id;
          data.photoUrl = member.photoUrl;
          data.name = member.firstName + " " + member.lastName;
          data.status = member.micStatus;
          data.loggedIn = member.loggedIn;
          data.seat = member.seat;
          data.district = member.district;
          data.requestOrder = member.requestOrder;
          data.adminRole = member.adminRole;
          data.bypassTimer = member.bypassTimer;
          data.managerRole = member.managerRole;
          data.title = member.title;
          data.round = member.round;
          newArray.push(data);
          if (member.votingRole) {
            let votingData = {};
            votingData.id = member._id;
            votingData._id = member._id;
            votingData.photoUrl = member.photoUrl;
            votingData.name = member.firstName + " " + member.lastName;
            votingData.voted = "none";
            votingData.loggedIn = member.loggedIn;
            votingData.district = member.district;
            votingData.adminRole = member.adminRole;
            newArray2.push(votingData);
          }
        }
        setMicState(newArray);
        setVoterState(newArray2);
      });

      socketRef.current.on("memberLogout", (payload) => {
        let newArray = [];
        let newArray2 = [];
        for (const member of payload.loggedIn) {
          let data = {};
          data.id = member._id;
          data.photoUrl = member.photoUrl;
          data.name = member.firstName + " " + member.lastName;
          data.status = member.micStatus;
          data.loggedIn = member.loggedIn;
          data.seat = member.seat;
          data.district = member.district;
          data.requestOrder = member.requestOrder;
          data.adminRole = member.adminRole;
          data.bypassTimer = member.bypassTimer;
          data.managerRole = member.managerRole;
          data.title = member.title;
          data.round = member.round;
          newArray.push(data);
          if (member.votingRole) {
            let votingData = {};
            votingData.id = member._id;
            votingData._id = member._id;
            votingData.photoUrl = member.photoUrl;
            votingData.name = member.firstName + " " + member.lastName;
            votingData.voted = "none";
            votingData.loggedIn = member.loggedIn;
            votingData.district = member.district;
            votingData.adminRole = member.adminRole;
            newArray2.push(votingData);
          }
        }
        setMicState(newArray);
        setVoterState(newArray2);
      });

      socketRef.current.on("manualLogout", (payload) => {
        let newArray = [];
        let newArray2 = [];
        for (const member of payload.loggedIn) {
          let data = {};
          data.id = member._id;
          data.photoUrl = member.photoUrl;
          data.name = member.firstName + " " + member.lastName;
          data.status = member.micStatus;
          data.loggedIn = member.loggedIn;
          data.seat = member.seat;
          data.district = member.district;
          data.requestOrder = member.requestOrder;
          data.adminRole = member.adminRole;
          data.bypassTimer = member.bypassTimer;
          data.managerRole = member.managerRole;
          data.title = member.title;
          data.round = member.round;
          newArray.push(data);
          if (member.votingRole) {
            let votingData = {};
            votingData.id = member._id;
            votingData._id = member._id;
            votingData.photoUrl = member.photoUrl;
            votingData.name = member.firstName + " " + member.lastName;
            votingData.voted = "none";
            votingData.loggedIn = member.loggedIn;
            votingData.district = member.district;
            votingData.adminRole = member.adminRole;
            newArray2.push(votingData);
          }
        }
        setMicState(newArray);
        setVoterState(newArray2);
        setLoggedOut(payload.user);
        setTimeout(() => {
          setLoggedOut();
        }, 750);
      });

      socketRef.current.on("motioned", (payload) => {
        setMotionStatus(payload.status);
        setMotionedBy(payload.motions);
      });

      socketRef.current.on("newMotionType", (payload) => {
        setTopicMotionList(payload.motionList);
        setSubmittedVotes([]);
      });

      socketRef.current.on("newMotion", (payload) => {
        setTopicMotionList(payload.motionList);
        setActiveMotion(payload.motion._id);
        setMotionStatus(payload.motion.motionType);
        // if (payload.motionedBy.length === 1) {
        //   setActiveMotion(payload.motion._id);
        //   setMotionStatus(payload.motion.motionType);
        // }
        if (payload.cachedMotion) {
          setActiveMotion(payload.motion._id);
        }
        setMotionedBy(payload.motionedBy);
      });

      socketRef.current.on("deleteMotion", (payload) => {
        setTopicMotionList(payload.motionList);
        setActiveMotion();
        setMotionedBy([]);
        setMotionStatus();
        // setMotionStatus(payload.status);
      });

      // sent when a vote is ended
      socketRef.current.on("voteStatus", (payload) => {
        if (payload.status === true) {
          setVoteStatus(payload.status);
          setActiveMotion(payload.motion._id);
          setMotionStatus(payload.motion.motionType);

          const motionedBy = [
            payload.motion.motionedBy,
            payload.motion.secondedBy,
          ];
          setMotionedBy(motionedBy);
        } else if (payload.results) {
          setMemberVoted("");
          setVoteStatus(payload.status);
          handleVotingResults(payload.results);
          setTopicMotionList(payload.motionList);
        }
      });

      socketRef.current.on("quickVoteYes", (payload) => {
        setMotionStatus();
        setActiveMotion();
        setMotionedBy([]);
        setTopicMotionList(payload.motionList);
      });

      socketRef.current.on("resultsClear", (payload) => {
        setVotingResults({});
        setFsVotingResults({});
        setMotionStatus();
        setActiveMotion();
        setMotionedBy([]);
      });

      socketRef.current.on("liveResultsClear", (payload) => {
        setLiveVoteResults({});
        setActiveMotion();
        setMotionedBy([]);
        setSubmittedVotes([]);
      });

      socketRef.current.on("voteSubmit", (payload) => {
        setMemberVoted(payload.member);
        setSubmittedVotes(payload.votes);
      });

      socketRef.current.on("votedAllYes", (payload) => {
        setSubmittedVotes(payload.votes);
      });

      socketRef.current.on("sessionEnded", (payload) => {
        handleMeetingEnded();
      });

      socketRef.current.on("error", (err) => {
        console.log("socket error");
        console.log(err);
        socketRef.current.connect();
      });

      // socketRef.current.on("disconnect", () => {
      //   console.log("socket disconnected");
      // });
      return () => {
        socketRef.current.disconnect();
      };
    }
  }, [server]); // eslint-disable-line react-hooks/exhaustive-deps

  function sendEvent(event, payload) {
    socketRef.current.emit(event, payload);
  }

  function nextTopic() {
    setTopicPreview(false);
    setMotionStatus();
    setMotionedBy([]);
    setMemberVoted();
    setVoteStatus(false);
    setVotingResults({});
    setTopicMotionList([]);
    setMotionNotes("");
  }

  function managerReset() {
    setMicState([]);
    setSession();
    setUser();
    setActiveTopic();
    setActiveDetails();
    setPrevTopic();
    setDuration("0 min");
    setMicRequest();
    setActiveMotion();
    setMotionStatus();
    setLoggedIn([]);
    setMotionStatus();
    setVoteStatus(false);
    setMemberVoted();
    setVotingResults({});
    setFsVotingResults({});
    setLiveVoteResults({});
    setEnableLogin(false);
    setMotionedBy([]);
    setMenuChoice(2);
    setSubmittedVotes([]);
    // setMicState([]);
    setVoterState([]);
    setEndingNotesModal(false);
    setSessionBoard();
    setTopicMotionList([]);
    setMotionNotes("");
  }

  return {
    sendEvent,
    nextTopic,
    managerReset,
  };
};
