import axios from "axios";

export const apiGetSettings = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    };

    const result = await axios
      .get(`${process.env.REACT_APP_MANAGER}/api/entity/${token}`, config)
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response;
          return data;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.log("error api settings apiGetSettings");
    console.log(error);
    return null;
  }
};

export const apiUpdateSettings = async (data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    };
    const result = await axios
      .patch(
        `${process.env.REACT_APP_MANAGER}/api/entity/${token}`,
        { data },
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          return true;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.log("error api settings apiUpdateSettings");
    console.log(error);
    return null;
  }
};
