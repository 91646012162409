import React from "react";
import { motion } from "framer-motion";

import { AiOutlineClose } from "react-icons/ai";

const QueueModal = ({ action }) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <motion.div
            className="absolute inset-0 bg-blueGray-800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={() => action("clear")}
          ></motion.div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          style={{ minHeight: "10rem" }}
        >
          <div className="flex flex-col">
            <div className="flex justify-between items-center border-b border-zinc-500 pb-2">
              <p className="font-semibold text-gray-800 text-xl">
                Queue Action
              </p>
              <AiOutlineClose
                className="h-6 w-6 text-gray-800 cursor-pointer hover:text-gray-600 hover:rotate-180 transform duration-200"
                onClick={() => action("clear")}
              />
            </div>

            <p className="px-4 mt-4 mb-1 text-base xl:text-xl font-medium text-zinc-800 focus:outline-none">
              Do you want to utilize the timed speaking round or are they
              speaking in a general discussion?
            </p>
            <div className="px-4 flex justify-end space-x-6">
              <button
                className="mt-4 inline-flex items-center justify-center px-6 py-3 text-base xl:text-lg font-medium rounded-md text-white bg-blue-500 hover:bg-blue-300 transform duration-200 focus:outline-none"
                onClick={() => {
                  action("general");
                }}
              >
                General
              </button>
              <button
                className="mt-4 inline-flex items-center justify-center px-6 py-3 text-base xl:text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-400 transform duration-200 focus:outline-none"
                onClick={() => {
                  action("timed");
                }}
              >
                Timed
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default QueueModal;
