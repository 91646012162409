import { atom } from "recoil";

export const agendaTimeState = atom({
  key: "agendaTimer",
  default: "0 min",
});

export const testModeState = atom({
  key: "testMode",
  default: false,
});

export const menuChoiceState = atom({
  key: "menuChoice",
  default: 2,
});

export const prevTopicState = atom({
  key: "prevTopic",
  default: null,
});

export const activeTopicIndex = atom({
  key: "activeIndex",
  default: null,
});

export const triggerNextTopic = atom({
  key: "triggerNext",
  default: false,
});

export const speakerStatusState = atom({
  key: "speakerStatus",
  default: false,
});

export const speakerTimeNumberState = atom({
  key: "timerNumber",
  default: 180,
});

export const speakerModalState = atom({
  key: "speakerModal",
  default: false,
});

export const speakerModalContentState = atom({
  key: "speakerModalContent",
  default: null,
});

export const setupMenuChoiceState = atom({
  key: "setupMenuChoice",
  default: 0,
});

export const setupAccessState = atom({
  key: "setupAdmin",
  default: false,
});

export const refreshState = atom({
  key: "refreshPage",
  default: false,
});

export const topicPreviewState = atom({
  key: "topicPreview",
  default: false,
});

export const topicPreviewDetailsState = atom({
  key: "topicPreviewDetails",
  default: null,
});

export const confirmEndState = atom({
  key: "confirmEnd",
  default: false,
});

export const endingNotesModalState = atom({
  key: "endingNotesModal",
  default: false,
});
