import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { managerUserState } from "../../atoms/user.atom";
import { boardsListState, soloSettingsState } from "../../atoms/settings.atom";
import {
  apiAddBoard,
  apiDeleteBoard,
  apiUpdateBoard,
  apiGetBoards,
} from "../../api/backend.options";
import { apiImportBodies } from "../../api/granicus";

import { XCircleIcon } from "@heroicons/react/solid";
import { CloudUploadIcon } from "@heroicons/react/outline";

import Loading from "../Modals/Loading";

const Boards = ({ server }) => {
  const user = useRecoilValue(managerUserState);
  const [newBoard, setNewBoard] = useState("");
  const [loading, setLoading] = useState(false);
  const [boards, setBoards] = useRecoilState(boardsListState);
  const soloSettings = useRecoilValue(soloSettingsState);

  async function handleAdd() {
    const result = await apiAddBoard(
      server,
      {
        name: newBoard,
        manuallyAdded: true,
        civicSync: {
          active: false,
          categoryName: "",
        },
      },
      user?.token
    );
    if (result) {
      let newArray = JSON.parse(JSON.stringify(boards));
      newArray.push(result);
      setBoards(newArray);
    }
    setNewBoard("");
  }

  async function handleDelete(id) {
    const result = await apiDeleteBoard(server, id, user?.token);
    if (result !== "error") {
      const boardResult = await apiGetBoards(soloSettings.backendServerUrl);
      if (Array.isArray(boardResult)) {
        setBoards(boardResult);
      }
    } else {
      // TODO error notification
    }
  }

  async function handleToggle(board, value) {
    // update local value
    const index = boards.findIndex((e) => {
      return e._id === board;
    });
    let newArray = JSON.parse(JSON.stringify(boards));
    newArray[index].granicusSync = value;
    setBoards(newArray);

    // update board via api call
    await apiUpdateBoard(server, board, { granicusSync: value }, user?.token);
  }

  async function handleCivicToggle(board, value) {
    // update local value
    const index = boards.findIndex((e) => {
      return e._id === board;
    });
    let newArray = JSON.parse(JSON.stringify(boards));
    newArray[index].civicSync.active = value;
    const { categoryName } = newArray[index].civicSync;
    setBoards(newArray);

    // update board via api call
    await apiUpdateBoard(
      server,
      board,
      { civicSync: { active: value, categoryName } },
      user?.token
    );
  }

  async function handleTieToggle(board, value) {
    // update local value
    const index = boards.findIndex((e) => {
      return e._id === board;
    });
    let newArray = JSON.parse(JSON.stringify(boards));
    newArray[index].tieBreakerVote = value;
    setBoards(newArray);

    // update board via api call
    await apiUpdateBoard(server, board, { tieBreakerVote: value }, user?.token);
  }

  async function handleCivicName(board, value) {
    // update local value
    const index = boards.findIndex((e) => {
      return e._id === board;
    });
    let newArray = JSON.parse(JSON.stringify(boards));
    newArray[index].civicSync.categoryName = value;
    setBoards(newArray);

    // // update board via api call
    // await apiUpdateBoard(
    //   server,
    //   board,
    //   { civicSync: { active: value, categoryName } },
    //   user?.token
    // );
  }

  async function handleCivicBlur(board) {
    // update local value
    const index = boards.findIndex((e) => {
      return e._id === board;
    });
    // let newArray = JSON.parse(JSON.stringify(boards));
    // newArray[index].civicSync.categoryName = value;
    // setBoards(newArray);

    // // update board via api call
    await apiUpdateBoard(
      server,
      board,
      { civicSync: { ...boards[index].civicSync } },
      user?.token
    );
  }

  async function handleGranicusImport() {
    setLoading(true);
    const result = await apiImportBodies(
      soloSettings.backendServerUrl,
      soloSettings.granicusEntity
    );
    if (result === "success") {
      const boardResult = await apiGetBoards(soloSettings.backendServerUrl);
      if (Array.isArray(boardResult)) {
        setBoards(boardResult);
      }
    } else {
      // TODO error notification needs to go here
    }

    setLoading(false);
  }

  return (
    <div className="max-w-full px-4 py-2 mt-4">
      <div className="flex justify-between items-center">
        <div className="flex-grow">
          <h1 className="text-lg xl:text-xl 2xl:text-2xl text-gray-900">
            Board Management
          </h1>
          <div className="flex space-x-2 mb-2 mt-2 max-w-lg xl:max-w-xl">
            <input
              type="text"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full px-2 sm:text-sm border border-gray-400 rounded-md"
              placeholder="Enter Board Name..."
              value={newBoard}
              onChange={(e) => setNewBoard(e.target.value)}
            />
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm 2xl:text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={() => handleAdd()}
            >
              Add
            </button>
          </div>
        </div>
        {soloSettings.granicusSync && (
          <button
            className="inline-flex items-center px-4 py-3 mr-4 rounded-md bg-indigo-600 hover:bg-opacity-70 text-sm text-white font-medium"
            onClick={() => handleGranicusImport()}
          >
            Import Granicus
            <CloudUploadIcon
              className="w-5 2xl:h-6 2xl:w-6 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
      <div className="flex flex-col space-y-3 mt-4">
        {boards.length > 0 &&
          boards.map((item) => {
            if (!item.manuallyAdded) {
              return (
                <div
                  key={item._id}
                  className="px-4 py-6 rounded-lg bg-gray-600 shadow-md"
                >
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="flex flex-col">
                        <p className="text-sm font-semibold lg:text-md xl:text-lg 2xl:text-xl text-white">
                          {item.name}
                        </p>

                        <p className="text-sm lg:text-lg text-coolGray-400">
                          From Granicus
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center">
                      <Toggle
                        board={item._id}
                        value={item.tieBreakerVote}
                        action={handleTieToggle}
                      />
                      <p className="text-sm md:text-base text-coolGray-200 mt-2">
                        TieBreaker Vote
                      </p>
                    </div>
                    <div className="flex flex-col items-center">
                      <Toggle
                        board={item._id}
                        value={item.granicusSync}
                        action={handleToggle}
                      />
                      <p className="text-sm md:text-base text-coolGray-200 mt-2">
                        Sync Meetings
                      </p>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={item._id}
                  className="px-4 py-6 rounded-lg bg-gray-600 shadow-md"
                >
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="flex flex-col">
                        <p className="text-sm font-semibold lg:text-md xl:text-lg 2xl:text-xl text-white">
                          {item.name}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center">
                      <Toggle
                        board={item._id}
                        value={item.tieBreakerVote}
                        action={handleTieToggle}
                      />
                      <p className="text-sm md:text-base text-coolGray-200 mt-2">
                        TieBreaker Vote
                      </p>
                    </div>
                    {item.civicSync?.active && (
                      <div className="flex flex-col items-center">
                        <input
                          type="text"
                          className="focus:outline-none w-full px-2 sm:text-sm border-b border-gray-400 rounded-md bg-gray-600 text-zinc-200"
                          placeholder="Enter Category Name..."
                          value={
                            item.civicSync ? item.civicSync.categoryName : ""
                          }
                          onChange={(e) =>
                            handleCivicName(item._id, e.target.value)
                          }
                          onBlur={() => handleCivicBlur(item._id)}
                        />
                        <p className="text-xs md:text-sm text-coolGray-200 mt-0.5">
                          Category Name
                        </p>
                      </div>
                    )}
                    {soloSettings.civicSync && (
                      <div className="flex flex-col items-center">
                        <Toggle
                          board={item._id}
                          value={item.civicSync ? item.civicSync.active : false}
                          action={handleCivicToggle}
                        />
                        <p className="text-sm md:text-base text-coolGray-200 mt-2">
                          CivicClerk
                        </p>
                      </div>
                    )}
                    <div
                      className="cursor-pointer"
                      onClick={() => handleDelete(item._id)}
                    >
                      <XCircleIcon
                        className="h-6 2xl:h-8 w-6 2xl:w-8 text-white hover:text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
      {loading && <Loading />}
    </div>
  );
};

const Toggle = ({ board, value, action }) => {
  if (value) {
    return (
      <button
        type="button"
        aria-pressed="false"
        aria-labelledby="toggleLabel"
        className="bg-indigo-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => action(board, !value)}
      >
        <span
          aria-hidden="true"
          className="translate-x-5 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        ></span>
      </button>
    );
  } else {
    return (
      <button
        type="button"
        aria-pressed="false"
        aria-labelledby="toggleLabel"
        className="bg-gray-300 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => action(board, !value)}
      >
        <span
          aria-hidden="true"
          className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        ></span>
      </button>
    );
  }
};

export default Boards;
