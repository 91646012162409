import React from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { soloSettingsState } from "../../atoms/settings.atom";

function NavBar() {
  const soloSettings = useRecoilValue(soloSettingsState);
  return (
    <header className="pb-24 bg-gradient-to-b from-gray-800 to-gray-600">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-full lg:px-4 2xl:px-20">
        <div className="relative flex flex-wrap items-center space-x-10">
          <div className="absolute left-0 py-5 flex-shrink-0 lg:static">
            <div className="inline-flex items-center space-x-2">
              <img
                className="h-8 w-auto sm:h-10"
                src="/images/LogoBlue.png"
                alt=""
              />
              <h1 className="text-xl lg:text-2xl text-white pl-2">
                {soloSettings.entityName}
              </h1>
            </div>
          </div>

          <div className="hidden lg:block lg:col-span-2">
            <nav className="flex space-x-10">
              <Link
                className="text-white inline-flex items-center text-base font-medium hover:text-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 group"
                to="/"
              >
                Home
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavBar;
