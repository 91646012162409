import { atom, selector } from "recoil";
// import { apiGetSettings } from "../api/settings";

export const soloSettingsState = atom({
  key: "soloSettings",
  default: {},
});

export const settingsChangedState = atom({
  key: "soloSettingsChanged",
  default: false,
});

export const backendServerState = selector({
  key: "backendServerState",
  get: ({ get }) => {
    const settings = get(soloSettingsState);
    return settings.backendServerUrl;
  },
});

// export const soloSettingsQuery = selector({
//   key: "apiSoloSettings",
//   get: async ({ get }) => {
//     const response = await apiGetSettings();
//     return response;
//   },
// });

export const customButtonsState = selector({
  key: "customButtons",
  get: ({ get }) => {
    const settings = get(soloSettingsState);
    return settings.customAvButtons;
  },
});

export const topicSectionState = atom({
  key: "topicSection",
  default: 0,
});

export const boardsState = atom({
  key: "boards",
  default: [],
});

export const boardsListState = atom({
  key: "boardList",
  default: [],
});

export const boardsFilterState = atom({
  key: "boardFilter",
  default: null,
});

export const roundTimerState = atom({
  key: "roundTimer",
  default: 0,
});
