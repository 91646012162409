import React, { useState, useEffect } from "react";

import Toggle from "../Shared/Toggle";

function ScrapeReviewCard({ imported, add, index, enabled }) {
  const [topic, setTopic] = useState({
    itemNumber: "",
    title: "",
    more: "",
    actionEnabled: true,
    approved: false,
  });

  useEffect(() => {
    if (imported) {
      setTopic(imported);
    }
  }, [imported]);

  function handleEnable(value) {
    enabled(value, index);
  }

  return (
    <div className="bg-white rounded-lg shadow-md px-4 py-1.5 grid grid-cols-10 mb-4 border border-asana-bg">
      <div className="flex items-center justify-start">
        <input
          name="import"
          type="checkbox"
          checked={topic.approved}
          className="h-4 w-4 text-indigo-600 bg-gray-50 rounded focus:outline-none"
          onChange={() => add(!topic.approved, index)}
        />
      </div>
      <div className="col-span-2">
        <p className="text-xs xl:text-sm font-medium text-gray-600 underline">
          Item Number
        </p>
        <p className="text-xs xl:text-sm font-medium text-gray-900">
          {topic.itemNumber}
        </p>
      </div>
      <div className="col-span-2">
        <p className="text-xs xl:text-sm font-medium text-gray-700">Title</p>
        <p className="text-xs xl:text-sm font-medium text-gray-900">
          {topic.title}
        </p>
      </div>
      <div className="col-span-4">
        <p className="text-xs xl:text-sm font-medium text-gray-700">Details</p>
        <p className="text-xs xl:text-sm font-medium text-gray-900 truncate">
          {topic.more}
        </p>
      </div>
      <div className="flex items-center justify-end">
        <div className="flex flex-col items-center justify-center">
          <Toggle value={topic.actionEnabled} action={handleEnable} />
          <span className="text-tiny xl:text-xs font-medium text-gray-900">
            Regular Item
          </span>
        </div>
      </div>
    </div>
  );
}

export default ScrapeReviewCard;
