import cx from "classnames";

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { BsThreeDotsVertical } from "react-icons/bs";

import Button from "./Button";

const generalMenuItems = [
  {
    label: "Test Mode",
    value: "test",
  },
  {
    label: "Activate",
    value: "active",
  },
  {
    label: "Clone",
    value: "clone",
  },
  {
    label: "Delete",
    value: "delete",
  },
];

function Actions({ action }) {
  return (
    <div className="relative inline-block text-left">
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          <Button>
            <BsThreeDotsVertical className="text-black w-6 h-6" />
          </Button>
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          align="end"
          sideOffset={5}
          className={cx(
            "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down",
            "w-48 rounded-lg px-1.5 py-1 shadow-md md:w-56",
            "bg-gray-800 border border-gray-400"
          )}
        >
          {generalMenuItems.map(({ label, value }, i) => {
            return (
              <DropdownMenuPrimitive.Item
                key={`${label}-${i}`}
                className={cx(
                  "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
                  "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
                )}
                onClick={() => action(value)}
              >
                {/* {icon} */}
                <span className="flex-grow text-gray-300 group-hover:text-gray-800">
                  {label}
                </span>
              </DropdownMenuPrimitive.Item>
            );
          })}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </div>
  );
}

export default Actions;
