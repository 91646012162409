import React from "react";
import ConsentTopicCard from "./ConsentTopicCard";

function ConsentTopicList({ topics, activate, refresh }) {
  return (
    <div className="flex flex-col">
      {topics?.map((topic, index) => (
        <ConsentTopicCard
          topic={topic}
          key={index}
          activate={activate}
          refresh={refresh}
        />
      ))}
    </div>
  );
}

export default ConsentTopicList;
