import { useRecoilState } from "recoil";
import cx from "classnames";

import { roundTimerState } from "../../../atoms/settings.atom";

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

function RoundTimer({ rounds }) {
  const [round, setRound] = useRecoilState(roundTimerState);
  function handleRound(value) {
    setRound(value);
  }
  return (
    <div className="relative inline-block text-left">
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          <button
            className={cx(
              "text-gray-900 ml-2 rounded-md px-4 py-1.5 bg-blue-300",
              "transform duration-300",
              "hover:bg-blue-200",
              "focus:outline-none",
              // Register all radix states
              "group",
              "radix-state-open:bg-gray-900",
              "radix-state-on:bg-gray-900",
              "radix-state-instant-open:bg-gray-50 radix-state-delayed-open:bg-gray-50"
            )}
          >
            {round === 0 ? "No Round Selected" : `Timer is ${round} minutes`}
          </button>
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          align="end"
          sideOffset={5}
          className={cx(
            "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down",
            "w-48 max-h-64 overflow-y-auto rounded-lg px-1.5 py-1 shadow-md md:w-56",
            "bg-gray-800 border border-gray-400"
          )}
        >
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => handleRound(0)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              No Round Selected
            </span>
          </DropdownMenuPrimitive.Item>
          {rounds?.map((r, i) => (
            <DropdownMenuPrimitive.Item
              className={cx(
                "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
                "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
              )}
              onClick={() => handleRound(r)}
              key={i + 320}
            >
              <span className="flex-grow text-gray-300 group-hover:text-gray-800">
                Round {i + 1}: {r.toString()} Minutes
              </span>
            </DropdownMenuPrimitive.Item>
          ))}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </div>
  );
}

export default RoundTimer;
