import React from "react";
import { FiEdit, FiTrash2 } from "react-icons/fi";

function TopicCard({ topic, index, setEdit, deleteTopic }) {
  return (
    <div className="bg-white rounded-lg shadow-md py-4 px-6 flex flex-col space-y-1 mb-6 border-asana-bg border">
      <div className="flex justify-between">
        <div>
          <p>
            {topic.itemNumber} {topic.title}
          </p>
        </div>
        <div className="flex space-x-1">
          <button
            onClick={() => setEdit(index, topic)}
            className="px-4 hover:scale-110 transform duration-300"
          >
            <FiEdit className="h-6 w-6 text-indigo-800" />
          </button>
          <button
            onClick={() => deleteTopic(index)}
            className="px-4 hover:scale-110 transform duration-300"
          >
            <FiTrash2 className="h-6 w-6 text-red-700" />
          </button>
        </div>
      </div>
      <div>
        <label className="block text-sm xl:text-lg font-medium text-gray-900">
          Details
        </label>
        <div className="">
          <p>{topic.more}</p>
        </div>
      </div>
    </div>
  );
}

export default TopicCard;
