import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { managerUserState } from "../../atoms/user.atom";

// api calls
import {
  apiGetAllMotions,
  apiAddMotion,
  apiDeleteMotion,
} from "../../api/backend.options";

import { XCircleIcon } from "@heroicons/react/solid";

function Motions({ server }) {
  const user = useRecoilValue(managerUserState);
  const [newMotion, setNewMotion] = useState("");
  const [motions, setMotions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await apiGetAllMotions(server);
      if (result.error) {
        // add error notification
      } else {
        setMotions(result.data);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleAdd() {
    const result = await apiAddMotion(server, { name: newMotion }, user?.token);
    if (result.error) {
      // error notification
    } else {
      let newArray = JSON.parse(JSON.stringify(motions));
      newArray.push(result.data);
      setMotions(newArray);
    }
    setNewMotion("");
  }

  async function handleDelete(id) {
    const result = await apiDeleteMotion(server, id, user?.token);
    if (result.error) {
      // error notification
    } else {
      setMotions(result.data);
    }
  }

  return (
    <div className="max-w-full px-4 py-2 mt-4">
      <h1 className="text-lg xl:text-xl 2xl:text-2xl text-gray-900">
        Motion Management
      </h1>
      <div className="flex space-x-2 mb-2 mt-2 max-w-lg xl:max-w-xl">
        <input
          type="text"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full px-2 sm:text-sm border border-gray-400 rounded-md"
          placeholder="Motion Type"
          value={newMotion}
          onChange={(e) => setNewMotion(e.target.value)}
        />
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm 2xl:text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => handleAdd()}
        >
          Add
        </button>
      </div>
      <div className="flex flex-wrap space-x-6 mt-4">
        {motions.length > 0 &&
          motions.map((item) => (
            <div key={item._id} className="px-4 py-2 rounded-full bg-blue-400">
              <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1 flex items-center">
                  <p className="text-sm font-semibold lg:text-md xl:text-lg 2xl:text-xl text-white">
                    {item.name}
                  </p>
                </div>

                <div
                  className="cursor-pointer"
                  onClick={() => handleDelete(item._id)}
                >
                  <XCircleIcon
                    className="h-6 w-6 text-white hover:text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Motions;
