import cx from "classnames";
import { some } from "lodash/collection";

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { AiOutlinePlus } from "react-icons/ai";

import Button from "./Button";

function InsertBoard({ boards, addBoard, memberOf }) {
  return (
    <div className="relative inline-block text-left">
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          <Button>
            <AiOutlinePlus className="text-white w-5 h-5 mr-2" /> Add Board
          </Button>
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          align="end"
          sideOffset={5}
          className={cx(
            "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down",
            "w-48 max-h-64 overflow-y-auto rounded-lg px-1.5 py-1 shadow-md md:w-56",
            "bg-gray-800 border border-gray-400"
          )}
        >
          {boards.map(({ name, _id, manuallyAdded, granicusSync }, i) => {
            if (manuallyAdded || granicusSync) {
              if (!some(memberOf, ["boardId", _id])) {
                return (
                  <DropdownMenuPrimitive.Item
                    key={_id}
                    className={cx(
                      "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
                      "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
                    )}
                    onClick={() => addBoard({ boardId: _id, boardName: name })}
                  >
                    <span className="flex-grow text-gray-300 group-hover:text-gray-800">
                      {name}
                    </span>
                  </DropdownMenuPrimitive.Item>
                );
              } else {
                return null;
              }
            } else {
              return null;
            }
          })}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </div>
  );
}

export default InsertBoard;
