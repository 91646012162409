import React, { useState } from "react";
import { motion } from "framer-motion";
import { filter } from "lodash/collection";
import { useRecoilValue } from "recoil";

import { micState } from "../../atoms/session.atom";
import { soloSettingsState } from "../../atoms/settings.atom";

import { BiShow, BiHide } from "react-icons/bi";

import Error from "../Shared/Error";
import Loading from "../Modals/Loading";

function MemberLogin({ login, notify, seat, manualLogin }) {
  const soloSettings = useRecoilValue(soloSettingsState);
  const mics = useRecoilValue(micState);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);

  React.useEffect(() => {
    if (mics) {
      if (mics.length > 0) {
        // console.log(mics);
        const result = filter(mics, { seat, loggedIn: true });
        if (result.length > 0) {
          // this location has been logged in manually
          manualLogin(result[0].id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mics]);

  async function handleSubmit(e) {
    e.preventDefault();
    login(email, pass);
    setEmail("");
    setPass("");
  }

  if (Object.entries(soloSettings).length === 0) {
    return <Loading />;
  }

  return (
    <div className="h-screen bg-gradient-to-b from-main-bg to-blueGray-800 py-2">
      <div className="px-6 2xl:px-0 2xl:max-w-7xl 2xl:mx-auto flex flex-col h-full">
        <div className="flex w-full border-b border-gray-400 pb-2 justify-between items-center">
          <h1 className="text-2xl font-normal leading-normal text-gray-200 text-center ml-4">
            {soloSettings?.entityName}
          </h1>
        </div>
        <div className="flex-grow flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg w-full sm:p-6 mb-24"
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="flex flex-col">
                <img
                  className="mx-auto h-32 w-auto"
                  src={soloSettings.entityLogo}
                  alt="logo"
                />
                <div className="flex flex-col justify-center items-center mt-4">
                  <p className="font-semibold text-gray-800 text-xl">
                    Meeting Login
                  </p>
                  <p className="italic text-gray-800 text-xl">
                    {seat === "0" ? "Remote Join" : `Seat ${seat}`}
                  </p>
                </div>
                <div className="mt-6 px-4 2xl:px-12">
                  <p className="text-sm font-light text-gray-800">Email</p>
                  <div className="mt-1">
                    <input
                      type="email"
                      required
                      value={email}
                      className="w-full p-2 h-8 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-400 rounded-md"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-2 px-4 2xl:px-12">
                  <p className="text-sm font-light text-gray-800 sm:mt-px sm:pt-2">
                    Password
                  </p>
                  <div className="mt-1 flex items-center">
                    <input
                      type={showPass ? "text" : "password"}
                      required
                      value={pass}
                      className="w-full p-2 h-8 sm:text-sm shadow-sm border border-gray-400 focus:outline-none rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) => setPass(e.target.value)}
                    />
                    {showPass ? (
                      <BiHide
                        className="h-4 2xl:h-6 w-4 2xl:w-6 ml-1 cursor-pointer"
                        onClick={() => setShowPass(false)}
                      />
                    ) : (
                      <BiShow
                        className="h-4 2xl:h-6 w-4 2xl:w-6 ml-1 cursor-pointer"
                        onClick={() => setShowPass(true)}
                      />
                    )}
                  </div>
                </div>
                <div className="mt-8 px-4 2xl:px-12 flex justify-around">
                  {/* <button
                    className="text-center py-2 px-8 font-medium rounded-md text-white bg-gray-600 hover:bg-opacity-70 focus:outline-none transform duration-300"
                    onClick={() => handlePress("clear")}
                  >
                    Clear
                  </button> */}
                  <button
                    className="w-full flex justify-center py-2 px-8 font-medium rounded-md text-white bg-indigo-600 hover:bg-opacity-70 transform duration-300"
                    type="submit"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </div>
      {notify && <Error subheading="Invalid Credentials" />}
    </div>
  );
}

export default MemberLogin;
