import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { soloSettingsState } from "../atoms/settings.atom";
import { managerUserState } from "../atoms/user.atom";
import { apiRestoreMeeting, apiGetRecentlyRun } from "../api/meetings";

import MeetingList from "../components/Meetings/MeetingsRecent";
import Error from "../components/Shared/Error";
import ConfirmRestore from "../components/Modals/ConfirmRestore";
// import Loading from "../components/Modals/Loading";

const Recent = () => {
  const user = useRecoilValue(managerUserState);
  const [meetings, setMeetings] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMeeting, setConfirmMeeting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const soloSettings = useRecoilValue(soloSettingsState);

  useEffect(() => {
    async function fetchData() {
      const result = await apiGetRecentlyRun(soloSettings.backendServerUrl);
      setMeetings(result);
    }
    if (Object.entries(soloSettings).length !== 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soloSettings]);

  function handleSure(meeting) {
    setConfirmMeeting(meeting);
    setShowConfirm(true);
  }

  async function handleMeetingPress() {
    const restoreResult = await apiRestoreMeeting(
      soloSettings.backendServerUrl,
      confirmMeeting.id,
      user?.token
    );
    setShowConfirm(false);
    if (restoreResult === 200) {
      handleRefresh();
    } else {
      setErrorMsg("Error Restoring Meeting");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg("");
      }, 2000);
    }
  }

  async function handleRefresh() {
    const meetingResult = await apiGetRecentlyRun(
      soloSettings.backendServerUrl
    );
    setMeetings(meetingResult);
  }

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      <div className="py-4">
        <div className="flex items-center justify-between border-b-2 max-w-7xl mx-auto px-2 lg:px-4">
          <h1 className="text-lg xl:text-xl 2xl:text-2xl font-semibold text-gray-900">
            Recently Run
          </h1>
        </div>
        <div className="max-w-7xl mx-auto mt-2 2xl:mt-6 px-1 xl:px-8">
          <p className="mb-6">
            The purpose of this list is to allow you to restore a meeting in
            case you accidentally ran it in active mode instead of test mode.
            The list only goes back 3 days.
          </p>
          {/* Content */}
          <MeetingList meetings={meetings} action={handleSure} />
        </div>
      </div>
      {/* {loading && <Loading />} */}
      {showError && <Error subheading={errorMsg} />}
      {showConfirm && (
        <ConfirmRestore
          isVisible={setShowConfirm}
          confirm={handleMeetingPress}
        />
      )}
    </main>
  );
};

export default Recent;
