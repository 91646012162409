import React from "react";
import { useRecoilValue } from "recoil";
import { soloSettingsState } from "../../atoms/settings.atom";

import Loading from "./Loading";

function NoActiveLive() {
  const soloSettings = useRecoilValue(soloSettingsState);

  if (Object.entries(soloSettings).length === 0) {
    return <Loading />;
  }

  return (
    <div className="relative h-screen w-screen overflow-hidden bg-gradient-to-br from-zinc-900 via-slate-900 to-slate-800">
      <img
        src="/images/solo-white.png"
        className="h-12 2xl:h-20 w-auto absolute top-4 left-4 opacity-70"
        alt="agendalink"
      />
      <div
        className="w-1/2 h-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-origin-content bg-center bg-no-repeat bg-contain"
        style={{
          backgroundImage: `url(${soloSettings.entityLogo})`,
        }}
      ></div>
      <div className="grid grid-rows-3 gap-2 h-full w-full">
        <div className="flex items-center justify-center">
          <p className="text-zinc-500 text-7xl font-light">No Active Meeting</p>
        </div>
        <div />
        <div className="flex items-center justify-center">
          <p className="text-zinc-400 text-7xl font-semibold">
            {soloSettings.entityName}
          </p>
        </div>
      </div>
    </div>
  );
}

export default NoActiveLive;
