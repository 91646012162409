import axios from "axios";

export const apiSyncComments = async (server) => {
  try {
    const result = await axios
      .get(`${server}/comments`)
      .then(function (response) {
        return response.data.result;
      });
    return result;
  } catch (error) {
    console.log("error api granicus apiSyncComments");
    console.log(error);
    return false;
  }
};

export const apiGranicusPublished = async (server, entity) => {
  try {
    const result = await axios
      .get(`${server}/granicus/meetings/${entity}`)
      .then(function (response) {
        if (response.status === 200) {
          return "success";
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error api granicus apiGranicusPublished");
    console.log(error);
    return "error";
  }
};

export const apiGetGranicusMeeting = async (server, entity, id) => {
  try {
    const result = await axios
      .get(
        `${server}/granicus/meeting/${entity}/${id}`
      )
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error api granicus apiGetGranicusMeeting");
    console.log(error);
    return "error";
  }
};

export const apiImportBodies = async (server, entity) => {
  try {
    const result = await axios
      .get(`${server}/granicus/bodies/${entity}`)
      .then(function (response) {
        if (response.status === 200) {
          return "success";
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error api granicus apiImportBodies");
    console.log(error);
    return false;
  }
};

export const apiImportUsers = async (server, entity) => {
  try {
    const result = await axios
      .get(`${server}/granicus/users/${entity}`)
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        }
      });
    return result;
  } catch (error) {
    console.log("error api granicus apiImportUsers");
    console.log(error);
    return false;
  }
};
