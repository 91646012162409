import React from "react";
import { useRecoilValue } from "recoil";
import { customButtonsState } from "../../../atoms/settings.atom";

function CustomControls({ sendEvent }) {
  const buttons = useRecoilValue(customButtonsState);

  return (
    <div className="flex flex-col h-full w-full items-center justify-start mx-2 divide-y-4 divide-gray-200">
      <p className="px-2 text-base xl:text-2xl text-gray-700 leading-relaxed tracking-wide">
        Audio / Video System Controls
      </p>
      <div className="grid grid-cols-4 xl:grid-cols-5 gap-2 xl:gap-4 pt-4">
        {buttons?.map((button, index) => (
          <button
            className="text-center px-6 py-3 border border-transparent text-base xl:text-xl font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() =>
              sendEvent("customButton", {
                command: button.command,
              })
            }
            key={index}
          >
            {button.title}
          </button>
        ))}
      </div>
    </div>
  );
}

export default CustomControls;
