import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";

const HeadlessDrop = ({ array, value, change, heading }) => {
  function handleChange(e) {
    const newHeading = heading.replace(/\s/g, "");
    change(newHeading.toLowerCase(), e);
  }

  return (
    <div className="w-full">
      <p className="text-xs 2xl:text-base text-gray-700 italic">{heading}</p>

      <Listbox value={value} onChange={handleChange}>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-md border border-gray-700 shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-100 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                <span className="block truncate">{value.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute w-full py-1 mt-1 overflow-auto text-base 2xl:text-lg bg-white rounded-md shadow-2xl max-h-80 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-40 border-l border-r border-b border-gray-600"
                >
                  {array.map((item, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `${
                            active
                              ? "text-blue-900 bg-blue-100"
                              : "text-gray-900"
                          }
        cursor-default select-none relative py-2 px-4`
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <div className="flex w-full space-x-2 items-center">
                            <span
                              className={`${
                                selected ? "font-medium" : "font-normal"
                              } block truncate`}
                            >
                              {item.name}
                            </span>
                          </div>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default HeadlessDrop;
