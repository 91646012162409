import React, { useState, Fragment } from "react";
import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import { filter } from "lodash/collection";

import { sessionBoardState } from "../../atoms/session.atom";
import { testModeState } from "../../atoms/globals.atom";

import { Listbox, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";

import Loading from "../Shared/Loading";

const ManagerSeatModal = ({ isVisible, users, sendEvent }) => {
  const [chosen, setChosen] = useState();
  const [avail, setAvail] = useState([]);
  const [loading, setLoading] = useState(false);
  const sessionBoard = useRecoilValue(sessionBoardState);
  const testMode = useRecoilValue(testModeState);

  React.useEffect(() => {
    if (users) {
      const filtered = filter(users, (o) => {
        return !o.loggedIn;
      });
      setAvail(filtered);
      if (filtered.length > 0) {
        setChosen(filtered[0]);
      }
    }
  }, [users]);

  function loginUser() {
    sendEvent("manualLogin", {
      user: chosen.id,
      seat: chosen.seat,
      board: sessionBoard,
      testMode,
      remote: "false",
    });
    isVisible(false);
  }

  function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async function loginAll() {
    setLoading(true);
    for (const i of avail) {
      await sleep(800);
      sendEvent("manualLogin", {
        user: i.id,
        seat: i.seat,
        board: sessionBoard,
        testMode,
        remote: "false",
      });
    }
    setLoading(false);
    isVisible(false);
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <motion.div
            className="absolute inset-0 bg-blueGray-800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            transition={{ duration: 0.4 }}
            onClick={() => isVisible(false)}
          ></motion.div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          style={{ minHeight: "24rem" }}
        >
          <div className="flex flex-col">
            <div className="flex justify-between items-center">
              <p className="font-semibold text-gray-800 text-xl">
                Manager Member Login
              </p>
              <AiOutlineClose
                className="h-6 w-6 text-gray-800 cursor-pointer hover:text-gray-600"
                onClick={() => isVisible(false)}
              />
            </div>
            {avail.length > 0 ? (
              <div className="flex flex-col px-6 mt-12">
                <div className="flex flex-col px-6 mb-6">
                  <p className="mb-1 text-center text-base xl:text-xl font-medium rounded-md text-zinc-800 focus:outline-none">
                    Select Member
                  </p>
                  {chosen && (
                    <Listbox value={chosen} onChange={setChosen}>
                      {({ open }) => (
                        <>
                          <div className="relative mt-1">
                            <Listbox.Button className="relative flex justify-between w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-gray-700 shadow-md cursor-default focus:outline-none sm:text-sm 2xl:text-base">
                              <p>{chosen.name}</p>
                              <p>Seat {chosen.seat}</p>
                            </Listbox.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options
                                static
                                className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-2xl max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm 2xl:text-base z-40 border-b border-r border-l border-gray-800"
                              >
                                {avail.map((item, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      `${
                                        active
                                          ? "text-blue-900 bg-blue-100"
                                          : "text-gray-900"
                                      } cursor-default select-none relative py-2 pl-10 pr-4 flex justify-between`
                                    }
                                    value={item}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <p
                                          className={`${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          } block truncate`}
                                        >
                                          {item.name}
                                        </p>
                                        <p>Seat {item.seat}</p>
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  )}
                </div>
                <div className="flex flex-col px-6 justify-end">
                  <button
                    className="mt-4 inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base xl:text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    onClick={() => {
                      loginUser();
                    }}
                  >
                    Login User
                  </button>
                </div>
                <div className="flex flex-col px-6 justify-end">
                  <p className="mb-1 mt-4 text-center text-base xl:text-xl font-medium rounded-md text-zinc-800 focus:outline-none">
                    Or
                  </p>
                  <button
                    className="mt-4 inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base xl:text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    onClick={() => {
                      loginAll();
                    }}
                  >
                    Log Everyone in
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p>All Members are Logged In</p>
              </div>
            )}
          </div>
        </motion.div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default ManagerSeatModal;
