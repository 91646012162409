import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

const MotionDrop = ({ array, value, change, heading }) => {
  const [filterName, setFilterName] = useState("");
  const [filtered, setFiltered] = useState(array);

  function handleChange(e) {
    const newHeading = heading.replace(/\s/g, "");
    change(newHeading.toLowerCase(), e);
    setFilterName("");
  }

  const filter = (e) => {
    const keyword = e.target.value;
    const lowerKey = keyword.toLowerCase();

    if (keyword !== "") {
      const results = filtered.filter((user) => {
        let item = user.name.toLowerCase();
        return item.indexOf(lowerKey) !== -1;
      });
      setFiltered(results);
    } else {
      // If the text field is empty, show all users
      setFiltered(array);
    }

    setFilterName(keyword);
  };

  return (
    <div className="w-full">
      <p className="text-xs 2xl:text-base text-gray-700 italic">{heading}</p>
      <Listbox value={value} onChange={handleChange}>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-md border border-gray-700 shadow-md cursor-default focus:outline-none sm:text-sm">
                <span className="block truncate">{value.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute flex flex-col w-full pb-2 mt-1 overflow-y-auto text-base 2xl:text-lg bg-white rounded-md shadow-2xl max-h-80 focus:outline-none sm:text-sm z-30 border border-gray-600"
                >
                  <div className="sticky top-0 border-b border-zinc-500 bg-white z-10">
                    <input
                      type="search"
                      value={filterName}
                      onChange={(e) => filter(e)}
                      className="w-full text-sm placeholder:text-zinc-400 border border-white focus:outline-none focus:ring-0 appearance-none"
                      placeholder="Enter a name..."
                    />
                  </div>

                  {filtered.map((item, fIdx) => {
                    if (item.loggedIn) {
                      return (
                        <Listbox.Option
                          key={`filtered-${fIdx}`}
                          className={({ active }) =>
                            `${
                              active
                                ? "text-blue-900 bg-white"
                                : "text-gray-900"
                            }
        cursor-pointer select-none relative py-2 px-4`
                          }
                          value={item}
                        >
                          {({ selected, active }) => (
                            <div className="flex space-x-2 items-center">
                              <img
                                src={item.photoUrl}
                                alt="member"
                                className="h-10 w-10 rounded-md"
                              />
                              <span
                                className={`${
                                  selected ? "font-medium" : "font-normal"
                                } block truncate`}
                              >
                                {item.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`${
                                    active ? "text-blue-600" : "text-blue-600"
                                  }
              absolute inset-y-0 left-0 flex items-center pl-3`}
                                >
                                  <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default MotionDrop;
