import React from "react";

function EndMeeting({ endMeeting, endingNotes, sendEvent }) {
  return (
    <div className="w-full mt-2">
      <div
        className="h-full w-full bg-red-800 rounded-lg overflow-hidden cursor-pointer shadow transform hover:bg-red-900 py-2 xl:py-4 2xl:py-6"
        onClick={() => {
          if (endingNotes.length > 0) {
            sendEvent("endingNotes", endingNotes);
            endMeeting();
          } else {
            endMeeting();
          }
        }}
      >
        <div className="px-4 py-2">
          <h1 className="text-base 2xl:text-lg font-medium text-white title-font">
            Meeting Adjournment
          </h1>
        </div>
      </div>
    </div>
  );
}

export default EndMeeting;
