import React from "react";
import { useSetRecoilState } from "recoil";
import {
  speakerModalState,
  speakerModalContentState,
} from "../../atoms/globals.atom";
function TopicSpeaker({ user, speaker, index, attend }) {
  const setSpeakerModal = useSetRecoilState(speakerModalState);
  const setSpeakerModalContent = useSetRecoilState(speakerModalContentState);

  return (
    <div
      className={`block space-y-1 rounded-lg px-2 py-1 2xl:px-6 2xl:py-2 border-l-8 border-r-8 border-b bg-gray-50 shadow-md
        ${
          speaker.position === "A" &&
          "border-l-8 border-r-8 border-b border-red-600 text-white"
        }
        ${
          speaker.position === "F" &&
          "border-l-8 border-r-8 border-b border-green-600 text-white"
        }
        ${
          speaker.position === "U" &&
          "border-l-8 border-r-8 border-b border-gray-600  text-white"
        }
        ${
          speaker.position === "P" &&
          "border-l-8 border-r-8 border-b border-gray-600  text-white"
        }
        ${
          (user?.adminRole || user?.managerRole) &&
          "cursor-pointer hover:bg-opacity-60"
        }
        `}
      key={index}
      onClick={() => {
        if (user?.managerRole || user?.adminRole) {
          setSpeakerModalContent(speaker);
          setSpeakerModal(true);
        }
      }}
    >
      <div className="flex justify-between items-center text-black">
        <p className="text-sm xl:text-base 2xl:text-xl font-semibold">
          {speaker.firstName} {speaker.lastName}
        </p>
        {!attend && (
          <p className="px-2 inline-flex text-xs 2xl:text-base font-semibold rounded-full bg-gray-500 text-white">
            Calling
          </p>
        )}
      </div>
      <div className="flex justify-between text-black">
        <div className="block">
          <p className="text-xs xl:text-sm 2xl:text-base italic">
            {speaker.address}
          </p>
          {speaker.address.length > 0 && (
            <p className="text-xs xl:text-sm 2xl:text-base italic">
              {speaker.city}, {speaker.state} {speaker.zip}
            </p>
          )}
        </div>
        {speaker.district.length > 0 && (
          <p className="text-sm xl:text-base 2xl:text-lg">
            District: {speaker.district}
          </p>
        )}
      </div>
      {speaker.subject.length > 0 && (
        <p className="text-xs xl:text-sm 2xl:text-base font-medium text-black">
          Subject: {speaker.subject}
        </p>
      )}
      {speaker.representing.length > 0 && (
        <p className="text-xs xl:text-sm 2xl:text-base font-medium text-black">
          Representing: {speaker.representing}
        </p>
      )}
      {speaker.representingOrg.length > 0 && (
        <p className="text-xs xl:text-sm 2xl:text-base font-medium text-black">
          Organization: {speaker.representingOrg}
        </p>
      )}
      {speaker.oppositionOnly && (
        <p className="px-2 inline-flex text-xs 2xl:text-base leading-5 font-semibold rounded-full bg-red-600 text-white">
          Opposition Only
        </p>
      )}
    </div>
  );
}

export default TopicSpeaker;
