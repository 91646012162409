import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { managerUserState } from "../../atoms/user.atom";
import { soloSettingsState } from "../../atoms/settings.atom";
import { apiUpdateUser } from "../../api/members";

import MeetingRow from "./MeetingRow";

const MeetingList = ({ meetings, action }) => {
  let history = useHistory();
  const user = useRecoilValue(managerUserState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (meetings !== undefined) {
      let status = false;
      for (const meeting of meetings) {
        if (meeting.active || meeting.testMode) {
          status = true;
        }
      }
      setShowButton(status);
    }
  }, [meetings]);

  async function handleMeetingStart() {
    // make sure this user is marked as logged in
    // this is important because if they end a meeting it will mark them as logged out again
    await apiUpdateUser(
      soloSettings.backendServerUrl,
      user?._id,
      { loggedIn: true },
      user?.token
    );
    history.push("/manager");
  }

  return (
    <div className="flex flex-col">
      {showButton && (
        <button
          className="inline-flex items-center justify-center px-4 py-2 xl:px-6 2xl:py-3 border border-transparent text-sm 2xl:text-base font-medium rounded-xl text-white bg-green-700 hover:bg-green-900 focus:outline-none mt-0 mb-2 sm:mb-4 sm:mt-2 transform duration-300"
          onClick={() => handleMeetingStart()}
        >
          Begin the Meeting...
        </button>
      )}
      <div className="-my-2 xl:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full px-1 xl:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Schedule Date
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-1 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 xl:px-6 xl:py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase"
                  >
                    Details
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 xl:px-6 xl:py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase"
                  >
                    Actions
                  </th>
                  {/* <th scope="col" className="px-2 py-3 bg-gray-50"></th>
                  <th scope="col" className="px-2 py-3 bg-gray-50"></th> */}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {meetings &&
                  meetings.map((meeting) => (
                    <MeetingRow
                      meeting={meeting}
                      key={meeting.id}
                      action={action}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingList;
