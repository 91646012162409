import React from "react";

function Present() {
  return (
    <div className="flex flex-col h-full w-full items-center justify-start mx-2 divide-y divide-gray-200 mt-4">
      <video width="320" height="240">
        <source src="tv:brightsign.biz/hdmi" />
      </video>
    </div>
  );
}

export default Present;
