import { Fragment, useState, useEffect } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import Toggle from "../Shared/DarkToggle";


export default function EditTopicSlideOut({
  currentTopic,
  isVisible,
  updateTopic,
}) {
  const [topic, setTopic] = useState({
    itemNumber: "",
    title: "",
    more: "",
    votingOption: "majority",
    actionEnabled: true,
  });
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (currentTopic) {
      setTopic(currentTopic);
    }
  }, [currentTopic]);

  function handleChange(target, value) {
    switch (target) {
      case "title":
        setTopic({
          ...topic,
          title: value,
        });
        setChanged(true);
        break;
      case "more":
        setTopic({
          ...topic,
          more: value,
        });
        setChanged(true);
        break;
      case "itemNumber":
        setTopic({
          ...topic,
          itemNumber: value,
        });
        setChanged(true);
        break;

      default:
        console.log("screwed up somewhere");
        break;
    }
  }

  function handleEnable(value) {
    setTopic({
      ...topic,
      actionEnabled: value,
    });
    setChanged(true);
  }

  function handleClose() {
    if (changed) {
      updateTopic(topic);
    } else {
      isVisible();
    }
  }

  return (
    <Transition.Root show={true} appear={true} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={true}
        onClose={handleClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-900 bg-opacity-50 transform duration-300" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-out duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-xl">
                <div className="h-full flex flex-col bg-asana-bg overflow-y-scroll">
                  <div className="px-6 py-2 border-b border-gray-600">
                    <div className="flex items-start justify-end">
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className="rounded-md text-gray-400 hover:text-opacity-75 focus:outline-none"
                          onClick={() => handleClose()}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Content */}
                  <div className="mt-2 flex flex-col px-1 2xl:px-4 pt-1 2xl:pt-4 pb-2">
                    <div className="mt-4">
                      <div className="">
                        <input
                          type="text"
                          name="title"
                          placeholder="Enter Title"
                          value={topic.title}
                          className="mt-1 block w-full border-2 hover:border-gray-400 border-gray-600 bg-asana-bg text-gray-300 rounded-md py-2 px-1 transform duration-200 text-lg focus:outline-none"
                          onChange={(e) => {
                            handleChange("title", e.target.value);
                          }}
                        />
                      </div>

                      <div className="mt-6 min-h-[10rem]">
                        <label className="block text-sm font-light text-gray-500">
                          Item Number &#40;Auto-Generated if Blank&#41;
                        </label>
                        <input
                          type="text"
                          name="itemNumber"
                          value={topic.itemNumber}
                          className="mt-1 block w-full border-2 hover:border-gray-400 border-gray-600 bg-asana-bg text-gray-300 rounded-md py-2 px-1 transform duration-200 text-lg focus:outline-none"
                          onChange={(e) => {
                            handleChange("itemNumber", e.target.value);
                          }}
                        />
                      </div>
                      <div className="mt-6 min-h-[10rem]">
                        <label className="block text-sm font-light text-gray-500">
                          Details
                        </label>
                        <textarea
                          name="more"
                          rows="4"
                          placeholder="Add description here"
                          value={topic.more}
                          className="mt-1 block w-full border-2 hover:border-gray-400 border-gray-600 bg-asana-bg text-gray-300 rounded-md py-2 px-3 sm:text-sm transform duration-200"
                          onChange={(e) => {
                            handleChange("more", e.target.value);
                          }}
                        ></textarea>
                      </div>
                      <div className="mt-6">
                        <Toggle
                          value={topic.actionEnabled}
                          action={handleEnable}
                          label="Action Topic"
                        />
                        <p className="text-sm text-gray-500 italic mt-2">
                          Disabling will make it part of consent group
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
