import { motion } from "framer-motion";

const circleStyle = {
  width: "6rem",
  height: "6rem",
  border: "0.7rem solid #e9e9e9",
  borderTop: "0.7rem solid #3498db",
  borderRadius: "50%",
  boxSizing: "border-box",
};

const spinTransition = {
  repeat: Infinity,
  ease: "linear",
  duration: 3,
};

const Loading = () => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center sm:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-coolGray-900 bg-opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <motion.span
          className="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          style={circleStyle}
          animate={{ rotate: 360 }}
          transition={spinTransition}
        />
      </div>
    </div>
  );
};

export default Loading;
