import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { boardsState } from "../../atoms/settings.atom";

import { Switch } from "@headlessui/react";
import { RiDeleteBin6Line } from "react-icons/ri";

import InsertBoard from "./InsertItem";

const MemberReviewItem = ({ member, add }) => {
  const [enabled, setEnabled] = useState(false);
  const [managerRole, setManagerRole] = useState(false);
  const [temp, setTemp] = useState();
  const [password, setPassword] = useState("");
  const [memberOf, setMemberOf] = useState([]);
  const boards = useRecoilValue(boardsState);

  useEffect(() => {
    if (member) {
      setTemp(member);
    }
  }, [member]);

  function validateEmail(email) {
    const res =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (res.test(String(email).toLowerCase())) {
      if (password.length >= 8) {
        add({
          ...temp,
          managerRole,
          memberOf,
          password,
        });
        setEnabled(false);
        setManagerRole(false);
        setPassword("");
        setMemberOf([]);
      } else {
        alert("Password isn't minimum of 8 characters");
      }
    } else {
    }
  }

  function handleEnabled(value) {
    setEnabled(value);
  }

  function handleManager(value) {
    setManagerRole(value);
  }

  function handleBoard({ boardId, boardName }) {
    if (memberOf.length === 0) {
      setMemberOf([
        { boardId, boardName, adminRole: false, votingRole: false },
      ]);
    } else {
      setMemberOf((prevState) => [
        ...prevState,
        { boardId, boardName, adminRole: false, votingRole: false },
      ]);
    }
  }

  function handleBoardOptions(index, key, value) {
    let tempArray = Array.from(memberOf);
    const [removed] = tempArray.splice(index, 1);
    let newData = {
      ...removed,
      [key]: value,
    };
    tempArray.splice(index, 0, newData);
    setMemberOf(tempArray);
  }

  function handleBoardRemove(index) {
    let tempArray = Array.from(memberOf);
    tempArray.splice(index, 1);
    setMemberOf(tempArray);
  }

  if (!temp) {
    return null;
  }

  return (
    <div className="p-4 bg-gray-50 rounded-xl border border-gray-600 shadow-md flex flex-col space-y-3">
      <div className="flex justify-between items-center">
        <p className="text-black font-medium">
          <span className="text-gray-800 mr-2 font-normal">User:</span>
          {temp.firstName} {temp.lastName}
        </p>
        <div className="flex">
          <p className="text-gray-800 mr-2">Details:</p>
          <Switch
            checked={enabled}
            onChange={(e) => handleEnabled(e)}
            className={`${
              enabled ? "bg-green-700" : "bg-gray-400"
            } relative inline-flex items-center h-6 rounded-full w-11 transform duration-300`}
          >
            <span
              className={`${
                enabled ? "translate-x-6" : "translate-x-1"
              } inline-block w-4 h-4 transform bg-white rounded-full`}
            />
          </Switch>
        </div>
      </div>

      {enabled && (
        <>
          <div>
            <p className="text-gray-800 mr-2">Email:</p>
            <input
              type="email"
              required
              value={temp.email}
              className="max-w-lg block w-full p-2 h-8 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-400 rounded-md"
              onChange={(e) => setTemp({ ...temp, email: e.target.value })}
            />
            <p className="text-red-800 text-sm">
              &#42;&#42; Required &#42;&#42;
            </p>
          </div>
          <div>
            <p className="text-gray-800 mr-2">Password:</p>
            <input
              type="email"
              required
              value={password}
              className="max-w-lg block w-full p-2 h-8 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-400 rounded-md"
              onChange={(e) => setPassword(e.target.value)}
            />
            <p className="text-red-800 text-sm">
              &#42;&#42; Minimum 8 Characters &#42;&#42;
            </p>
          </div>
        </>
      )}
      {enabled && boards && (
        <>
          <div className="flex">
            <InsertBoard
              boards={boards}
              addBoard={handleBoard}
              memberOf={memberOf}
            />
          </div>
          <div>
            {memberOf.map(
              ({ boardId, boardName, adminRole, votingRole }, index) => {
                return (
                  <div
                    key={boardId}
                    className="flex justify-between items-center bg-gray-200 border border-gray-600 rounded-md p-4 mb-2"
                  >
                    <p className="font-medium">{boardName}</p>
                    <div className="flex items-center">
                      <div className="mr-8 flex flex-col items-center">
                        <input
                          id={index}
                          name="adminRole"
                          type="checkbox"
                          checked={adminRole}
                          className="h-4 w-4 text-indigo-600 bg-gray-50 rounded mb-1 focus:outline-none"
                          onChange={() =>
                            handleBoardOptions(index, "adminRole", !adminRole)
                          }
                        />
                        <p className="text-sm">Admin</p>
                      </div>
                      <div className="mr-8 flex flex-col items-center">
                        <input
                          id={index}
                          name="votingRole"
                          type="checkbox"
                          checked={votingRole}
                          className="h-4 w-4 text-indigo-600 bg-gray-50 rounded mb-1 focus:outline-none"
                          onChange={() =>
                            handleBoardOptions(index, "votingRole", !votingRole)
                          }
                        />
                        <p className="text-sm">Voting</p>
                      </div>
                      <button onClick={() => handleBoardRemove(index)}>
                        <RiDeleteBin6Line className="text-delete h-5 w-5 hover:text-red-500 transform duration-300" />
                      </button>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </>
      )}

      {enabled && (
        <div className="flex justify-between">
          <div>
            <p className="text-gray-800 mr-2 mt-2">Manager Role:</p>
            <Switch
              checked={managerRole}
              onChange={(e) => handleManager(e)}
              className={`${
                managerRole ? "bg-blue-600" : "bg-gray-400"
              } relative inline-flex items-center h-6 rounded-full w-11 ml-4 mt-1 transform duration-300`}
            >
              <span
                className={`${
                  managerRole ? "translate-x-6" : "translate-x-1"
                } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
          </div>
        </div>
      )}
      {/* TODO add email verification here and only show import button if passes */}
      {enabled && (
        <div className="flex justify-end">
          <button
            className="bg-blue-700 text-white px-4 py-2 rounded-md"
            onClick={() => validateEmail(temp.email)}
          >
            Add to Import
          </button>
        </div>
      )}
    </div>
  );
};

export default MemberReviewItem;
