import React from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import clsx from "clsx";

import { userMicState, userState } from "../../atoms/user.atom";
import { micControlsState } from "../../atoms/controls.atom";
import { sessionBoardState } from "../../atoms/session.atom";

function RequestAndMic({ sendEvent }) {
  const userMic = useRecoilValue(userMicState);
  const user = useRecoilValue(userState);
  const { seat } = useParams();
  const micControls = useRecoilValue(micControlsState);
  const sessionBoard = useRecoilValue(sessionBoardState);

  function handleRequest() {
    let newAction;
    switch (userMic) {
      case "off":
        newAction = "queue";
        break;
      case "queue":
        newAction = "on";
        break;
      case "on":
        newAction = "off";
        break;
      default:
        console.log("wtf happened");
        break;
    }
    sendEvent("micChange", {
      id: user._id,
      seat: seat,
      action: newAction,
      board: sessionBoard,
    });
  }

  return (
    <>
      <button
        className={clsx(
          userMic === "off" &&
            "bg-gradient-to-r from-gray-300 to-gray-500 rounded-md",
          userMic === "queue" &&
            "bg-gradient-to-r from-blue-300 to-blue-500 rounded-md",
          userMic === "on" &&
            "bg-gradient-to-r from-green-300 to-green-500 rounded-md",
          "p-3 text-3xl h-36 w-52 rounded-3xl mt-2",
          "xl:p-4 xl:mb-1 xl:mt-2 xl:text-xl hd:text-3xl xl:rounded-md xl:w-56 2xl:w-72 xl:h-auto",
          "flex justify-center items-center font-medium text-center focus:outline-none"
        )}
        onClick={() => handleRequest()}
      >
        {userMic === "off" && "Request to Speak"}
        {userMic === "queue" && "In Queue"}
        {userMic === "on" && micControls && "Mic is On"}
        {userMic === "on" && !micControls && "Turn Mic On"}
      </button>
    </>
  );
}

export default RequestAndMic;
