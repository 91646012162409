import React, { createRef, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  topicState,
  activeTopicState,
  consentTopicState,
  endingNotesState,
} from "../../atoms/session.atom";
import { userState } from "../../atoms/user.atom";
import { testModeState, confirmEndState } from "../../atoms/globals.atom";
import { soloSettingsState } from "../../atoms/settings.atom";

import { ChevronRightIcon } from "@heroicons/react/outline";

import TopicCard from "./TopicCard";
import EndMeeting from "./EndMeeting";
import RollCall from "./RollCall";
import ModalConsentAgenda from "../Modals/ModalConsentAgenda";

function TopicListMember({ timerStart, timerStop, sendEvent, nextTopic }) {
  const [showConsent, setShowConsent] = useState(false);
  const topics = useRecoilValue(topicState);
  const consentTopics = useRecoilValue(consentTopicState);
  const user = useRecoilValue(userState);
  const testMode = useRecoilValue(testModeState);
  const activeTopic = useRecoilValue(activeTopicState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const endingNotes = useRecoilValue(endingNotesState);
  const setConfirmEnd = useSetRecoilState(confirmEndState);

  const refs = topics.reduce((acc, value) => {
    acc[value._id] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (activeTopic?.length > 0) {
      if (refs[activeTopic] !== undefined) {
        refs[activeTopic].current.scrollIntoView({
          behavior: "smooth",
          inline: "start",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTopic]);

  const handleEndMeeting = () => {
    if (testMode) {
      setConfirmEnd(true);
    } else {
      setConfirmEnd(true);
      timerStop();
    }
  };

  return (
    <>
      <div className="w-full flex justify-center border-b-2 border-gray-800">
        <p className="text-base 2xl:text-2xl text-indigo-500 font-medium">
          Agenda Items
        </p>
      </div>
      {consentTopics.length > 0 && (
        <div className="w-full mt-2 mb-2">
          <button
            className="inline-flex rounded-md px-2 py-1 2xl:py-2 text-sm 2xl:text-lg items-center border border-gray-600 focus:outline-none"
            onClick={() => setShowConsent(true)}
          >
            Consent Items
            <ChevronRightIcon className="h-4 w-4 2xl:h-6 2xl:w-6 text-black ml-4" />
          </button>
        </div>
      )}

      <div className="w-full overflow-y-auto mt-1">
        {user?.managerRole && <RollCall sendEvent={sendEvent} />}
        {topics?.map((topic, index) => (
          <TopicCard
            key={index}
            topic={topic}
            number={index}
            refs={refs}
            timerStart={timerStart}
            timerStop={timerStop}
            sendEvent={sendEvent}
            nextTopic={nextTopic}
            autoAdvance={soloSettings.autoNextTopic}
          />
        ))}
        {user?.managerRole && (
          <EndMeeting
            endMeeting={handleEndMeeting}
            endingNotes={endingNotes}
            sendEvent={sendEvent}
          />
        )}
      </div>
      <ModalConsentAgenda
        sendEvent={sendEvent}
        open={showConsent}
        show={setShowConsent}
        nextTopic={nextTopic}
      />
    </>
  );
}

export default TopicListMember;
