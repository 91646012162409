import React from "react";
import { useRecoilValue } from "recoil";
import { votingResultsState } from "../../atoms/session.atom";

import VotingResultCard from "./VotingResultCard";

function InlineVotingResults() {
  const votingResults = useRecoilValue(votingResultsState);
  return (
    <div className="flex h-full w-full justify-start m-2">
      <div
        className={`flex flex-col h-full w-full justify-start mx-2 rounded-md
    ${votingResults.votingResult ? "bg-green-700" : "bg-red-700"}`}
      >
        <div className="flex flex-col flex-nowrap bg-white m-4 rounded-md h-full px-4">
          <div className="inline-flex px-6 justify-between border-b border-gray-500 mb-2">
            <h2 className="hidden md:block text-center text-base 2xl:text-2xl font-bold text-gray-900 italic">
              {votingResults.itemNumber}
            </h2>
            <h2 className="hidden xl:block text-center text-base 2xl:text-2xl font-bold text-gray-900 italic">
              {votingResults.motionType} Motion: {votingResults.votingResult ? "Passed" : "Failed"}
            </h2>
          </div>
          <div className="grid grid-cols-3 2xl:grid-cols-2 gap-2 2xl:gap-4">
            {votingResults.votes.map((voter, index) => {
              if (voter) {
                return <VotingResultCard voter={voter} key={index} />;
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InlineVotingResults;
