import { Fragment, useState } from "react";

import { Transition, Listbox } from "@headlessui/react";

import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

// import Error from "../Shared/Error";

const positions = [
  { id: "F", name: "FOR" },
  { id: "A", name: "AGAINST" },
  { id: "U", name: "UNDECIDED" },
];

function SpeakerModal({ sendEvent, topic, visible }) {
  // const [actionError, setActionError] = useState(false);
  const [speaker, setSpeaker] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    position: positions[0],
    speakerType: "S",
    attendOrCall: "A",
    representingOrg: "",
    comments: "",
    manuallyAdded: true,
  });

  function handleChange(target, value) {
    setSpeaker({
      ...speaker,
      [target]: value,
    });
  }

  function handlePosition(value) {
    setSpeaker({
      ...speaker,
      position: value,
    });
  }

  return (
    <Transition
      show={true}
      appear={true}
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed z-40 inset-0 overflow-hidden">
        <div className="flex flex-col min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-coolGray-900 bg-opacity-75 transition-opacity"></div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          {/* Start Modal */}
          <div className="inline-block bg-coolGray-700 rounded-lg text-left transform transition-all mx-auto sm:align-middle max-w-7xl 2xl:max-w-5xl sm:w-full sm:px-6 sm:pt-2 sm:pb-8">
            {topic && (
              <div className="flex flex-col space-y-2 w-full">
                <div>
                  <div className="mt-3 text-center">
                    <h3
                      className="text-lg 2xl:text-2xl leading-6 font-medium text-blueGray-200"
                      id="modal-title"
                    >
                      New Speaker for {topic.itemNumber}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-blueGray-300 mb-2">
                        Please fill out all fields to create a new speaker for
                        this action.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-4 grid grid-cols-4 gap-2 2xl:gap-4 pb-8">
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-coolGray-400">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={speaker.firstName}
                      className="mt-1 block w-full border-2 border-amber-600 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("firstName", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-coolGray-400">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={speaker.lastName}
                      className="mt-1 block w-full border-2 border-amber-600 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("lastName", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-coolGray-400">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={speaker.email}
                      className="mt-1 block w-full border-2 border-amber-600 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("email", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-coolGray-400">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={speaker.phoneNumber}
                      className="mt-1 block w-full border-2 border-amber-600 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("phoneNumber", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-span-4">
                    <label className="block text-sm font-medium text-coolGray-400">
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={speaker.address}
                      className="mt-1 block w-full border-2 border-coolGray-400 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("address", e.target.value);
                      }}
                    />
                  </div>
                  <div className="">
                    <label className="block text-sm font-medium text-coolGray-400">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={speaker.city}
                      className="mt-1 block w-full border-2 border-coolGray-400 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("city", e.target.value);
                      }}
                    />
                  </div>
                  <div className="">
                    <label className="block text-sm font-medium text-coolGray-400">
                      State
                    </label>
                    <input
                      type="text"
                      name="state"
                      value={speaker.state}
                      className="mt-1 block w-full border-2 border-coolGray-400 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("state", e.target.value);
                      }}
                    />
                  </div>
                  <div className="">
                    <label className="block text-sm font-medium text-coolGray-400">
                      Zip Code
                    </label>
                    <input
                      type="text"
                      name="zip"
                      value={speaker.zip}
                      className="mt-1 block w-full border-2 border-coolGray-400 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("zip", e.target.value);
                      }}
                    />
                  </div>
                  <div className="">
                    <label className="block text-sm font-medium text-coolGray-400">
                      Position
                    </label>
                    <Listbox value={speaker.position} onChange={handlePosition}>
                      {({ open }) => (
                        <>
                          <div className="relative mt-1">
                            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-coolGray-700 rounded-md border-2 border-amber-600 shadow-md cursor-default focus:outline-none sm:text-sm">
                              <span className="block truncate text-coolGray-200">
                                {speaker.position.name}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="w-5 h-5 text-coolGray-200 p-0.5"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options
                                static
                                className="absolute w-full py-1 mt-1 overflow-auto text-base bg-coolGray-200 rounded-md shadow-2xl max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50 border-l border-r border-b border-gray-600"
                              >
                                {positions.map((person, personIdx) => (
                                  <Listbox.Option
                                    key={personIdx}
                                    className={({ active }) =>
                                      `${
                                        active
                                          ? "text-white bg-coolGray-600"
                                          : "text-gray-900"
                                      }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                    }
                                    value={person}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={`${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          } block truncate`}
                                        >
                                          {person.name}
                                        </span>
                                        {selected ? (
                                          <span
                                            className={`${
                                              active
                                                ? "text-white"
                                                : "text-gray-900"
                                            }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                          >
                                            <CheckIcon
                                              className="w-5 h-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="col-span-4">
                    <label className="block text-sm font-medium text-coolGray-400">
                      Representing
                    </label>
                    <input
                      type="text"
                      name="representingOrg"
                      value={speaker.representingOrg}
                      className="mt-1 block w-full border-2 border-coolGray-400 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("representingOrg", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-span-4">
                    <label className="block text-sm font-medium text-coolGray-400">
                      Comments
                    </label>
                    <textarea
                      rows="2"
                      name="comments"
                      value={speaker.comments}
                      className="mt-1 block w-full border-2 border-coolGray-400 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                      onChange={(e) => {
                        handleChange("comments", e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-5 sm:mt-20 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:col-start-2 sm:text-sm"
                    onClick={() => {
                      sendEvent("addSpeaker", {
                        speaker: {
                          ...speaker,
                          position: speaker.position.id,
                        },
                        topic: topic._id,
                      });
                      setSpeaker({
                        firstName: "",
                        lastName: "",
                        email: "",
                        phoneNumber: "",
                        address: "",
                        city: "",
                        state: "",
                        zip: "",
                        position: positions[0],
                        speakerType: "S",
                        attendOrCall: "A",
                        representingOrg: "",
                        comments: "",
                        manuallyAdded: true,
                      });
                      visible(false);
                    }}
                  >
                    Add
                  </button>

                  <button
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-coolGray-400 text-coolGray-400 shadow-sm px-4 py-2 bg-coolGray-700 text-base font-medium hover:bg-coolGray-800 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => visible(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* End Modal */}
        </div>
      </div>
    </Transition>
  );
}

export default SpeakerModal;
