import React from "react";
import { useRecoilValue } from "recoil";

import { useSocket } from "../hooks/useSocket";
import {
  activeDetailsState,
  motionedByState,
  countdownState,
  micState,
  liveRollCallState,
  liveVotingResultsState,
  liveSpeakerState,
  motionStatusState,
} from "../atoms/session.atom";
import {
  speakerStatusState,
  speakerTimeNumberState,
} from "../atoms/globals.atom";
import { soloSettingsState } from "../atoms/settings.atom";

import NoActiveLive from "../components/Modals/NoActiveLive";
import VotingResults from "../components/InMeeting/LiveVotingResults";
// import LiveMotioner from "../components/InMeeting/LiveMotioner";
import LiveDetails from "../components/InMeeting/LiveDetails";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Live() {
  const activeDetails = useRecoilValue(activeDetailsState);
  const votingResults = useRecoilValue(liveVotingResultsState);
  const motionedBy = useRecoilValue(motionedByState);
  const speakerStatus = useRecoilValue(speakerStatusState);
  const countdown = useRecoilValue(countdownState);
  const speakerTimerNumber = useRecoilValue(speakerTimeNumberState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const liveRollCall = useRecoilValue(liveRollCallState);
  const members = useRecoilValue(micState);
  const liveSpeaker = useRecoilValue(liveSpeakerState);
  const motionStatus = useRecoilValue(motionStatusState);
  useSocket(soloSettings.backendServerUrl, "/live");

  if (liveRollCall) {
    return (
      <div className="flex flex-col max-h-screen text-center overflow-hidden h-screen bg-gradient-to-br from-zinc-900 via-slate-900 to-slate-800">
        <div className="grid grid-cols-2 rounded-lg px-4 pb-4 m-4 h-full">
          <div className="grid grid-cols-1 gap-x-2 gap-y-1 col-span-2">
            <div className="flex flex-col flex-nowrap space-y-4 pr-2">
              <div className="inline-flex justify-between border-b-2 items-center border-grey-800 pb-2">
                <img
                  className="h-24 2xl:h-36 w-auto"
                  src={soloSettings.entityLogo}
                  alt=""
                />
                <h2 className="text-center 2xl:text-6xl font-bold text-zinc-200 italic">
                  <span className="font-medium">Roll Call</span>
                </h2>
              </div>
              <div className="mt-2 w-full pb-2 overflow-hidden">
                <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-8">
                  {members?.map((member, index) => {
                    return (
                      <li
                        className={`col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200
                      ${member.loggedIn ? "bg-green-400" : "bg-white"}
                      `}
                        key={index}
                      >
                        <div className="flex-1 flex flex-col py-2">
                          {!soloSettings.hideImages && (
                            <img
                              className="w-24 h-24 flex-shrink-0 mx-auto bg-black rounded-lg"
                              src={member.photoUrl}
                              alt=""
                            />
                          )}
                          <h3 className="mt-2 text-gray-900 text-base font-medium 2xl:text-2xl">
                            {member.name}
                          </h3>
                          <p className="text-gray-700 text-base 2xl:text-xl">
                            {member.title}
                          </p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!activeDetails) {
    return <NoActiveLive />;
  }

  if (votingResults.votes) {
    return <VotingResults />;
  }

  return (
    <div className="flex flex-col max-h-screen text-center overflow-hidden h-screen bg-gradient-to-br from-zinc-900 via-slate-900 to-slate-800">
      <div className="grid grid-cols-2 px-4 pb-4 m-4 h-full">
        <div className="grid grid-cols-1 gap-x-2 gap-y-1 col-span-2">
          <div className="flex flex-col flex-nowrap space-y-2 pr-2">
            <div className="inline-flex justify-between border-b-2 items-center border-grey-800 pb-2">
              <img
                className="h-24 2xl:h-36 w-auto"
                src={soloSettings.entityLogo}
                alt=""
              />
              {speakerStatus && !motionStatus && (
                <div
                  className={`flex flex-col bg-gray-600 text-white p-4 rounded-md shadow-lg
                    ${
                      speakerTimerNumber <= 30 &&
                      speakerTimerNumber > 10 &&
                      "bg-yellow-500 text-gray-900"
                    }
                ${speakerTimerNumber <= 10 && "bg-red-600 text-white"}
                `}
                >
                  <p className="text-6xl font-semibold italic">
                    Timer: {countdown}
                  </p>
                  {liveSpeaker &&
                    typeof liveSpeaker === "object" &&
                    !soloSettings?.hidePublicSpeaker && (
                      <p className="text-6xl font-semibold italic">
                        {liveSpeaker.firstName} {liveSpeaker.lastName}
                      </p>
                    )}
                  {liveSpeaker && typeof liveSpeaker === "string" && (
                    <p className="text-6xl font-semibold italic">
                      {liveSpeaker}
                    </p>
                  )}
                </div>
              )}
              <h2
                className={classNames(
                  "text-center font-bold text-zinc-200 italic text-6xl"
                )}
              >
                {/* <span className="font-medium">Item: </span> */}
                {activeDetails?.itemNumber}
              </h2>
            </div>
            <div>
              <p className="text-4xl 2xl:text-7xl leading-8 text-zinc-200">
                {activeDetails?.title}
              </p>
            </div>
            <LiveDetails details={activeDetails?.more} />
          </div>
        </div>
      </div>
      {motionStatus && (
        <div className="absolute inset-x-0 bottom-0 rounded-md bg-blue-400 px-2 py-8 mb-2">
          <div className="block space-y-2 w-full px-4">
            <h3 className="text-5xl font-medium text-gray-900 text-center mb-6">
              {motionStatus} Motion in Progress...
            </h3>
            <div className="text-white grid grid-cols-2 gap-6 font-bold items-center">
              {motionedBy?.map((member, index) => {
                return (
                  <div
                    className="flex flex-col items-center text-center"
                    key={index}
                  >
                    <p className="italic font-light text-black text-4xl mb-2">
                      {index === 0 ? "Motioned By:" : "Seconded By:"}
                    </p>
                    <img
                      src={member.photoUrl}
                      alt=""
                      className="flex-shrink-0 h-24 w-24 rounded-xl"
                    />
                    <p className="text-white text-5xl">
                      {member.firstName} {member.lastName}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Live;
