import firebase from "firebase/app";

import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAN9_R3lLH0nPWzIpwS62a7VsJfe3IqQ84",
  authDomain: "fast-archive-274904.firebaseapp.com",
  databaseURL: "https://fast-archive-274904.firebaseio.com",
  projectId: "fast-archive-274904",
  storageBucket: "fast-archive-274904.appspot.com",
  messagingSenderId: "523023692861",
  appId: "1:523023692861:web:28ac524227750d3174a654",
  measurementId: "G-D9WJ6ER6B7",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
