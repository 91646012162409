import React from "react";
import { useRecoilValue } from "recoil";

import { soloSettingsState } from "../../atoms/settings.atom";

import DefaultSeat from "./DefaultSeat";

const MemberRow = ({ member, edit, index, refresh }) => {
  const soloSettings = useRecoilValue(soloSettingsState);

  function handleSeat(value) {
    refresh(index, "defaultSeat", value.toString());
  }

  return (
    <tr>
      <td className="px-4 py-1 xl:px-6 xl:py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img
              className="h-8 w-8 xl:h-10 xl:w-10 rounded-full"
              src={member.photoUrl}
              alt=""
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {member.firstName} {member.lastName}
            </div>
          </div>
          {member.managerRole && (
            <div className="ml-2 bg-indigo-500 rounded-full py-0.5 px-4">
              <p className="text-white">Manager</p>
            </div>
          )}
        </div>
      </td>
      <td className="px-4 py-1 xl:px-6 xl:py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{member.title}</div>
      </td>
      {/* <td className="px-4 py-1 xl:px-6 xl:py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {member.district === 0
            ? "No District"
            : `District ${member.district}`}
        </div>
      </td> */}
      <td className="px-4 py-1 xl:px-6 xl:py-4 whitespace-nowrap">
        <div className="flex">
          <p className="flex">
            {member.defaultSeat === "0"
              ? "Not Assigned"
              : `Seat ${member.defaultSeat}`}
          </p>
          <DefaultSeat setSeat={handleSeat} />
        </div>
      </td>
      {soloSettings?.memberTimer && (
        <td className="px-4 py-1 xl:px-6 xl:py-4 whitespace-nowrap">
          <input
            id={index}
            name="bypassTimer"
            type="checkbox"
            checked={member.bypassTimer}
            className="ml-4 2xl:ml-8 h-4 w-4 text-indigo-600 bg-gray-50 rounded mb-1 focus:outline-none"
            onChange={() => refresh(index, "bypassTimer", !member.bypassTimer)}
          />
        </td>
      )}
      <td className="px-4 py-1 xl:px-6 xl:py-4 whitespace-nowrap">
        <input
          id={index}
          name="votingRole"
          type="checkbox"
          checked={member.votingRole}
          className="ml-4 h-4 w-4 text-indigo-600 bg-gray-50 rounded mb-1 focus:outline-none"
          onChange={() => refresh(index, "votingRole", !member.votingRole)}
        />
      </td>
      <td className="px-4 py-1 xl:px-6 xl:py-4 whitespace-nowrap text-sm text-gray-500">
        <input
          id={index}
          name="adminRole"
          type="checkbox"
          checked={member.adminRole}
          className="ml-4 2xl:ml-8 h-4 w-4 text-indigo-600 bg-gray-50 rounded mb-1 focus:outline-none"
          onChange={() => refresh(index, "adminRole", !member.adminRole)}
        />
      </td>
      <td className="px-4 py-1 xl:px-6 xl:py-4 whitespace-nowrap text-right text-sm font-medium">
        <div
          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
          onClick={() => edit(member._id)}
        >
          Edit
        </div>
      </td>
    </tr>
  );
};

export default MemberRow;
