/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import clsx from "clsx";

import {
  voterState,
  activeTopicState,
  // activeMotionState,
} from "../../atoms/session.atom";
import { testModeState } from "../../atoms/globals.atom";

import MotionDrop from "../Shared/DropDown/MotionDrop";

const MotionItem = ({ sendEvent, motion, startVote }) => {
  const voters = useRecoilValue(voterState);
  const activeTopic = useRecoilValue(activeTopicState);
  const testMode = useRecoilValue(testModeState);
  // const activeMotion = useRecoilValue(activeMotionState);
  const [first, setFirst] = useState({ id: "none", name: "Please Choose" });
  const [second, setSecond] = useState({ id: "none", name: "Please Choose" });

  useEffect(() => {
    if (motion.motionedBy) {
      setFirst({
        id: motion.motionedBy._id,
        name: motion.motionedBy.firstName + " " + motion.motionedBy.lastName,
      });
    }
    if (motion.secondedBy) {
      setSecond({
        id: motion.secondedBy._id,
        name: motion.secondedBy.firstName + " " + motion.secondedBy.lastName,
      });
    }
  }, [motion]);

  function handleMotion(target, value) {
    if (target === "motionedby") {
      // setFirst(value);
      sendEvent("newMotion", {
        topic: activeTopic,
        member: value.id,
        level: 1,
        test: testMode,
        motion: motion._id,
      });
    } else {
      // setSecond(value);
      sendEvent("newMotion", {
        topic: activeTopic,
        member: value.id,
        level: 2,
        test: testMode,
        motion: motion._id,
      });
    }
  }

  function handleQuickVote() {
    sendEvent("quickvote", {
      motion: motion._id,
      topic: activeTopic,
      voters,
    });
  }

  function handleCancel() {
    sendEvent("deleteMotion", {
      topic: activeTopic,
      motion: motion._id,
    });
  }

  return (
    <div className="w-full rounded-md bg-zinc-100 py-1 2xl:py-2 px-2 2xl:px-4 border border-zinc-400 shadow-sm mb-3">
      <p className="font-semibold text-black text-sm 2xl:text-base">
        {motion.motionType} Motion
      </p>

      {motion.votes.length === 0 ? (
        <div className="grid grid-cols-2 gap-2 2xl:gap-4 mt-2">
          {motion.motionType === "Failed" ? (
            <p className="text-sm 2xl:text-base">
              Motioned By:{" "}
              {motion.motionedBy.firstName + " " + motion.motionedBy.lastName}{" "}
            </p>
          ) : (
            <MotionDrop
              array={voters}
              value={first}
              change={handleMotion}
              heading="Motioned By"
            />
          )}

          {motion.motionedBy && motion.motionType !== "Failed" && (
            <MotionDrop
              array={voters}
              value={second}
              change={handleMotion}
              heading="Seconded By"
            />
          )}
        </div>
      ) : (
        <div className="flex space-x-1 mt-2">
          {motion.motionedBy && (
            <p className="text-sm 2xl:text-base">
              Motioned By:{" "}
              <span className="font-medium">
                {motion.motionedBy.firstName + " " + motion.motionedBy.lastName}
              </span>
            </p>
          )}
          {motion.secondedBy && (
            <p className="text-sm 2xl:text-base">
              and Seconded By:{" "}
              <span className="font-medium">
                {motion.secondedBy.firstName + " " + motion.secondedBy.lastName}
              </span>
            </p>
          )}
        </div>
      )}

      <div className="flex justify-end w-full space-x-4 mt-2">
        <button
          type="button"
          className={clsx(
            motion.secondedBy && motion.votes.length === 0 ? "block" : "hidden",
            "rounded-md border border-transparent shadow-sm px-4 py-3 bg-blue-700 text-base font-medium text-white hover:bg-opacity-70 focus:outline-none sm:text-sm transform duration-200"
          )}
          onClick={() => handleQuickVote()}
        >
          Quick Vote
        </button>
        {motion.motionedBy &&
          !motion.secondedBy &&
          motion.motionType !== "Failed" && (
            <button
              type="button"
              className="rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-opacity-70 focus:outline-none sm:text-sm"
              onClick={() => handleCancel()}
            >
              Fail Motion
            </button>
          )}

        <button
          type="button"
          className={clsx(
            motion.secondedBy && motion.votes.length === 0 ? "block" : "hidden",
            "rounded-md border border-transparent shadow-sm px-4 py-3 bg-green-700 text-base font-medium text-white hover:bg-opacity-70 focus:outline-none sm:text-sm transform duration-200"
          )}
          onClick={() => startVote(motion)}
        >
          Start Vote
        </button>
      </div>
    </div>
  );
};

export default MotionItem;
