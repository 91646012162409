import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { managerUserState } from "../../atoms/user.atom";
import { backendServerState } from "../../atoms/settings.atom";
import { apiGetFile, apiUpdateFile } from "../../api/notes";

const FileUrl = ({ clear, topic, save }) => {
  const [file, setFile] = useState("");
  const user = useRecoilValue(managerUserState);
  const backendServer = useRecoilValue(backendServerState);

  useEffect(() => {
    async function fetchData() {
      const result = await apiGetFile(backendServer, topic);
      setFile(result);
    }
    if (backendServer !== undefined) {
      fetchData();
    }
  }, [topic, backendServer]);

  async function handleFileSave() {
    const result = await apiUpdateFile(backendServer, file, topic, user?.token);
    save(result);
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => clear()}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col items-center px-2">
            <div className="mt-3 text-center sm:mt-0 w-full">
              <h3 className="px-4 text-lg font-medium text-gray-900">
                Paste a public url below for a{" "}
                <span className="font-bold">PDF</span> you would like to be
                available to members during the meeting.
              </h3>
              <div className="mt-2">
                <div className="max-w-2xl w-full flex flex-col space-y-4">
                  <input
                    type="text"
                    name="fileUrl"
                    id="fileUrl"
                    value={file}
                    className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-800 rounded-md mb-4 border p-2"
                    onChange={(e) => setFile(e.target.value)}
                  />

                  <button
                    onClick={() => handleFileSave()}
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUrl;
