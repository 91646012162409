import axios from "axios";

export const apiScrapeUrl = async (entity, url) => {
  try {
    const result = await axios
      .post(`${process.env.REACT_APP_SYNC_URL}/scrape/${entity}`, { url })
      .then(function (response) {
        if (response.status === 200) {
          const { topics } = response.data;
          return topics;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.log("error api scrape apiScrapeUrl");
    console.log(error);
    return null;
  }
};
