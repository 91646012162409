import React, { useState, Fragment } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";

import {
  topicSpeakersState,
  countdownState,
  podiumMicState,
} from "../../atoms/session.atom";
import {
  speakerModalContentState,
  speakerModalState,
} from "../../atoms/globals.atom";
import { removeArrayEntry } from "../../helpers/arrays";
import { soloSettingsState } from "../../atoms/settings.atom";

const InMeetingSpeaker = ({ sendEvent, visible }) => {
  const setSpeakerModal = useSetRecoilState(speakerModalState);
  const [speaker, setSpeaker] = useRecoilState(speakerModalContentState);
  const topicSpeakers = useRecoilValue(topicSpeakersState);
  const countdown = useRecoilValue(countdownState);
  const [calledOn, setCalledOn] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const soloSettings = useRecoilValue(soloSettingsState);
  const [podiumMic, setPodiumMic] = useRecoilState(podiumMicState);

  function handleSpeaker(action) {
    switch (action) {
      case "call":
        setCalledOn(true);
        sendEvent("speakerCall", {
          speaker,
        });
        break;
      case "remove":
        let newList = [...topicSpeakers];
        newList = removeArrayEntry(newList, speaker._id);
        if (!timerStarted) {
          sendEvent("liveTimerClear", {
            status: true,
          });
        }
        sendEvent("speakerRemove", {
          speaker: newList,
        });
        setCalledOn(false);
        setSpeakerModal(false);
        break;
      default:
        console.log("should never see this");
        break;
    }
  }

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={visible}
        onClose={setSpeakerModal}
      >
        {/* <div className="fixed z-10 inset-0 overflow-y-auto"> */}
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6"
          >
            {speaker && (
              <div
                // className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      if (calledOn) {
                        handleSpeaker("remove");
                      } else {
                        setCalledOn(false);
                        setSpeakerModal(false);
                        setTimeout(() => {
                          setSpeaker();
                        }, 300);
                      }
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg 2xl:text-3xl leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      {speaker.firstName} {speaker.lastName}
                    </h3>
                    <div className="mt-2">
                      <div className="flex-1 min-w-0">
                        <div className="flex flex-wrap justify-between items-center">
                          {speaker.district.length > 0 && (
                            <p className="text-sm xl:text-base 2xl:text-lg">
                              District: {speaker.district}
                            </p>
                          )}
                        </div>
                        <p className="text-xs xl:text-sm 2xl:text-xl italic">
                          {speaker.address}
                        </p>
                        {speaker.address.length > 0 && (
                          <p className="text-xs xl:text-sm 2xl:text-base italic">
                            {speaker.city}, {speaker.state} {speaker.zip}
                          </p>
                        )}
                        {speaker.subject.length > 0 && (
                          <p className="text-xs xl:text-sm 2xl:text-xl font-medium">
                            Subject: {speaker.subject}
                          </p>
                        )}
                        {speaker.representing.length > 0 && (
                          <p className="text-xs xl:text-sm 2xl:text-xl font-medium">
                            Representing: {speaker.representing}
                          </p>
                        )}
                        {speaker.representingOrg.length > 0 && (
                          <p className="text-xs xl:text-sm 2xl:text-xl font-medium">
                            Organization: {speaker.representingOrg}
                          </p>
                        )}
                        {speaker.oppositionOnly && (
                          <p className="text-xs xl:text-sm 2xl:text-xl font-semibold text-gray-900">
                            &#42;&#42; Opposition Only
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 flex justify-center">
                  {!calledOn && (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-4 bg-blue-500 text-base 2xl:text-lg font-medium text-white hover:bg-blue-700 focus:outline-none sm:w-auto sm:text-sm"
                      onClick={() => handleSpeaker("call")}
                    >
                      Call On Speaker
                    </button>
                  )}
                  {calledOn && (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-4 bg-red-600 text-base 2xl:text-lg font-medium text-white hover:bg-red-800 focus:outline-none sm:w-auto sm:text-sm"
                      onClick={() => handleSpeaker("remove")}
                    >
                      Remove speaker
                    </button>
                  )}
                </div>
                {calledOn && (
                  <div className="flex flex-col items-center mt-4 border-t border-gray-900">
                    <p className="px-2 text-lg xl:text-2xl text-gray-700 leading-relaxed tracking-wide mt-2">
                      Timer: {countdown}
                    </p>
                    <div className="items-center justify-center flex flex-nowrap w-full pb-2 space-x-4">
                      <button
                        className="w-32 h-14 px-4 py-2 rounded-md border border-green-800 bg-green-600 text-base xl:text-lg font-medium text-white hover:bg-gray-50 hover:text-gray-700 focus:outline-none mt-4"
                        onClick={() => {
                          sendEvent("serverSpeaker", {
                            command: "start",
                          });
                          setTimerStarted(true);
                        }}
                      >
                        Start
                      </button>

                      <button
                        className="w-32 h-14 px-4 py-2 rounded-md border border-red-800 bg-red-500 text-base xl:text-lg font-medium text-white hover:text-gray-700 hover:bg-gray-50 focus:outline-none mt-4"
                        onClick={() => {
                          sendEvent("serverSpeaker", {
                            command: "stop",
                          });
                        }}
                      >
                        Stop
                      </button>
                      <button
                        className="w-32 h-14 rounded-md bg-red-700 border border-red-700 text-base xl:text-lg font-medium text-white hover:bg-gray-50 hover:text-gray-700 focus:outline-none mt-4"
                        onClick={() => {
                          sendEvent("serverSpeaker", {
                            command: "pause",
                          });
                        }}
                      >
                        Pause
                      </button>

                      <button
                        className="w-32 h-14 px-4 py-2 rounded-md border border-indigo-800 bg-indigo-500 text-base xl:text-lg font-medium text-white hover:text-gray-700 hover:bg-gray-50 focus:outline-none mt-4"
                        onClick={() => {
                          sendEvent("serverSpeaker", {
                            command: "reset",
                          });
                        }}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="items-center justify-center flex flex-nowrap w-full pb-2 shadow-sm rounded-md space-x-4">
                      <button
                        className="w-32 h-14 px-4 py-2 border rounded-md border-gray-500 bg-white text-base xl:text-lg font-medium text-gray-700 hover:bg-gray-200 focus:outline-none mt-4"
                        onClick={() => {
                          sendEvent("serverSpeaker", {
                            command: "add",
                          });
                        }}
                      >
                        +1 Minute
                      </button>
                      <button
                        className="w-32 h-14 px-4 py-2 rounded-md border border-gray-500 bg-white text-base xl:text-lg font-medium text-gray-700 hover:bg-gray-200 focus:outline-none  mt-4"
                        onClick={() => {
                          sendEvent("serverSpeaker", {
                            command: "minus",
                          });
                        }}
                      >
                        -1 Minute
                      </button>
                    </div>
                  </div>
                )}
                {soloSettings?.podiumMuteButton && (
                  <div className="flex items-center justify-center">
                    <button
                      className={clsx(
                        podiumMic
                          ? "bg-green-600 text-white hover:bg-green-400"
                          : "bg-gray-400 text-black hover:bg-green-400",
                        "p-4 rounded-md text-base xl:text-lg font-medium transform duration-200 focus:outline-none mt-4"
                      )}
                      onClick={() => {
                        let command = "podiumOn";
                        if (podiumMic) {
                          command = "podiumOff";
                        }
                        setPodiumMic(!podiumMic);
                        sendEvent("customButton", {
                          command,
                        });
                      }}
                    >
                      Podium Mic
                    </button>
                  </div>
                )}
              </div>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InMeetingSpeaker;
