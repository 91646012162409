import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { backendServerState } from "../../atoms/settings.atom";
import { managerUserState } from "../../atoms/user.atom";
import { apiEndingNotes, apiGetEndingNotes } from "../../api/notes";

const EndingNotesModal = ({ meeting, show }) => {
  const [notes, setNotes] = useState("");
  const user = useRecoilValue(managerUserState);
  const backendServer = useRecoilValue(backendServerState);

  useEffect(() => {
    async function fetchData() {
      const result = await apiGetEndingNotes(backendServer, meeting);
      setNotes(result);
    }
    if (backendServer !== undefined) {
      fetchData();
    }
  }, [meeting, backendServer]);

  async function handleNotesshow(e) {
    // show notes api call
    e.preventDefault();
    await apiEndingNotes(backendServer, notes, meeting, user?.token);
    show(false);
  }

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="bg-white rounded-md text-gray-900 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => show(false)}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="text-center mt-6 sm:ml-4 sm:text-left w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900 text-justify">
                Notes entered here will be visible to the manager and meeting
                administrator before ending the meeting.
              </h3>
              <div className="mt-2">
                <form
                  className="mt-5 sm:flex sm:flex-col sm:items-center"
                  onSubmit={(e) => handleNotesshow(e)}
                >
                  <div className="max-w-5xl w-full">
                    <textarea
                      rows="4"
                      name="notes"
                      id="notes"
                      value={notes}
                      className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm lg:text-base border-gray-800 rounded-md mb-4 border p-2"
                      onChange={(e) => setNotes(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndingNotesModal;
