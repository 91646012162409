import cx from "classnames";
import { forwardRef } from "react";

const Button = forwardRef(({ children, ...props }, ref) => (
  <button
    ref={ref}
    {...props}
    className={cx(
      "inline-flex select-none items-center justify-center rounded-full p-2 text-sm font-medium",
      "bg-green-500 hover:bg-green-700 transform duration-300",
      "hover:bg-green-700 hover:scale-110",
      "focus:outline-none",
      // Register all radix states
      "group",
      "radix-state-open:bg-gray-900",
      "radix-state-on:bg-gray-900",
      "radix-state-instant-open:bg-gray-50 radix-state-delayed-open:bg-gray-50"
    )}
  >
    {children}
  </button>
));

Button.displayName = "Button";

export default Button;
