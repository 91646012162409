import React from "react";
import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";

import { sessionBoardState } from "../../atoms/session.atom";
import { logoutUserState } from "../../atoms/options";

import { AiOutlineClose } from "react-icons/ai";

const LogoutModal = ({ isVisible, sendEvent }) => {
  const sessionBoard = useRecoilValue(sessionBoardState);
  const toLogout = useRecoilValue(logoutUserState);

  function logoutUser() {
    sendEvent("manualLogout", {
      user: toLogout.id,
      board: sessionBoard,
    });
    isVisible(false);
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <motion.div
            className="absolute inset-0 bg-blueGray-800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            transition={{ duration: 0.4 }}
            onClick={() => isVisible(false)}
          ></motion.div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          style={{ minHeight: "20rem" }}
        >
          <div className="flex flex-col">
            <div className="flex justify-between items-center">
              <p className="font-semibold text-gray-800 text-xl">Logout User</p>
              <AiOutlineClose
                className="h-6 w-6 text-gray-800 cursor-pointer hover:text-gray-600"
                onClick={() => isVisible(false)}
              />
            </div>

            <div className="flex flex-col px-6 mt-12">
              <div className="flex flex-col px-6 justify-end">
                <p className="mb-1 mt-4 text-center text-base xl:text-xl font-medium rounded-md text-zinc-800 focus:outline-none">
                  Logout {toLogout?.name}
                </p>
                <button
                  className="mt-4 inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base xl:text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LogoutModal;
