import axios from "axios";

export const apiUpdateIntegrations = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    };

    const result = await axios
      .patch(
        `${process.env.REACT_APP_MANAGER}/api/entity/${process.env.REACT_APP_SOLO_ID}`,
        { data },
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          return true;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.log("error api edge apiUpdateIntegrations");
    console.log(error);
    return null;
  }
};
