import React from "react";
import Dropzone from "react-dropzone";
import firebase from "../../firebase/firebase";
import { useRecoilState } from "recoil";

import { soloSettingsState } from "../../atoms/settings.atom";
import { setSessionStorage } from "../../helpers/storage";

const Entity = ({ save }) => {
  const [soloSettings, setSoloSettings] = useRecoilState(soloSettingsState);

  function handleEntityPhoto(files) {
    try {
      var storageRef = firebase
        .storage()
        .ref(`entity-logos/${soloSettings.entityName}/${files[0].name}`);
      var status = storageRef.put(files[0]);
      status.on(
        "state_changed",
        function progress(snapshot) {
          var progress = ~~(
            (snapshot.bytesTransferred / snapshot.totalBytes) *
            100
          );
          if (progress > 0) {
          }
        },
        function error(err) {
          console.log(err);
          // toast.error("Error During Upload", {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });
        },
        function complete() {
          // get url of file
          status.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setSoloSettings({
              ...soloSettings,
              entityLogo: downloadURL,
            });
            setSessionStorage("config", {
              ...soloSettings,
              entityLogo: downloadURL,
            });
            setTimeout(() => {
              save();
            }, 1000);
          });
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="space-y-8 divide-y divide-gray-200 mt-10">
      <div className="divide-y divide-gray-200 space-y-2 xl:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center pt-2 xl:pt-5">
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="flex items-center">
              {/* <span className="h-42 w-42 rounded-full overflow-hidden bg-gray-100"> */}
              <img
                className="h-20 w-auto"
                src={soloSettings.entityLogo}
                alt=""
              />
              {/* </span> */}
              <Dropzone
                onDrop={(e) => handleEntityPhoto(e)}
                multiple={false}
                accept="image/png, image/jpeg, image/jpg"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <button
                      type="button"
                      className="ml-5 bg-white py-2 px-3 border border-gray-600 rounded-md shadow-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Change Logo
                    </button>
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entity;
