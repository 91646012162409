import React from "react";
import { useRecoilState } from "recoil";
import {
  soloSettingsState,
  settingsChangedState,
} from "../../atoms/settings.atom";

import Toggle from "../Shared/Toggle";

const PdfOptions = ({ save }) => {
  const [soloSettings, setSoloSettings] = useRecoilState(soloSettingsState);
  const [changes, setChanges] = useRecoilState(settingsChangedState);

  function handleToggle(value) {
    setSoloSettings({
      ...soloSettings,
      automatedMinutes: value,
    });
    setChanges(true);
  }

  return (
    <form
      className="space-y-8 divide-y divide-gray-200 mt-10"
      onSubmit={(e) => {
        e.preventDefault();
        save();
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Automatic Meeting Minutes
            </h3>
          </div>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <Toggle value={soloSettings.automatedMinutes} action={handleToggle} />
                <span className="flex-grow flex flex-col" id="toggleLabel">
                  <span className="text-lg font-medium text-gray-900">
                    Email
                  </span>
                  <span className="text-base leading-normal text-gray-500 pb-4">
                    Enabling this feature will automatically email meeting
                    minutes upon completion.
                  </span>
                </span>
              </div>
            </div>
          </div>
          {/* <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start ">
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email Address to Send Minutes
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="email"
                    id="timerDefault"
                    value={automatedEmailsTo}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {changes && (
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="w-32 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </form>
  );
};

export default PdfOptions;
