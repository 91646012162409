import React from "react";
import { useRecoilState } from "recoil";
import { rollCallState } from "../../atoms/session.atom";

function RollCall({ sendEvent }) {
  const [rollCall, setRollCall] = useRecoilState(rollCallState);
  return (
    <div className="w-full mt-2">
      <div
        className={`h-full w-full rounded-lg overflow-hidden cursor-pointer shadow transform py-2 xl:py-4 2xl:py-6 text-white
        ${
          rollCall
            ? "bg-red-600 hover:bg-red-800"
            : "bg-green-600 hover:bg-green-900"
        }
        `}
        onClick={() => {
          sendEvent("rollCall", {
            status: !rollCall,
          });
          setRollCall(!rollCall);
        }}
      >
        <div className="px-4 py-2">
          {!rollCall && (
            <h1 className="text-base 2xl:text-lg font-medium text-white title-font">
              Start Roll Call
            </h1>
          )}
          {rollCall && (
            <h1 className="text-base 2xl:text-lg font-medium text-white title-font">
              End Roll Call
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}

export default RollCall;
