import cx from "classnames";

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { MdEdit } from "react-icons/md";

function InsertBoard({ setSeat }) {
  return (
    <div className="relative inline-block text-left">
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          <button
            className={cx(
              "text-gray-900 ml-2 rounded-full",
              "transform duration-300",
              "hover:text-blue-400",
              "focus:outline-none",
              // Register all radix states
              "group",
              "radix-state-open:bg-gray-900",
              "radix-state-on:bg-gray-900",
              "radix-state-instant-open:bg-gray-50 radix-state-delayed-open:bg-gray-50"
            )}
          >
            <MdEdit className="w-5 h-5" />
          </button>
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          align="end"
          sideOffset={5}
          className={cx(
            "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down",
            "w-48 max-h-64 overflow-y-auto rounded-lg px-1.5 py-1 shadow-md md:w-56",
            "bg-gray-800 border border-gray-400"
          )}
        >
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setSeat("0")}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              No Seat
            </span>
          </DropdownMenuPrimitive.Item>
          {Array(30)
            .fill("0")
            .map((value, index) => {
              return (
                <DropdownMenuPrimitive.Item
                  key={index * 200}
                  className={cx(
                    "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
                    "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
                  )}
                  onClick={() => setSeat(index + 1)}
                >
                  <span className="flex-grow text-gray-300 group-hover:text-gray-800">
                    Seat {index + 1}
                  </span>
                </DropdownMenuPrimitive.Item>
              );
            })}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </div>
  );
}

export default InsertBoard;
