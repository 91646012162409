import React, { Fragment, useState } from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
  consentTopicState,
  sessionState,
  activeTopicState,
  activeDetailsState,
} from "../../atoms/session.atom";
import { testModeState, prevTopicState } from "../../atoms/globals.atom";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon, ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { BiArrowToLeft } from "react-icons/bi";
import { userState } from "../../atoms/user.atom";

function ModalConsentAgenda({ sendEvent, open, show, nextTopic }) {
  const [topic, setTopic] = useState();
  const [showReview, setShowReview] = useState(false);
  const user = useRecoilValue(userState);
  const consentTopics = useRecoilValue(consentTopicState);
  const sessionData = useRecoilValue(sessionState);
  const testMode = useRecoilValue(testModeState);
  const [activeTopic, setActiveTopic] = useRecoilState(activeTopicState);
  const setPrevTopic = useSetRecoilState(prevTopicState);
  const setActiveDetails = useSetRecoilState(activeDetailsState);

  function handleActivate(topic) {
    let prevEmit = null;
    if (activeTopic) {
      prevEmit = activeTopic;
      setPrevTopic(activeTopic);
      nextTopic();
    }
    setActiveTopic(topic._id);
    setActiveDetails(topic);
    sendEvent("consentSelected", {
      session: sessionData,
      id: topic._id,
      prevTopic: prevEmit,
      testMode,
    });
    show(false);
    setShowReview(false);
  }

  function handleConfirm(topic) {
    setTopic(topic);
    setShowReview(true);
  }

  function handleClose() {
    setShowReview(false);
    setTopic();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={open}
        onClose={show}
      >
        <div className="absolute inset-0 overflow-hidden bg-gray-600 bg-opacity-50">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 pr-10 max-w-full flex sm:pr-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="w-screen max-w-lg">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="py-6 px-4 bg-gray-700 xl:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-white">
                        Consent Agenda
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className="rounded-md text-white hover:text-gray-200 focus:outline-none transform duration-200"
                          onClick={() => show(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {!showReview && (
                    <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto transform duration-200">
                      {consentTopics.map((topic) => (
                        <li key={topic._id}>
                          <div
                            className="relative group py-6 px-5 flex items-center cursor-pointer"
                            onClick={() => handleConfirm(topic)}
                          >
                            <div
                              className="absolute inset-0 group-hover:bg-blue-200"
                              aria-hidden="true"
                            />
                            <div className="flex-1 flex items-center justify-between min-w-0 relative">
                              <div className="ml-4 truncate">
                                <p className="text-sm 2xl:text-lg font-medium text-gray-900 truncate">
                                  {topic.itemNumber}
                                </p>
                              </div>

                              <ChevronDoubleRightIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                  {showReview && (
                    <div className="flex-1 flex flex-col justify-between transform duration-200">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="pt-2">
                          <BiArrowToLeft
                            className="h-8 w-8 cursor-pointer hover:text-blue-800 transform duration-300"
                            onClick={() => handleClose()}
                          />
                        </div>
                        <div className="space-y-4 pt-6 pb-5">
                          <div>
                            <label className="block text-sm font-medium text-gray-500 italic">
                              Item Number
                            </label>
                            <div className="mt-1">
                              <p className="text-md 2xl:text-lg">
                                {topic?.itemNumber}
                              </p>
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-500 italic">
                              Title
                            </label>
                            <div className="mt-1">
                              <p className="text-md 2xl:text-lg">
                                {topic?.title}
                              </p>
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-500 italic">
                              Details
                            </label>
                            <div className="mt-1">
                              <p className="text-md 2xl:text-lg">
                                {topic?.more}
                              </p>
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-500 italic">
                              Speakers
                            </label>
                            <div className="mt-1">
                              {topic?.speakers.length > 0 && (
                                <div>
                                  {topic?.speakers.map((speaker, index) => {
                                    return (
                                      <p
                                        className="text-md 2xl:text-lg"
                                        key={index}
                                      >
                                        {speaker.firstName} {speaker.lastName}
                                      </p>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="pt-4 pb-6">
                          {user?.managerRole && (
                            <div className="px-2 py-2 flex justify-end">
                              <button
                                type="submit"
                                className="py-4 px-4 border shadow-sm text-sm 2xl:text-lg font-medium rounded-md text-white bg-red-600 hover:bg-red-800 focus:outline-none transform duration-300"
                                onClick={() => handleActivate(topic)}
                              >
                                Remove from Consent
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ModalConsentAgenda;
