import axios from "axios";

export const apiGetPendingMeetings = async (server, board) => {
  try {
    const result = await axios
      .get(`${server}/api/session/compact`, {
        params: { board },
      })
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api meeting apiGetPendingMeetings");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiGetRecentlyRun = async (server) => {
  try {
    const result = await axios
      .get(`${server}/api/session/recent`)
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api meeting apiGetRecentlyRun");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiSyncComments = async (server) => {
  try {
    const result = await axios
      .get(`${server}/api/session/fw`)
      .then(function (response) {
        return "success";
      });
    return result;
  } catch (error) {
    console.log("error api meeting apiSyncComments");
    console.log(error);
    return "error";
  }
};

export const apiGetActiveSession = async (server) => {
  try {
    const result = await axios
      .get(`${server}/api/session/active`)
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api meeting apiGetActiveSession");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiGetInProgress = async (server) => {
  try {
    const result = await axios
      .get(`${server}/api/session/progress`)
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error api meeting apiGetActiveSession");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiUpdateMeeting = async (server, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${server}/api/session/${data.id}`,
        {
          id: data.id,
          active: data.active,
          testMode: data.testMode,
          board: data.board,
        },
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.error("error in api meeting apiUpdateMeeting");
    console.error(error);
    return null;
  }
};

export const apiRestoreMeeting = async (server, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${server}/api/session/restore`,
        {
          id: id,
        },
        config
      )
      .then(function (response) {
        return response.status;
      });
    return result;
  } catch (error) {
    console.error("error in api meeting apiRestoreMeeting");
    console.error(error);
    return null;
  }
};

export const apiUpdateMeetingDetails = async (server, id, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(`${server}/api/session/details/${id}`, data, config)
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.error("error in api meeting apiUpdateMeetingData");
    console.error(error);
    return null;
  }
};

export const apiCloneMeeting = async (server, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .get(`${server}/api/session/clone?session=${id}`, config)
      .then(function (response) {
        return response.status;
      });
    return result;
  } catch (error) {
    console.error("error api meeting apiCloneMeeting");
    console.error(error);
    return null;
  }
};

export const apiDeleteMeeting = async (server, data, board, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .delete(`${server}/api/session/${data.id}`, config, {
        params: { board },
      })
      .then(function (response) {
        if (response.status === 200) {
          return "success";
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.error("error in api meeting apiDeleteMeeting");
    console.error(error);
    return "error";
  }
};

export const apiGetTopics = async (server, id) => {
  try {
    const result = await axios
      .get(`${server}/api/session/${id}`)
      .then(function (response) {
        return response.data;
      });
    return result;
  } catch (error) {
    console.log("error api meeting apiGetPendingMeetings");
    console.log(error);
    return { error: "issue" };
  }
};

// export const apiCreateSession = async (server, data) => {
//   try {
//     const result = await axios
//       .post(`${server}/api/session`, {
//         data,
//       })
//       .then(function (response) {
//         return response.data.data;
//       });
//     return result;
//   } catch (error) {
//     console.log("error in api meetings apiCreateSession");
//     return "error";
//   }
// };

export const apiCreateSessionHtml = async (server, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .post(
        `${server}/api/session/html`,
        {
          data,
        },
        config
      )
      .then(function (response) {
        return response.data.data;
      });
    return result;
  } catch (error) {
    console.log("error in api meetings apiCreateSession");
    console.log(error);
    return "error";
  }
};

export const apiUploadBoardBook = async (server, file) => {
  try {
    let formData = new FormData();
    formData.append("agenda", file);
    const result = await axios
      .post(`${server}/upload/boardbook`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log(response.data);
        const { data } = response.data;
        return data;
      });
    return result;
  } catch (error) {
    console.log("error in api meetings apiUploadFile");
    return "error";
  }
};

export const apiUploadCivicPlus = async (server, file) => {
  try {
    let formData = new FormData();
    formData.append("agenda", file);
    const result = await axios
      .post(`${server}/upload/civicplus`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        const { data } = response.data;
        return data;
      });
    return result;
  } catch (error) {
    console.log("error in api meetings apiUploadFile");
    return "error";
  }
};
