import axios from "axios";

export const apiCreateTopic = async (server, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .post(
        `${server}/api/topic/new`,
        {
          ...data,
        },
        config
      )
      .then((response) => {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error in api topics apiCreateTopic");
    return "error";
  }
};

export const apiUpdateTopic = async (server, data, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${server}/api/topic/${id}`,
        {
          ...data,
        },
        config
      )
      .then((response) => {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error in api topics apiUpdateTopic");
    return "error";
  }
};

export const apiRemoveTopic = async (server, id, token, granicus) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .delete(
        `${server}/api/topic/${id}?granicus=${granicus}`,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error in api topics apiUpdateTopic");
    return "error";
  }
};

export const apiUpdateConsent = async (server, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${server}/api/topic/consent/${id}`,
        {
          id: id,
        },
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error in api meeting apiUpdateMeeting");
    return { error: "issue" };
  }
};

export const apiImportTopics = async (server, data, token, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .post(
        `${server}/api/topic/import/${id}`,
        {
          data,
        },
        config
      )
      .then((response) => {
        if (response.status === 200) {
          return "success";
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error in api topics apiImportTopics");
    return "error";
  }
};
