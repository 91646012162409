import cx from "classnames";
import { some } from "lodash/collection";

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { AiOutlinePlus } from "react-icons/ai";
import { BsMic } from "react-icons/bs";

import Button from "./Button";

const generalMenuItems = [
  // {
  //   label: "CableCast",
  //   icon: <FiVideo className="mr-2 h-5 w-5" />,
  //   value: "cablecast",
  // },
  {
    label: "Televic Confero 360",
    icon: <BsMic className="mr-2 h-5 w-5" />,
    value: "confero",
  },
  {
    label: "Televic Plixus",
    icon: <BsMic className="mr-2 h-5 w-5" />,
    value: "plixus",
  },
];

function Dropdown({ insert, currentPlugins }) {
  return (
    <div className="relative inline-block text-left">
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          <Button>
            <AiOutlinePlus className="text-white w-6 h-6" />
          </Button>
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          align="end"
          sideOffset={5}
          className={cx(
            "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down",
            "w-48 rounded-lg px-1.5 py-1 shadow-md md:w-56",
            "bg-gray-800 border border-gray-400"
          )}
        >
          {generalMenuItems.map(({ label, icon, value }, i) => {
            if (!some(currentPlugins, ["id", value])) {
              return (
                <DropdownMenuPrimitive.Item
                  key={`${label}-${i}`}
                  className={cx(
                    "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
                    "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
                  )}
                  onClick={() => insert({ value, label })}
                >
                  {icon}
                  <span className="flex-grow text-gray-300 group-hover:text-gray-800">
                    {label}
                  </span>
                </DropdownMenuPrimitive.Item>
              );
            } else {
              return null;
            }
          })}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </div>
  );
}

export default Dropdown;
