import React, { useState, useEffect, Fragment } from "react";
import { useRecoilValue } from "recoil";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";

import { soloSettingsState } from "../../atoms/settings.atom";
import {
  apiGetTopics,
  apiSyncComments,
  apiUpdateMeetingDetails,
} from "../../api/meetings";
import { managerUserState } from "../../atoms/user.atom";
import {
  apiCreateTopic,
  apiUpdateConsent,
  apiImportTopics,
} from "../../api/topics";
import { apiGetGranicusMeeting } from "../../api/granicus";
import { apiCivicSpecific } from "../../api/civicClerk";

import { Transition, Dialog } from "@headlessui/react";
import { AiOutlineCalendar } from "react-icons/ai";

import ActiveTopicList from "./ActiveTopicList";
import ConsentTopicList from "./ConsentTopicList";
import ConsentModal from "./ConsentModal";
import Success from "../Shared/Success";
import Error from "../Shared/Error";
import OptionItem from "../Options/OptionItem";
import EndingNotesModal from "../Modals/EndingNotesModal";
import NewTopicSlideOut from "../Topics/NewTopicSlideOut";
import GranicusReviewCard from "./GranicusReviewCard";
import Loading from "../Shared/Loading";

import "react-datepicker/dist/react-datepicker.css";

const choices = ["Active Topics", "Consent Items"];

function MeetingDetails({ meeting, exit, server, refresh }) {
  const user = useRecoilValue(managerUserState);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState(0);
  const [eventId, setEventId] = useState();
  const [activeTopics, setActiveTopics] = useState([]);
  const [consentTopics, setConsentTopics] = useState([]);
  const [modal, setModal] = useState(false);
  const [remove, setRemove] = useState();
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [endNotesModal, setEndNotesModal] = useState(false);
  const [meetingId, setMeetingId] = useState();
  const [showNewTopic, setShowNewTopic] = useState(false);
  const [isGranicus, setIsGranicus] = useState(false);
  const [importAll, setImportAll] = useState(false);
  const [editSchedule, setEditSchedule] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const soloSettings = useRecoilValue(soloSettingsState);
  // Used for reviewing topics on first granicus pull
  const [review, setReview] = useState(false);
  const [reviewTopics, setReviewTopics] = useState([]);

  // TODO handle fetching from CivicClerk as well in here
  // to tell the difference maybe also use eventGuid to check if civic clerk or granicus
  useEffect(() => {
    async function fetchData() {
      const result = await apiGetTopics(server, meeting?.id);
      if (result.meeting.eventId) {
        setEventId(result.meeting.eventId);
        if (result.meeting.eventGuid) {
          setIsGranicus(true);
          // check if there any topics and if not then fetch from granicus
          if (
            result.activeTopics.length === 0 &&
            result.consentTopics.length === 0
          ) {
            // Do an import review if those are empty
            setLoading(true);
            const gTopics = await apiGetGranicusMeeting(
              server,
              soloSettings.granicusEntity,
              result.meeting.eventId
            );
            if (gTopics !== "error") {
              setReviewTopics(gTopics.eventItems);
              setReview(true);
            } else {
              handleError();
            }
            setLoading(false);
          }
        } else {
          // Civic Plus
          // check if there any topics and if not then fetch
          if (
            result.activeTopics.length === 0 &&
            result.consentTopics.length === 0
          ) {
            // Do an import review if those are empty
            setLoading(true);
            const cTopics = await apiCivicSpecific(
              soloSettings.clientId,
              result.meeting.eventId
            );
            if (cTopics) {
              setReviewTopics(cTopics);
              setReview(true);
            } else {
              handleError();
            }
            setLoading(false);
          }
        }
      } else {
        setEventId(result.meeting._id);
      }
      setActiveTopics(result.activeTopics);
      setConsentTopics(result.consentTopics);
      setMeetingId(result.meeting._id);
      const converted = parseISO(meeting.scheduleObject);
      setScheduleDate(converted);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting]);

  async function handleRemoveConsent(action) {
    if (action.type === "confirm") {
      const result = await apiUpdateConsent(
        soloSettings.backendServerUrl,
        remove._id,
        user?.token
      );
      setModal(false);
      setRemove();
      if (result === "error") {
        handleError();
        handleRefresh(false);
      } else {
        handleRefresh(true);
      }
    }
    setModal(false);
    setRemove();
  }

  function handleRemoveModal(topic) {
    setRemove(topic);
    setModal(true);
  }

  async function handleCreateTopic(topic) {
    const result = await apiCreateTopic(
      soloSettings.backendServerUrl,
      {
        soloId: eventId,
        ...topic,
        order: activeTopics.length + 1,
      },
      user?.token
    );
    if (result === "error") {
      handleRefresh(false);
    } else {
      handleRefresh(true);
    }
    setShowNewTopic(false);
  }

  async function handleRefresh(status) {
    const result = await apiGetTopics(server, meeting?.id);
    if (result.csv) {
      setEventId(result.meeting._id);
      setActiveTopics(result.activeTopics);
    } else {
      if (result.meeting.eventId) {
        setEventId(result.meeting.eventId);
        setIsGranicus(true);
      } else {
        setEventId(result.meeting._id);
      }
      setActiveTopics(result.activeTopics);
      setConsentTopics(result.consentTopics);
    }
    if (status) {
      handleSuccess();
    } else {
      handleError();
    }
  }

  function handleTopicAdd(value, index) {
    let newArray = Array.from(reviewTopics);
    newArray[index].approved = value;
    setReviewTopics(newArray);
    if (!value && importAll) {
      setImportAll(false);
    }
  }

  async function handleImportApprove() {
    const approvedArray = reviewTopics.reduce((arr, { approved, ...rest }) => {
      if (approved) {
        arr.push(rest);
      }
      return arr;
    }, []);
    setLoading(true);
    const result = await apiImportTopics(
      server,
      approvedArray,
      user?.token,
      eventId
    );
    if (result === "success") {
      handleSuccess();
      setReview(false);
      setReviewTopics([]);

      if (soloSettings.speakerComments) {
        await apiSyncComments(soloSettings.backendServerUrl);
      }
      // Now clear out and import the stuff
      const returnTopics = await apiGetTopics(server, meeting?.id);
      setEventId(returnTopics.meeting.eventId);
      if (returnTopics.meeting.eventGuid) {
        setIsGranicus(true);
      }
      setActiveTopics(returnTopics.activeTopics);
      setConsentTopics(returnTopics.consentTopics);
      setMeetingId(returnTopics.meeting._id);
      setLoading(false);
    } else {
      setLoading(false);
      handleError();
    }
  }

  async function handleImportAll() {
    setImportAll(!importAll);
    const newArray = reviewTopics.map((e) => {
      return { ...e, approved: !importAll };
    });
    setReviewTopics(newArray);
  }

  async function handleDateChange() {
    const result = await apiUpdateMeetingDetails(
      server,
      meeting.id,
      {
        scheduleDate,
      },
      user?.token
    );
    setEditSchedule(false);
    if (result) {
      handleSuccess();
      refresh();
    } else {
      handleError();
    }
  }

  function handleSuccess() {
    setAlertSuccess(true);
    setTimeout(() => {
      setAlertSuccess(false);
    }, [2000]);
  }

  function handleError() {
    setAlertError(true);
    setTimeout(() => {
      setAlertError(false);
    }, [2000]);
  }

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      <div className="py-2 2xl:py-4">
        <div className="flex justify-between items-center border-b-2 max-w-7xl mx-auto px-2 sm:px-6 md:px-4 lg:px-4">
          <div className="flex items-center space-x-4">
            <h1 className="text-base xl:text-xl 2xl:text-2xl font-semibold text-gray-900">
              {review ? "Review Found Topics" : "Details"}
            </h1>
            {!review && (
              <button
                type="button"
                className="inline-flex px-4 py-2 xl:py-3 mr-4 rounded-md shadow-sm text-sm 2xl:text-base font-medium text-gray-900 hover:text-indigo-700 focus:outline-none transform duration-300"
                onClick={() => setEditSchedule(true)}
              >
                <AiOutlineCalendar className="h-4 w-4 2xl:h-5 2xl:w-5 mr-2" />
                {format(scheduleDate, "MMMM do, yyyy")}
              </button>
            )}
          </div>
          <div className="flex items-center">
            {review && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 xl:py-3 mb-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none mr-4 transform duration-300"
                onClick={() => handleImportApprove()}
              >
                Approve Import
              </button>
            )}
            {!review && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 xl:py-3 mb-2 mr-4 border rounded-md shadow-sm text-sm font-medium text-white bg-coolGray-600 hover:bg-opacity-70 focus:outline-none transform duration-300"
                onClick={() => setEndNotesModal(true)}
              >
                Adjournement Notes
              </button>
            )}
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 xl:py-3 mb-2 rounded-md shadow-sm text-sm font-medium text-black border border-black hover:bg-coolGray-400 focus:outline-none transform duration-300"
              onClick={() => exit()}
            >
              {review ? "Cancel" : "Return to List"}
            </button>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-2 xl:px-4">
          <nav className="-mb-px flex space-x-2 xl:space-x-8">
            {!review &&
              choices.map((choice, index) => (
                <OptionItem
                  name={choice}
                  key={index}
                  active={option}
                  index={index}
                  action={setOption}
                />
              ))}
            {!isGranicus && (
              <button
                type="button"
                className="my-1.5 px-6 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-opacity-70 focus:outline-none transform duration-300"
                onClick={() => setShowNewTopic(!showNewTopic)}
              >
                New Topic
              </button>
            )}
          </nav>
        </div>
        <div className="max-w-7xl mx-auto px-2 mt-4">
          {/* Content */}
          {!review && option === 0 && (
            <ActiveTopicList
              topics={activeTopics}
              eventId={eventId}
              refresh={handleRefresh}
            />
          )}
          {!review && option === 1 && (
            <ConsentTopicList
              topics={consentTopics}
              eventId={eventId}
              activate={handleRemoveModal}
              refresh={handleRefresh}
            />
          )}
          {review && (
            <div>
              {reviewTopics?.length > 0 ? (
                <>
                  <div className="mb-6 flex items-center space-x-4 ml-4">
                    <input
                      name="import"
                      type="checkbox"
                      checked={importAll}
                      className="h-4 w-4 text-indigo-600 bg-gray-50 rounded focus:outline-none"
                      onChange={() => handleImportAll()}
                    />
                    <p>Check All</p>
                  </div>
                  {reviewTopics?.map((topic, index) => {
                    return (
                      <GranicusReviewCard
                        imported={topic}
                        key={index}
                        index={index}
                        add={handleTopicAdd}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="mb-6 flex items-center space-x-4 ml-4">
                    <p>
                      Returned no topics matching criteria. Please check how the
                      agenda was published.
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {modal && <ConsentModal topic={remove} action={handleRemoveConsent} />}
      {alertSuccess && (
        <Success heading="Info Updated" subheading="Changes have been saved" />
      )}
      {alertError && (
        <Error subheading="There was an issue updating the information." />
      )}
      {loading && <Loading />}
      {endNotesModal && (
        <EndingNotesModal meeting={meetingId} show={setEndNotesModal} />
      )}
      {showNewTopic && (
        <NewTopicSlideOut
          isVisible={setShowNewTopic}
          createTopic={handleCreateTopic}
        />
      )}
      {editSchedule && (
        <Transition.Root show={true} appear={true} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={true}
            onClose={() => setEditSchedule(false)}
          >
            <div className="items-end justify-center min-h-screen text-center block p-0 bg-gray-900 bg-opacity-50">
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block space-y-4 bg-gradient-to-b from-gray-200 to-gray-400 rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:max-w-xl sm:w-full sm:p-6">
                  <div>
                    <div className="text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Edit Meeting Schedule
                      </Dialog.Title>
                      <div className="mt-2">
                        <h1 className="italic">Scheduled Date:</h1>
                        <DatePicker
                          selected={scheduleDate}
                          onChange={(date) => setScheduleDate(date)}
                          inline
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mt-4 space-x-4">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2 border border-gray-900 text-gray-900 text-base font-medium hover:text-700 hover:border-indigo-700 focus:outline-none sm:text-sm"
                      onClick={() => {
                        setEditSchedule(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:text-sm"
                      onClick={() => {
                        handleDateChange();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </main>
  );
}

export default MeetingDetails;
