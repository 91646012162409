import React from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import clsx from "clsx";

import { countdownState, podiumMicState } from "../../../atoms/session.atom";
import { userState } from "../../../atoms/user.atom";
import { soloSettingsState } from "../../../atoms/settings.atom";

function SpeakerTimer({ sendEvent }) {
  const countdown = useRecoilValue(countdownState);
  const user = useRecoilValue(userState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const [podiumMic, setPodiumMic] = useRecoilState(podiumMicState);

  return (
    <div className="flex flex-col h-full w-full items-center justify-start mx-2 divide-y divide-gray-200 mt-4">
      <p className="px-2 text-lg xl:text-2xl text-gray-700 leading-relaxed tracking-wide">
        Speaker Timer
      </p>
      <h1 className="text-2xl 2xl:text-4xl font-bold">{countdown}</h1>
      {(user?.adminRole || user?.managerRole) && (
        <div>
          <div className="items-center justify-center flex flex-nowrap w-full pb-2 space-x-4">
            <button
              className="w-32 h-14 px-4 py-2 rounded-md border border-green-800 bg-green-600 text-base xl:text-lg font-medium text-white hover:bg-gray-50 hover:text-gray-700 focus:outline-none mt-4"
              onClick={() => {
                sendEvent("serverSpeaker", {
                  command: "start",
                });
              }}
            >
              Start
            </button>

            <button
              className="w-32 h-14 px-4 py-2 rounded-md border border-red-800 bg-red-500 text-base xl:text-lg font-medium text-white hover:text-gray-700 hover:bg-gray-50 focus:outline-none mt-4"
              onClick={() => {
                sendEvent("serverSpeaker", {
                  command: "stop",
                });
              }}
            >
              Stop
            </button>
            <button
              className="w-32 h-14 rounded-md border border-red-700 bg-red-700 text-base xl:text-lg font-medium text-white hover:bg-gray-50 hover:text-gray-700 focus:outline-none mt-4"
              onClick={() => {
                sendEvent("serverSpeaker", {
                  command: "pause",
                });
              }}
            >
              Pause
            </button>

            <button
              className="w-32 h-14 px-4 py-2 rounded-md border border-indigo-800 bg-indigo-500 text-base xl:text-lg font-medium text-white hover:text-gray-700 hover:bg-gray-50 focus:outline-none mt-4"
              onClick={() => {
                sendEvent("serverSpeaker", {
                  command: "reset",
                });
              }}
            >
              Reset
            </button>
          </div>
          <div className="items-center justify-center flex flex-nowrap w-full pb-2 shadow-sm rounded-md space-x-4">
            <button
              className="w-32 h-14 px-4 py-2 border rounded-md border-gray-500 bg-white text-base xl:text-lg font-medium text-gray-700 hover:bg-gray-200 focus:outline-none mt-4"
              onClick={() => {
                sendEvent("serverSpeaker", {
                  command: "add",
                });
              }}
            >
              +1 Minute
            </button>
            <button
              className="w-32 h-14 px-4 py-2 rounded-md border border-gray-500 bg-white text-base xl:text-lg font-medium text-gray-700 hover:bg-gray-200 focus:outline-none  mt-4"
              onClick={() => {
                sendEvent("serverSpeaker", {
                  command: "minus",
                });
              }}
            >
              -1 Minute
            </button>
          </div>
          {soloSettings?.podiumMuteButton && (
            <div className="flex items-center justify-center">
              <button
                className={clsx(
                  podiumMic
                    ? "bg-green-600 text-white hover:bg-green-400"
                    : "bg-gray-400 text-black hover:bg-green-400",
                  "p-4 rounded-md text-base xl:text-lg font-medium transform duration-200 focus:outline-none mt-4"
                )}
                onClick={() => {
                  let command = "podiumOn";
                  if (podiumMic) {
                    command = "podiumOff";
                  }
                  setPodiumMic(!podiumMic);
                  sendEvent("customButton", {
                    command,
                  });
                }}
              >
                Podium Mic
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SpeakerTimer;
