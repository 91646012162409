// Find array entry and return it
export const getArrayObject = (array, id) => {
  let index = array.findIndex((e) => e._id === id);
  let details = array[index];
  //   console.log(details);
  return details;
};

export const micListChange = (array, request) => {
  // console.log(array);
  // console.log(request);
  // find index of member
  let index = array.findIndex((obj) => obj.id === request.member || request.id);
  // console.log(index);
  let newArray = [...array];

  // pull the member out of the array
  let removedItem = newArray.splice(index, 1);

  // update the members status
  removedItem[0].status = request.action;

  // switch the status
  switch (request.action) {
    case "on":
      // place it at beginning of array
      newArray.unshift(removedItem[0]);
      break;
    case "off":
      // place it at end of the array
      newArray.push(removedItem[0]);
      break;
    case "queue":
      //place it after last mic is on index
      let lastOn;
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (element.status !== "on") {
          if (i > 0) {
            lastOn = i;
          } else {
            lastOn = 0;
          }
          break;
        }
      }
      // console.log(lastOn);
      // check if lastOn is the last in the array.
      if (lastOn === array.length - 1) {
        // since all array items are on then we will put queue at the end
        newArray.push(removedItem[0]);
      } else if (lastOn === 0) {
        // if there are no micIsOns then we will place queue at beginning
        newArray.unshift(removedItem[0]);
      } else {
        // here we find the position of the last on and insert the queue
        // right after last isOn
        newArray.splice(lastOn, 0, removedItem[0]);
      }

      break;

    default:
      console.error("error in switch case of micListChange");
      break;
  }
  return newArray;
};

export const findMemberMicStatus = (array, request) => {
  let status = false;
  try {
    let index = array.findIndex((obj) => obj.id === request.member);
    if (array[index].status !== request.action) {
      status = true;
    }
  } catch (error) {
    console.error(error);
  }

  return status;
};

export const updateMemberLoggedIn = (array, member) => {
  try {
    let index = array.findIndex((obj) => obj.id === member.id);
    let newArray = [...array];
    newArray[index].loggedIn = true;
    newArray[index].seat = member.seat;
    return newArray;
  } catch (error) {
    console.log(error);
  }
};

export const updateMemberVoted = (array, member, vote) => {
  try {
    if (member !== undefined) {
      let index = array.findIndex((obj) => obj.id === member._id);
      // console.log(index);
      if (index === -1) {
        return array;
      } else {
        let newArray = [...array];
        newArray[index].voted = vote;
        return newArray;
      }
    } else {
      return array;
    }
  } catch (error) {
    console.log("error array updateMemberVoted");
    console.log(error);
  }
};

export const stopVoteCheck = (array) => {
  try {
    let votedCount = 0;
    let loggedIn = 0;
    let votedSet;
    let loggedSet;
    for (let index = 0; index < array.length; index++) {
      if (array[index].loggedIn === true) {
        loggedIn++;
        loggedSet = loggedIn;
      }
      if (array[index].voted !== "none") {
        votedCount++;
        votedSet = votedCount;
      }
    }
    if (votedSet === loggedSet) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error stopVoteCheck helpers-arrays");
    console.log(error);
  }
};

export const setToNotVoted = (array) => {
  try {
    let newArray = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      let newItem = {};
      newItem.id = element.id;
      newItem._id = element.id;
      newItem.photoUrl = element.photoUrl;
      newItem.voted = "none";
      newItem.loggedIn = element.loggedIn;
      newItem.name = element.name;
      newArray.push(newItem);
    }
    return newArray;
  } catch (error) {}
};

export const documentArraySelection = (array, current) => {
  try {
    let returnUrl = "";
    let lastDoc = array.length - 1;
    let index = array.findIndex((e) => e.url === current);
    if (index === lastDoc) {
      returnUrl = array[0].url;
    } else {
      index = index + 1;
      returnUrl = array[index].url;
    }
    return returnUrl;
  } catch (error) {
    console.log("error in documentArraySelection");
  }
};

export const removeArrayEntry = (array, item) => {
  try {
    // search the array for the value
    let index = array.findIndex((e) => e._id === item);
    // remove the entry with that value
    array.splice(index, 1);
    // return the new array
    return array;
  } catch (error) {
    console.log("error in removeArrayEntry");
    console.log(error);
  }
};

export const setTopicCompleted = (array, item) => {
  try {
    // console.log(array);
    // console.log(item);
    let index = array.findIndex((e) => e._id === item);
    let newArray = [...array];
    console.log(newArray[index]);
    // newArray[index].complete = true;
    // return newArray;
  } catch (error) {
    console.log("error in setTopicCompleted");
    console.log(error);
  }
};

export const replaceItemAtIndex = (arr, index, newValue) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const removeItemAtIndex = (arr, index) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const updateObjectInArray = (array, newValue) => {
  const index = array.findIndex((e) => e.id === newValue.id);
  let clone = JSON.parse(JSON.stringify(array));
  clone[index] = newValue;
  return clone;
};

export const removeFromArray = (array, value) => {
  const index = array.findIndex((e) => e.id === value.id);
  let clone = JSON.parse(JSON.stringify(array));
  clone.splice(index, 1);
  return clone;
};
