import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { soloSettingsState } from "../atoms/settings.atom";

import FooterSmall from "../components/landing/FooterSmall";
import CompletedMeetings from "../components/Completed/CompletedMeetings";
import SeatLoginModal from "../components/Modals/SeatLoginModal";

export default function Landing() {
  const soloSettings = useRecoilValue(soloSettingsState);
  const [modal, setModal] = useState(false);

  return (
    <div className="h-screen bg-gradient-to-b from-main-bg to-blueGray-800 py-2 overflow-hidden">
      <div className="px-6 2xl:px-0 2xl:max-w-7xl 2xl:mx-auto flex flex-col h-full">
        <div className="flex w-full border-b border-gray-500 pb-2 justify-between items-center">
          <h1 className="text-2xl font-normal leading-normal text-gray-200 text-center ml-4">
            {soloSettings?.entityName}
          </h1>
          <div className="flex">
            <button
              className="text-xs 2xl:text-sm inline-flex items-center justify-center px-6 py-2 shadow-sm font-medium rounded-md text-gray-200 bg-indigo-600 hover:bg-opacity-70 focus:outline-none transform duration-300 mr-4 2xl:mr-8"
              onClick={() => setModal(true)}
            >
              Meeting Login
            </button>
            <Link
              className="text-xs 2xl:text-sm inline-flex items-center justify-center px-6 py-2 shadow-sm font-medium rounded-md text-gray-200 bg-indigo-600 hover:bg-opacity-70 focus:outline-none transform duration-300 mr-4 2xl:mr-8"
              to="/live"
            >
              Live Feed
            </Link>
            <Link
              className="text-xs 2xl:text-sm inline-flex items-center justify-center px-6 py-2 shadow-sm font-medium rounded-md text-gray-400 hover:text-gray-200 border border-gray-400 hover:border-gray-200 focus:outline-none transform duration-300"
              to="/setup"
            >
              Manage
            </Link>
          </div>
        </div>
        <div className="overflow-y-auto mb-24">
          <CompletedMeetings />
        </div>
      </div>
      <FooterSmall absolute />
      {modal && <SeatLoginModal isVisible={setModal} />}
    </div>
  );
}
