import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { pluginsState } from "../atoms/options";
import { soloSettingsState } from "../atoms/settings.atom";
import { updateObjectInArray, removeFromArray } from "../helpers/arrays";
import { apiUpdateIntegrations } from "../api/edge";
import { setSessionStorage } from "../helpers/storage";

import Error from "../components/Shared/Error";
import Success from "../components/Shared/Success";
import NewPluginSlideOut from "../components/Plugins/NewPluginSlideOut";
import DropDown from "../components/Plugins/InsertItem";

function Plugins({ sendEvent }) {
  const [plugins, setPlugins] = useRecoilState(pluginsState);
  const options = useRecoilValue(soloSettingsState);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [pluginType, setPluginType] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [pluginEdit, setPluginEdit] = useState();

  function handleNewPlugin(value) {
    setPluginEdit();
    setPluginType(value);
    setShowNew(true);
  }

  async function handlePluginAdd(data) {
    if (plugins.length > 0) {
      let newArray = Array.from(plugins);
      newArray.push({ ...data });
      setPlugins(newArray);
      const firstResult = await apiUpdateIntegrations({
        plugins: JSON.stringify(newArray),
      });
      if (firstResult) {
        handleSuccess();
        sendEvent("refreshAppliance");
        setSessionStorage("config", {
          ...options,
          plugins: JSON.stringify(newArray),
        });
      } else {
        handleError();
      }
    } else {
      setPlugins([{ ...data }]);
      const secondResult = await apiUpdateIntegrations({
        plugins: JSON.stringify([{ ...data }]),
      });
      if (secondResult) {
        handleSuccess();
        sendEvent("refreshAppliance");
        setSessionStorage("config", {
          ...options,
          plugins: JSON.stringify([{ ...data }]),
        });
      } else {
        handleError();
      }
    }
    setShowNew(false);
  }

  async function handlePluginEdit(data) {
    const result = updateObjectInArray(plugins, data);
    setPlugins(result);
    setShowEdit(false);
    setPluginEdit();
    const firstResult = await apiUpdateIntegrations({
      plugins: JSON.stringify(result),
    });
    if (firstResult) {
      handleSuccess();
      sendEvent("refreshAppliance");
      setSessionStorage("config", {
        ...options,
        plugins: JSON.stringify(result),
      });
    } else {
      handleError();
    }
  }

  async function handleRemove(value) {
    const result = removeFromArray(plugins, value);
    setPlugins(result);
    setShowEdit(false);
    setPluginEdit();
    if (result.length === 0) {
      const firstResult = await apiUpdateIntegrations({
        plugins: "",
      });
      if (firstResult) {
        handleSuccess();
        sendEvent("refreshAppliance");
        setSessionStorage("config", {
          ...options,
          plugins: "",
        });
      } else {
        handleError();
      }
    } else {
      const secondResult = await apiUpdateIntegrations({
        plugins: JSON.stringify(result),
      });
      if (secondResult) {
        handleSuccess();
        sendEvent("refreshAppliance");
        setSessionStorage("config", {
          ...options,
          plugins: JSON.stringify(result),
        });
      } else {
        handleError();
      }
    }
  }

  function handleError() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }
  function handleSuccess() {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      {plugins && (
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-2 2xl:gap-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="xl:col-span-3">
            <p className="text-slate-900 font-semibold text-xl 2xl:text-2xl">
              Plugins
            </p>
          </div>
          <div className="xl:col-span-3">
            <p className="text-slate-900 font-light 2xl:text-lg">
              Plugins allow for direct control of manufacturer products. Press
              the button to add and set up a supported plugin.
            </p>
          </div>
          <div className="xl:col-span-3">
            <DropDown insert={handleNewPlugin} currentPlugins={plugins} />
          </div>
          <div>
            <p className="text-black underline font-medium text-xl mb-2">
              Active Plugins
            </p>
            {plugins?.map((plugin, index) => {
              return (
                <div
                  key={index}
                  className="bg-white p-6 shadow-md border border-zinc-400 rounded-lg mb-4 cursor-pointer hover:border-zinc-700 hover:scale-95 transform duration-300"
                  onClick={() => {
                    setPluginEdit(plugin);
                    setShowEdit(true);
                  }}
                >
                  <p className="text-black font-semibold text-lg">
                    {plugin.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {success && <Success heading="Plugins Updated" />}
      {error && (
        <Error subheading="There was an issue updating the plugins." />
      )}
      {showNew && (
        <NewPluginSlideOut
          isVisible={setShowNew}
          createPlugin={handlePluginAdd}
          pluginType={pluginType}
          editPlugin={pluginEdit}
          removePlugin={handleRemove}
        />
      )}
      {showEdit && pluginEdit && (
        <NewPluginSlideOut
          isVisible={setShowEdit}
          createPlugin={handlePluginEdit}
          pluginType={{ value: pluginEdit?.id }}
          editPlugin={pluginEdit}
          removePlugin={handleRemove}
        />
      )}
    </main>
  );
}

export default Plugins;
