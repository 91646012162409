import React, { useState } from "react";

import ScrapeReviewCard from "./ScrapeReviewCard";

import "react-datepicker/dist/react-datepicker.css";

function TopicsImport({ topics, exit, save }) {
  const [importAll, setImportAll] = useState(false);
  const [reviewTopics, setReviewTopics] = useState(topics);

  function handleTopicAdd(value, index) {
    let newArray = JSON.parse(JSON.stringify(reviewTopics));
    newArray[index].approved = value;
    setReviewTopics(newArray);
    if (!value && importAll) {
      setImportAll(false);
    }
  }

  async function handleImportAll() {
    setImportAll(!importAll);
    const newArray = reviewTopics.map((e) => {
      return { ...e, approved: !importAll };
    });
    setReviewTopics(newArray);
  }

  function handleEnable(value, index) {
    let newArray = JSON.parse(JSON.stringify(reviewTopics));
    newArray[index].actionEnabled = value;
    setReviewTopics(newArray);
  }

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      <div className="py-2 2xl:py-4">
        <div className="flex justify-between items-center border-b-2 max-w-7xl mx-auto px-2 sm:px-6 md:px-4 lg:px-4">
          <div className="flex items-center space-x-4">
            <h1 className="text-base xl:text-xl 2xl:text-2xl font-semibold text-gray-900">
              Review Found Topics
            </h1>
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 xl:py-3 mb-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none mr-4 transform duration-300"
              onClick={() => save(reviewTopics)}
            >
              Approve Import
            </button>

            <button
              type="button"
              className="inline-flex items-center px-4 py-2 xl:py-3 mb-2 rounded-md shadow-sm text-sm font-medium text-black border border-black hover:bg-coolGray-400 focus:outline-none transform duration-300"
              onClick={() => exit()}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-2 mt-4">
          {/* Content */}
          <div>
            <div className="mb-6 flex items-center space-x-4 ml-4">
              <input
                name="import"
                type="checkbox"
                checked={importAll}
                className="h-4 w-4 text-indigo-600 bg-gray-50 rounded focus:outline-none"
                onChange={() => handleImportAll()}
              />
              <p>Check All</p>
            </div>
            {reviewTopics?.map((topic, index) => {
              return (
                <ScrapeReviewCard
                  imported={topic}
                  key={index}
                  index={index}
                  add={handleTopicAdd}
                  enabled={handleEnable}
                />
              );
            })}
          </div>
        </div>
      </div>
    </main>
  );
}

export default TopicsImport;
