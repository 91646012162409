import { memo } from "react";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

function PdfViewer({ url, width }) {
  return (
    <DocViewer
      prefetchMethod="GET"
      documents={[{ uri: url }]}
      pluginRenderers={DocViewerRenderers}
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
        },
        pdfZoom: {
          defaultZoom: 1.1,
        },
        pdfVerticalScrollByDefault: true,
      }}
    />
  );
}

export default memo(PdfViewer);
