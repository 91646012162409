import React from "react";
import { useRecoilValue } from "recoil";
import { motion } from "framer-motion";
import { soloSettingsState } from "../../atoms/settings.atom";
import packageJson from "../../../package.json";

import Loading from "./Loading";

function NoActiveMeeting({ seat }) {
  const soloSettings = useRecoilValue(soloSettingsState);

  if (Object.entries(soloSettings).length === 0) {
    return <Loading />;
  }

  return (
    <div className="h-screen xl:flex items-start justify-center tablet:py-6 2xl:py-8 px-4 sm:px-6 lg:px-2 bg-gradient-to-b from-main-bg to-blueGray-800">
      <img
        src="/images/LogoBlue.png"
        className="h-12 tablet:h-16 2xl:h-20 w-auto absolute top-4 left-4"
        alt="agendalink"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="w-full space-y-2 2xl:mt-8"
      >
        <div>
          <h2 className="block mb-6 text-center text-4xl font-bold text-gray-50 italic">
            AgendaLink Solo
          </h2>

          <img
            className="mx-auto h-28 tablet:h-56 2xl:h-72 w-auto sm:mt-4 2xl:mt-0 bg-gray-50 rounded-md py-6 px-12"
            src={soloSettings.entityLogo}
            alt="logo"
          />

          <h2 className="mt-8 text-3xl text-center font-bold text-gray-50">
            No Active Meeting
          </h2>
          <h2 className="mt-8 text-2xl text-center font-bold text-gray-50">
            {seat ? `Seat ${seat}` : "Remote Member"}
          </h2>
        </div>

        <div className="items-center justify-evenly flex-col flex"></div>
      </motion.div>
      <footer className="absolute bottom-0 left-0 w-full pb-6">
        <div className="container max-w-full 2xl:mx-auto 2xl:max-w-7xl">
          <hr className="mb-6 border-b-1 border-gray-500" />
          <div className="flex flex-nowrap items-center justify-between">
            <div className="px-4">
              <div className="text-sm text-gray-400 font-semibold py-1">
                Powered By:
                <a
                  href="https://www.agendalink.app"
                  className="text-white hover:text-indigo-400 text-sm font-semibold py-1 ml-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AgendaLink
                </a>
              </div>
            </div>
            <div className="px-4">
              <div className="text-sm text-white font-semibold py-1 flex flex-wrap list-none md:justify-end  justify-center">
                {packageJson.version}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default NoActiveMeeting;
