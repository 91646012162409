import { useState } from "react";
import { useRecoilState } from "recoil";

import { apiUpdateIntegrations } from "../api/edge";
import { soloSettingsState } from "../atoms/settings.atom";
import { setSessionStorage } from "../helpers/storage";

import Error from "../components/Shared/Error";
import Success from "../components/Shared/Success";
import Toggle from "../components/Shared/Toggle";
import ControlRow from "../components/InMeeting/CustomControls/ControlRow";

function AudioVideo({ sendEvent }) {
  const [options, setOptions] = useRecoilState(soloSettingsState);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [customTitle, setCustomTitle] = useState("");
  const [customCommand, setCustomCommand] = useState("");
  const [changes, setChanges] = useState(false);

  async function handleUpdate() {
    if (changes) {
      const result = await apiUpdateIntegrations({
        controlIp: options.controlIp,
        micControlPort: options.micControlPort,
        miscControlPort: options.miscControlPort,
      });
      if (result) {
        handleSuccess();
        setChanges(false);
        sendEvent("refreshAppliance");
        setSessionStorage("config", {
          ...options,
          controlIp: options.controlIp,
          micControlPort: options.micControlPort,
          miscControlPort: options.miscControlPort,
        });
      } else {
        handleError();
      }
    }
  }

  async function handleRequestToggle(value) {
    setOptions({
      ...options,
      customAvControl: value,
    });
    const result = await apiUpdateIntegrations({
      customAvControl: value,
    });
    if (result) {
      handleSuccess();
      sendEvent("refreshAppliance");
      setSessionStorage("config", { ...options, customAvControl: value });
    } else {
      handleError();
    }
  }

  async function handleConnectToggle(value) {
    setOptions({
      ...options,
      avConnect: value,
    });
    const result = await apiUpdateIntegrations({
      avConnect: value,
    });
    if (result) {
      handleSuccess();
      sendEvent("refreshAppliance");
      setSessionStorage("config", { ...options, avConnect: value });
    } else {
      handleError();
    }
  }

  async function handleButtonRemove(value) {
    let buttons = [...options.customAvButtons];
    buttons.splice(value, 1);
    setOptions({
      ...options,
      customAvButtons: buttons,
    });
    const result = await apiUpdateIntegrations({
      customAvButtons: buttons,
    });
    if (result) {
      handleSuccess();
      sendEvent("refreshAppliance");
      setSessionStorage("config", { ...options, customAvButtons: buttons });
    } else {
      handleError();
    }
  }

  async function handleButtonAdd() {
    if (options.customAvButtons) {
      let buttons = [...options.customAvButtons];
      buttons.push({ title: customTitle, command: customCommand });
      setOptions({
        ...options,
        customAvButtons: buttons,
      });
      const firstResult = await apiUpdateIntegrations({
        customAvButtons: buttons,
      });
      if (firstResult) {
        handleSuccess();
        sendEvent("refreshAppliance");
        setSessionStorage("config", { ...options, customAvButtons: buttons });
      } else {
        handleError();
      }
    } else {
      setOptions({
        ...options,
        customAvButtons: [{ title: customTitle, command: customCommand }],
      });
      const secondResult = await apiUpdateIntegrations({
        customAvButtons: [{ title: customTitle, command: customCommand }],
      });
      if (secondResult) {
        handleSuccess();
        sendEvent("refreshAppliance");
        setSessionStorage("config", {
          ...options,
          customAvButtons: [{ title: customTitle, command: customCommand }],
        });
      } else {
        handleError();
      }
    }
    setCustomCommand("");
    setCustomTitle("");
  }

  function handleError() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }
  function handleSuccess() {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  }

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      {options && (
        <div className="py-4">
          <div className="grid grid-cols-9 gap-2 2xl:gap-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="col-span-9">
              <label
                htmlFor="pincode"
                className="block text-base xl:text-lg font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Audio Video System Link
              </label>

              <div className="mt-1">
                <div className="max-w-lg flex flex-col space-y-1">
                  <Toggle
                    value={options.avConnect}
                    action={handleConnectToggle}
                  />

                  <span className="text-xs xl:text-sm leading-normal text-slate-700 pb-4">
                    By turning this on you will enable ascii string outputs from
                    AgendaLink Solo. Once enabled you will be provided further
                    setup options.
                  </span>
                </div>
              </div>
            </div>
            {options.avConnect && (
              <>
                <div className="col-span-9 md:col-span-3">
                  <label className="block text-sm xl:text-lg font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Control Processor IP Address
                  </label>

                  <div className="mt-1">
                    <input
                      type="text"
                      id="controlIp"
                      value={options.controlIp}
                      className="flex-1 block w-full rounded-md shadow-sm py-2 px-3 sm:text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) => {
                        setOptions({
                          ...options,
                          controlIp: e.target.value,
                        });
                        setChanges(true);
                      }}
                      onBlur={() => handleUpdate()}
                    />
                  </div>
                </div>
                <div className="col-span-9 md:col-span-3">
                  <label className="block text-sm xl:text-lg font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Mic Commands TCP Port
                  </label>

                  <div className="mt-1">
                    <input
                      type="text"
                      id="micControlPort"
                      value={options.micControlPort}
                      className="flex-1 block w-full rounded-md shadow-sm py-2 px-3 sm:text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) => {
                        setOptions({
                          ...options,
                          micControlPort: e.target.value,
                        });
                        setChanges(true);
                      }}
                      onBlur={() => handleUpdate()}
                    />
                  </div>
                </div>
                <div className="col-span-9 md:col-span-3">
                  <label className="block text-sm xl:text-lg font-medium text-gray-700 sm:mt-px sm:pt-2">
                    General Commands TCP Port
                  </label>

                  <div className="mt-1">
                    <input
                      type="text"
                      id="miscProtocolPort"
                      value={options.miscProtocolPort}
                      className="flex-1 block w-full rounded-md shadow-sm py-2 px-3 sm:text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) => {
                        setOptions({
                          ...options,
                          miscProtocolPort: e.target.value,
                        });
                        setChanges(true);
                      }}
                      onBlur={() => handleUpdate()}
                    />
                  </div>
                </div>
              </>
            )}
            {options.avConnect && (
              <>
                <div className="col-span-9 mt-2">
                  <label
                    htmlFor="pincode"
                    className="block text-base xl:text-lg font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Custom Buttons
                  </label>

                  <div className="mt-1">
                    <div className="flex flex-col space-y-1">
                      <Toggle
                        value={options.customAvControl}
                        action={handleRequestToggle}
                      />
                      <span
                        className="flex-grow flex flex-col"
                        id="toggleLabel"
                      >
                        <span className="text-xs xl:text-sm text-justify text-slate-700 pb-4">
                          Enabling this features allows you to create custom
                          buttons and commands for interfacing with an existing
                          control system. This communication is one-way only and
                          can&apos;t be used to show feedback from the av
                          system. These commands will use the General Commands
                          port.
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}

            {options.avConnect && options.customAvControl && (
              <>
                <div className="col-span-9 md:col-span-3 col-start-1 flex flex-col items-center space-x-1 2xl:space-x-4">
                  <input
                    type="text"
                    id="customTitle"
                    value={customTitle}
                    placeholder="Title"
                    className="flex-1 block w-full rounded-md shadow-sm py-2 px-3 sm:text-base border border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={(e) => {
                      setCustomTitle(e.target.value);
                    }}
                  />
                  <p className="text-sm xl:text-lg font-semibold text-gray-900">
                    New Button Title
                  </p>
                </div>
                <div className="col-span-9 md:col-span-3 flex flex-col items-center space-x-1 2xl:space-x-4">
                  <input
                    type="text"
                    id="customCommand"
                    value={customCommand}
                    placeholder="Command"
                    className="flex-1 block w-full rounded-md shadow-sm py-2 px-3 sm:text-base border border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={(e) => {
                      setCustomCommand(e.target.value);
                    }}
                  />
                  <p className="text-sm xl:text-lg font-semibold text-gray-900">
                    New Button Command
                  </p>
                </div>
                {customTitle.length > 0 && customCommand.length > 0 && (
                  <div className="col-span-9 md:col-span-3">
                    <button
                      className="py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => handleButtonAdd()}
                    >
                      Add Button
                    </button>
                  </div>
                )}

                <div className="col-span-9 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-1 2xl:px-6 2xl:py-3 bg-gray-50 text-left text-sm 2xl:text-lg font-medium text-gray-500"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-1 2xl:px-6 2xl:py-3 bg-gray-50 text-left text-sm 2xl:text-lg font-medium text-gray-500"
                        >
                          Command
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50"
                        >
                          <span className="sr-only">Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {options.customAvButtons?.map((button, index) => (
                        <ControlRow
                          button={button}
                          remove={handleButtonRemove}
                          key={index}
                          index={index}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {success && <Success heading="Settings Updated" />}
      {error && (
        <Error subheading="There was an issue updating the settings." />
      )}
    </main>
  );
}

export default AudioVideo;
