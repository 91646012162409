import React, { useState, useEffect } from "react";

import Toggle from "../Shared/Toggle";

function ReviewItemCard({ imported, add, index }) {
  const [topic, setTopic] = useState({
    itemNumber: "",
    title: "",
    more: "",
    actionEnabled: true,
    approved: false,
  });

  useEffect(() => {
    if (imported) {
      setTopic(imported);
    }
  }, [imported]);

  function handleEnable(value) {
    setTopic({
      ...topic,
      actionEnabled: value,
    });
  }

  return (
    <div className="bg-white rounded-lg shadow-md px-4 py-1.5 grid grid-cols-10 mb-4 border border-asana-bg">
      <div className="flex items-center justify-start">
        <input
          name="import"
          type="checkbox"
          checked={topic.approved}
          className="h-4 w-4 text-indigo-600 bg-gray-50 rounded focus:outline-none"
          onChange={() => add(!topic.approved, index)}
        />
      </div>
      <div className="col-span-2">
        <p className="text-xs xl:text-sm font-medium text-gray-600 underline">
          Action Number
        </p>
        <p className="text-xs xl:text-sm font-medium text-gray-900">
          {topic.itemNumber}
        </p>
      </div>
      <div className="col-span-2">
        <p className="text-xs xl:text-sm font-medium text-gray-700">Title</p>
        <p className="text-xs xl:text-sm font-medium text-gray-900">
          {topic.title}
        </p>
      </div>
      <div className="col-span-4">
        <p className="text-xs xl:text-sm font-medium text-gray-700">Details</p>
        <p className="text-xs xl:text-sm font-medium text-gray-900 truncate">
          {topic.more}
        </p>
      </div>
      <div className="flex items-center justify-end">
        <div className="flex flex-col items-center justify-center">
          <Toggle value={topic.actionEnabled} action={handleEnable} />
          <span className="text-tiny xl:text-xs font-medium text-gray-900">
            Regular Item
          </span>
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className="bg-white rounded-lg shadow-md p-4 flex flex-col space-y-3 mb-16 border border-asana-bg">
  //     <div>
  //       <label className="block text-sm xl:text-lg font-medium text-gray-900 px-2">
  //         Action Number
  //         <span className="pl-1 text-xs text-gray-700">
  //           (If left blank a number will be autogenerated)
  //         </span>
  //       </label>
  //       <input
  //         type="itemNumber"
  //         name="itemNumber"
  //         value={topic.itemNumber}
  //         className="text-sm xl:text-base 2xl:text-lg mt-1 block w-full border border-gray-300 bg-gray-100 rounded-md shadow-sm py-2 px-3 focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
  //         onChange={(e) => {
  //           handleChange(e);
  //         }}
  //       />
  //     </div>
  //     <div>
  //       <label className="block text-sm xl:text-lg font-medium text-gray-900 px-2">
  //         Title
  //       </label>
  //       <input
  //         type="title"
  //         name="title"
  //         value={topic.title}
  //         className="text-sm xl:text-base 2xl:text-lg mt-1 block w-full border border-gray-300 bg-gray-100 rounded-md shadow-sm py-2 px-3 focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
  //         onChange={(e) => {
  //           handleChange(e);
  //         }}
  //       />
  //     </div>
  //     <div>
  //       <label className="block text-sm xl:text-lg font-medium text-gray-900">
  //         Details
  //       </label>
  //       <div className="mt-1">
  //         <textarea
  //           name="details"
  //           rows="6"
  //           value={topic.more}
  //           className="text-sm xl:text-base 2xl:text-lg shadow-sm focus:ring-light-blue-500 focus:border-gray-900 mt-1 block w-full sm:text-sm border border-gray-300 bg-gray-100 rounded-md px-2"
  //           onChange={(e) => {
  //             handleChange(e);
  //           }}
  //         ></textarea>
  //       </div>
  //     </div>
  //     <div className="flex items-center justify-between">
  //       <div className="flex flex-col items-center justify-center">
  //         <Toggle value={topic.actionEnabled} action={handleEnable} />
  //         <span className="text-sm font-medium text-gray-900">
  //           Active Topic
  //         </span>
  //       </div>
  //     </div>
  //     <div className="flex justify-end">
  //       <div
  //         className="bg-blue-500 px-4 py-2 rounded-md flex items-center justify-center cursor-pointer hover:bg-opacity-70 transform duration-300"
  //         onClick={() => add(topic, index)}
  //       >
  //         <p className="text-white text-base lg:text-lg">Approve</p>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default ReviewItemCard;
