import React from "react";
import packageJson from "../../../package.json";

export default function FooterSmall(props) {
  return (
    <>
      <footer
        className={
          (props.absolute
            ? "sticky w-full bottom-0"
            : "relative") + " pb-2 hd:pb-6"
        }
      >
        <div className="container max-w-full 2xl:mx-auto 2xl:max-w-7xl">
          <hr className="mb-4 hd:mb-6 border-b-1 border-gray-500" />
          <div className="flex flex-nowrap items-center justify-between">
            <div className="px-4">
              <div className="text-sm text-gray-400 font-semibold py-1">
                Powered By:
                <a
                  href="https://www.agendalink.app"
                  className="text-white hover:text-indigo-400 text-sm font-semibold py-1 ml-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AgendaLink
                </a>
              </div>
            </div>
            <div className="px-4">
              <div className="text-sm text-white font-semibold py-1 flex flex-wrap list-none md:justify-end  justify-center">
                {packageJson.version}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
