import { useSetRecoilState } from "recoil";
import { agendaTimeState } from "../atoms/globals.atom";
var interval;
var startTime;
var endTime;

export const useAgendaTimer = (event) => {
  const setTimer = useSetRecoilState(agendaTimeState);

  function update() {
    // console.log("useAgendaTimer update");
    endTime = new Date();
    var timeDiff = endTime - startTime;
    timeDiff /= 1000;

    timeDiff = Math.floor(timeDiff / 60);

    // get minutes
    var minutes = Math.round(timeDiff % 60);
    // console.log(minutes);

    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);

    // get hours
    var hours = Math.round(timeDiff % 24);
    // console.log(hours);
    if (hours > 0) {
      setTimer(hours + " hr " + minutes + " min");
      event("topicLength", {
        time: hours + " hr " + minutes + " min",
      });
    } else {
      setTimer(minutes + " min");
      event("topicLength", {
        time: minutes + " min",
      });
    }
  }

  function start() {
    // console.log("useAgendaTimer start");
    startTime = new Date();
    // isOn = true;
    interval = setInterval(update.bind(this), 60000);
  }

  function stop() {
    // console.log("useAgendaTimer stop");
    clearInterval(interval);
    setTimer("0 min");
    event("topicLength", {
      time: "0 min",
    });
  }

  return { start, stop };
};
