import React from "react";

function VotingResultCard({ voter, hideImages }) {
  if (voter.vote !== "recuse") {
    return (
      <div
        className={`relative rounded-lg px-4 py-2 shadow-lg flex space-x-2 items-center
      ${voter.vote === "yea" && "bg-green-700"}
      ${voter.vote === "nay" && "bg-red-700"}
      ${voter.vote === "abstain" && "bg-yellow-300"}
      ${voter.vote === "absent" && "bg-gray-600"}
      `}
      >
        {!hideImages && (
          <div className="hidden 2xl:block flex-shrink-0">
            <img
              className="h-10 w-10 sm:h-16 sm:w-16 2xl:h-20 2xl:w-20 rounded-xl"
              src={voter.photoUrl}
              alt=""
            />
          </div>
        )}

        <div className="flex-1 min-w-0">
          <span className="absolute inset-0" aria-hidden="true"></span>
          <p
            className={`text-sm xl:text-base 2xl:text-2xl font-medium
            ${voter.vote === "yea" && "text-white"}
            ${voter.vote === "nay" && "text-white"}
            ${voter.vote === "abstain" && "text-gray-800"}
            ${voter.vote === "absent" && "text-gray-200"}
          `}
          >
            {voter.firstName} {voter.lastName}
          </p>
          <p
            className={`hidden 2xl:block 2xl:text-lg
           ${voter.vote === "yea" && "text-white"}
           ${voter.vote === "nay" && "text-white"}
           ${voter.vote === "abstain" && "text-gray-800"}
           ${voter.vote === "absent" && "text-gray-200"}
          `}
          >
            {voter.title}
          </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default VotingResultCard;
