import cx from "classnames";

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { MdEdit } from "react-icons/md";

function RoundTimer({ setRound, index }) {
  return (
    <div className="relative inline-block text-left">
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          <button
            className={cx(
              "text-gray-900 ml-2 rounded-full",
              "transform duration-300",
              "hover:text-blue-400",
              "focus:outline-none",
              // Register all radix states
              "group",
              "radix-state-open:bg-gray-900",
              "radix-state-on:bg-gray-900",
              "radix-state-instant-open:bg-gray-50 radix-state-delayed-open:bg-gray-50"
            )}
          >
            <MdEdit className="w-5 h-5" />
          </button>
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          align="end"
          sideOffset={5}
          className={cx(
            "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down",
            "w-48 max-h-64 overflow-y-auto rounded-lg px-1.5 py-1 shadow-md md:w-56",
            "bg-gray-800 border border-gray-400"
          )}
        >
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(1, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              1 Minute
            </span>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(2, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              2 Minutes
            </span>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(3, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              3 Minutes
            </span>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(4, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              4 Minutes
            </span>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(5, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              5 Minutes
            </span>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(6, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              6 Minutes
            </span>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(7, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              7 Minutes
            </span>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(8, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              8 Minutes
            </span>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(9, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              9 Minutes
            </span>
          </DropdownMenuPrimitive.Item>
          <DropdownMenuPrimitive.Item
            className={cx(
              "flex cursor-default select-none items-center rounded-md px-2 py-2 text-sm outline-none",
              "text-gray-300 focus:bg-gray-200 focus:text-gray-800 group transform duration-300"
            )}
            onClick={() => setRound(10, index)}
          >
            <span className="flex-grow text-gray-300 group-hover:text-gray-800">
              10 Minutes
            </span>
          </DropdownMenuPrimitive.Item>
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </div>
  );
}

export default RoundTimer;
