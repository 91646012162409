import React, { useState } from "react";

import Toggle from "./DarkToggle";

function TypeConfero({ create, editPlugin, removePlugin }) {
  const [value, setValue] = useState({
    ip: "",
    autoOff: true,
    id: "confero",
    title: "Televic Confero 360",
  });

  React.useEffect(() => {
    if (editPlugin) {
      setValue(editPlugin);
    }
  }, [editPlugin]);

  const handleChange = (type, newValue) => {
    setValue({
      ...value,
      [type]: newValue,
    });
  };

  return (
    <div className="mt-2 flex flex-col px-1 2xl:px-4 pt-1 2xl:pt-4 pb-2">
      <div className="mt-4 grow min-h-[20rem]">
        <div className="mt-6">
          <p className="block text-sm font-light text-zinc-300">IP Addresss</p>
          <p className="block text-xs font-light text-zinc-400 mt-1">
            Please enter the ip address of the Confero 360 WAP
          </p>
          <input
            type="text"
            name="itemNumber"
            value={value.ip}
            className="mt-1 block w-full border-2 hover:border-zinc-400 border-zinc-600 bg-asana-bg text-zinc-300 rounded-md py-2 px-1 transform duration-200 text-lg focus:outline-none"
            onChange={(e) => {
              setValue({ ...value, ip: e.target.value });
            }}
          />
        </div>
        <div className="mt-6">
          <p className="block text-sm font-light text-zinc-300">
            Auto Shut Off
          </p>
          <p className="block text-xs font-light text-zinc-400 mt-1 mb-2">
            Enable this if you would like the wireless microphones to
            automatically shut off after the meeting.
          </p>
          <Toggle
            value={value?.autoOff}
            setValue={handleChange}
            type="autoOff"
          />
        </div>
      </div>
      <div className="flex justify-end">
        {editPlugin && (
          <button
            className="bg-delete-btn px-4 py-2 rounded-md hover:bg-opacity-70 transform duration-300 mr-4"
            onClick={() => removePlugin(value)}
          >
            Remove Plugin
          </button>
        )}
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-opacity-70 transform duration-300"
          onClick={() => create(value)}
        >
          {editPlugin ? "Update " : "Add "} Plugin
        </button>
      </div>
    </div>
  );
}

export default TypeConfero;
