import { atom } from "recoil";

export const userState = atom({
  key: "userState",
  default: null,
});

export const userMicState = atom({
  key: "userMicState",
  default: "off",
});

export const importUserState = atom({
  key: "importUser",
  default: [],
});

export const managerUserState = atom({
  key: "managerUser",
  default: null,
});

export const loggedOutState = atom({
  key: "loggedOut",
  default: null,
});
