import { atom } from "recoil";

export const pluginsState = atom({
  key: "plugins",
  default: [],
});

export const logoutUserState = atom({
  key: "logoutUser",
  default: null,
});
