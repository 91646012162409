import React, { useState } from "react";

import DropDownItem from "./DropDownItem";
import NonDistrictItem from "./NonDistrictItem";

const DropDown = ({ value, action, heading }) => {
  const [popup, setPopup] = useState(false);

  function handleOption(option) {
    setPopup(false);
    action(option);
  }

  function dropDownValues() {
    let content = [];
    for (let index = 1; index < 31; index++) {
      content.push(
        <DropDownItem
          choose={handleOption}
          item={index}
          active={value}
          key={index}
        />
      );
    }
    return content;
  }

  function selectedTitle(value) {
    if (value !== undefined) {
      if (value > 0) {
        return <span className="block truncate">District {value}</span>;
      } else {
        return <span className="block truncate">No District</span>;
      }
    } else {
      return <span className="block truncate">Please choose</span>;
    }
  }

  return (
    <div>
      <label
        id="listbox-label"
        className="block text-sm font-medium text-gray-700"
      >
        {heading}
      </label>
      <div className="mt-1 relative z-10">
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          className="bg-white relative w-full border border-gray-400 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          onClick={() => setPopup(!popup)}
        >
          {selectedTitle(value)}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        {popup && (
          <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
            <ul
              tabIndex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            >
              <NonDistrictItem choose={handleOption} item={0} active={value} />
              {dropDownValues()}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;
