import React, { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import { Listbox, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";

function generateArrayOfSeats(numbers) {
  let returnArray = [];
  for (let index = 0; index < numbers; index++) {
    const temp = index + 1;
    returnArray.push(temp.toString());
  }
  return returnArray;
}

const SeatLoginModal = ({ isVisible }) => {
  let history = useHistory();
  const seats = generateArrayOfSeats(30);
  const [chosen, setChosen] = useState(seats[0]);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <motion.div
            className="absolute inset-0 bg-blueGray-800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            transition={{ duration: 0.4 }}
            onClick={() => isVisible(false)}
          ></motion.div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          style={{ minHeight: "30rem" }}
        >
          <div className="flex flex-col">
            <div className="flex justify-between items-center">
              <p className="font-semibold text-gray-800 text-xl">
                Meeting Login Options
              </p>
              <AiOutlineClose
                className="h-6 w-6 text-gray-800 cursor-pointer hover:text-gray-600"
                onClick={() => isVisible(false)}
              />
            </div>
            <div className="flex flex-col px-6 mt-12">
              <div className="flex flex-col px-6 mb-20">
                <p className="mb-1 text-center text-base xl:text-xl font-medium rounded-md text-zinc-800 focus:outline-none">
                  Join from Room
                </p>
                <p className="text-center text-gray-800 italic font-light">
                  Please choose the microphone you are sitting at. The
                  microphone will be labeled.
                </p>
                <Listbox value={chosen} onChange={setChosen}>
                  {({ open }) => (
                    <>
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-gray-700 shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-100 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm 2xl:text-base">
                          <span className="block truncate">{chosen}</span>
                          {/* <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <FilterIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span> */}
                        </Listbox.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            static
                            className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-2xl max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm 2xl:text-base z-40 border-b border-r border-l border-gray-800"
                          >
                            {seats.map((item, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  `${
                                    active
                                      ? "text-blue-900 bg-blue-100"
                                      : "text-gray-900"
                                  }
      cursor-default select-none relative py-2 pl-10 pr-4`
                                }
                                value={item}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={`${
                                        selected ? "font-medium" : "font-normal"
                                      } block truncate`}
                                    >
                                      {item}
                                    </span>
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                <button
                  className="mt-4 inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base xl:text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    history.push(`/manager/${chosen}`);
                    isVisible(false);
                  }}
                >
                  Join at this Mic
                </button>
              </div>
              <button
                className="mb-1 inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base xl:text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  history.push("/remote");
                  isVisible(false);
                }}
              >
                Join Remotely
              </button>
              <p className="text-center text-gray-800 italic font-light">
                Use this option if you are joining outside of the room.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SeatLoginModal;
