import React from "react";
import { Switch } from "@headlessui/react";

function DarkToggle({ value, setValue, type }) {
  return (
    <Switch.Group as="div" className="flex items-center space-x-4">
      <Switch
        as="button"
        checked={value}
        onChange={(e) => setValue(type, e)}
        className={`${
          value ? "bg-green-600" : "bg-coolGray-500"
        } relative inline-flex flex-shrink-0 h-7 transition-colors duration-200 ease-in-out border-2 border-coolGray-600 rounded-full cursor-pointer w-14 focus:outline-none focus:shadow-outline`}
      >
        {({ checked }) => (
          <span
            className={`${
              checked ? "translate-x-7" : "translate-x-0"
            } inline-block w-6 h-6 transition duration-200 ease-in-out transform bg-coolGray-800 rounded-full`}
          />
        )}
      </Switch>
    </Switch.Group>
  );
}

export default DarkToggle;
