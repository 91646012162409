import axios from "axios";

export const apiGetAllMotions = async (server) => {
  try {
    const result = await axios
      .get(`${server}/api/motions`)
      .then(function (response) {
        return response.data;
      });
    return result;
  } catch (error) {
    console.log("error apiGetAllMotions");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiGetBoards = async (server) => {
  try {
    const result = await axios
      .get(`${server}/api/boards`)
      .then(function (response) {
        const { data } = response.data;
        return data;
      });
    return result;
  } catch (error) {
    console.log("error apiGetBodies");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiGetActiveBoards = async (server) => {
  try {
    const result = await axios
      .get(`${server}/api/boards?status=active`)
      .then(function (response) {
        const { data } = response.data;
        return data;
      });
    return result;
  } catch (error) {
    console.log("error apiGetBodies");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiAddMotion = async (server, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${server}/api/motions`,
        {
          data,
        },
        config
      )
      .then(function (response) {
        return response.data;
      });
    return result;
  } catch (error) {
    console.log("error apiAddMotion");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiAddBoard = async (server, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .post(
        `${server}/api/boards`,
        {
          data,
        },
        config
      )
      .then(function (response) {
        const { data } = response.data;
        return data;
      });
    return result;
  } catch (error) {
    console.log("error apiAddBoard");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiUpdateBoard = async (server, board, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .put(
        `${server}/api/boards/${board}`,
        {
          data,
        },
        config
      )
      .then(function (response) {
        const { data } = response.data;
        return data;
      });
    return result;
  } catch (error) {
    console.log("error apiUpdateBoard");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiDeleteMotion = async (server, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .delete(`${server}/api/motions/${id}`, config)
      .then(function (response) {
        return response.data;
      });
    return result;
  } catch (error) {
    console.log("error apiDeleteMotion");
    console.log(error);
    return { error: "issue" };
  }
};

export const apiDeleteBoard = async (server, id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios
      .delete(`${server}/api/boards/${id}`, config)
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response.data;
          return data;
        } else {
          return "error";
        }
      });
    return result;
  } catch (error) {
    console.log("error apiDeleteBoard");
    console.log(error);
    return "error";
  }
};
