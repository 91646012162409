import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import array from "lodash/array";

import { apiCreateImport } from "../../api/members";
import { importUserState, managerUserState } from "../../atoms/user.atom";
import { backendServerState } from "../../atoms/settings.atom";

import { AiFillDelete } from "react-icons/ai";
import MemberReviewItem from "./MemberReviewItem";
import Loading from "../Shared/Loading";

const MemberReviewList = ({ visible, importFinish }) => {
  const user = useRecoilValue(managerUserState);
  const server = useRecoilValue(backendServerState);
  const [members, setMembers] = useRecoilState(importUserState);
  const [temp, setTemp] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleAdd(value) {
    setTemp([...temp, value]);
    const tempArray = Array.from(members);
    const newArray = array.remove(tempArray, function (n) {
      return n.granicusId !== value.granicusId;
    });
    setMembers(newArray);
  }

  function handleRemove(value) {
    const tempArray = Array.from(temp);
    const newArray = array.remove(tempArray, function (n) {
      return n.granicusId !== value.granicusId;
    });
    setTemp(newArray);
    let tempMembers = Array.from(members);
    tempMembers.unshift(value);
    setMembers(tempMembers);
  }

  async function handleImport() {
    setLoading(true);
    const result = await apiCreateImport(server, temp, user?.token);
    if (result === "success") {
      setLoading(false);
      setMembers([]);
      importFinish();
    } else {
      // TODO error notification here
    }
  }

  return (
    <div>
      <div className="flex justify-between mb-4">
        <p className="text-lg font-medium">Granicus User Import</p>
        <div>
          <button
            className="text-black font-medium rounded-lg bg-indigo-300 px-4 py-2 mr-4"
            onClick={() => {
              setMembers([]);
              visible(false);
            }}
          >
            Cancel
          </button>
          <button
            className="text-black font-medium rounded-lg bg-green-600 px-4 py-2"
            onClick={() => handleImport()}
          >
            Import
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-6">
        {temp.length > 0 && (
          <>
            <div className="col-span-2 lg:col-span-3 border-b border-black">
              <p className="text-black font-medium text-lg">To Import: </p>
            </div>

            {temp.map((item, index) => (
              <div
                key={index}
                className="p-4 bg-gray-50 rounded-xl border border-gray-600 shadow-md flex justify-between"
              >
                <p>
                  {item.firstName} {item.lastName}
                </p>
                <AiFillDelete
                  className="text-red-800 w-6 h-6 hover:text-red-600 transform duration-300 cursor-pointer"
                  onClick={() => handleRemove(item)}
                />
              </div>
            ))}
          </>
        )}
        <div className="col-span-2 lg:col-span-3 border-b border-black">
          <p className="text-black font-medium text-lg">Users Available: </p>
        </div>
        {members?.map((member, index) => {
          return (
            <MemberReviewItem member={member} key={index} add={handleAdd} />
          );
        })}
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default MemberReviewList;
