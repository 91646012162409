import React, { useState } from "react";
import { motion } from "framer-motion";

import { AiOutlineClose } from "react-icons/ai";

const PasswordResetModal = ({ isVisible, resetPassword }) => {
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [allowSave, setAllowSave] = useState(false);

  React.useEffect(() => {
    if (pass1.length >= 8 && pass2.length >= 8) {
      if (pass1 === pass2) {
        setAllowSave(true);
      }
    }
  }, [pass1, pass2]);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <motion.div
            className="absolute inset-0 bg-blueGray-800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            transition={{ duration: 0.4 }}
            onClick={() => isVisible(false)}
          ></motion.div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6"
        >
          <div className="grid">
            <div className="flex justify-between items-center">
              <p className="font-semibold text-gray-800 text-xl">
                User Password Reset
              </p>
              <AiOutlineClose
                className="h-6 w-6 text-gray-800 cursor-pointer hover:text-gray-600"
                onClick={() => isVisible(false)}
              />
            </div>

            <div className="flex flex-col px-6 mb-12 mt-12">
              <div className="">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Password
                  <span className="ml-2 font-light text-gray-600 text-xs">
                    Minimum 8 characters
                  </span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                      type="password"
                      id="pass1"
                      required
                      value={pass1}
                      className="flex-1 block p-2 w-full h-8 shadow-sm border border-gray-400 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm "
                      onChange={(e) => setPass1(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="testcode"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Confirm Password
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                      type="password"
                      id="pass2"
                      required
                      value={pass2}
                      className="flex-1 block w-full p-2 h-8 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-400"
                      onChange={(e) => setPass2(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between px-6">
              <button
                className="mb-1 inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base xl:text-lg font-medium rounded-md text-white bg-gray-600 hover:bg-opacity-70 focus:outline-none transform duration-300"
                onClick={() => {
                  isVisible(false);
                }}
              >
                Cancel
              </button>
              {allowSave && (
                <button
                  className="mb-1 inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base xl:text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-opacity-70 focus:outline-none transform duration-300"
                  onClick={() => {
                    resetPassword(pass1);
                  }}
                >
                  Change Password
                </button>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PasswordResetModal;
