import axios from "axios";

export const apiCivicMeetings = async (entity, type, token, server, board) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const typeFormed = type.toLowerCase().replace(/\s+/g, "");
    const civicResult = await axios
      .get(
        `${process.env.REACT_APP_SYNC_URL}/civic/meetings/${entity}?type=${typeFormed}`
      )
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response;
          return data;
        } else {
          return null;
        }
      });
    // Now take the result and verify none of those exist in the current database before adding them into the list
    const result = await axios
      .put(
        `${server}/api/session/civic/sync`,
        {
          civicResult,
          board,
        },
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response;
          return data;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.log("error api civicClerk apiCivicMeetings");
    console.log(error);
    return null;
  }
};

export const apiCivicSpecific = async (entity, id) => {
  try {
    const result = await axios
      .get(`${process.env.REACT_APP_SYNC_URL}/civic/agenda/${entity}/${id}`)
      .then(function (response) {
        if (response.status === 200) {
          const { data } = response;
          return data;
        } else {
          return null;
        }
      });
    return result;
  } catch (error) {
    console.log("error api civicClerk apiCivicSpecific");
    console.log(error);
    return null;
  }
};
