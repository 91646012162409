export const getSessionStorage = (key) => {
  try {
    const item = sessionStorage.getItem(key);
    const returnItem = JSON.parse(item);
    return returnItem;
  } catch (e) {
    console.error("error storage getSessionStorage");
    console.error(e);
    return null;
  }
};

export const getSessionToken = () => {
  try {
    const item = sessionStorage.getItem("user");
    const { token } = JSON.parse(item);
    return token;
  } catch (error) {
    console.error("error storage getSessionToken");
    console.error(error);
    return null;
  }
};

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const deleteSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const getLocalStorage = (key) => {
  try {
    const item = localStorage.getItem(key);
    const returnItem = JSON.parse(item);
    return returnItem;
  } catch (e) {
    console.error("error storage getLocalStorage");
    console.error(e);
    return null;
  }
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const deleteLocalStorage = (key) => {
  localStorage.removeItem(key);
};
