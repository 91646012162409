import React from "react";
import ActiveTopicCard from "./ActiveTopicCard";

function ActiveTopicList({ topics, refresh }) {
  return (
    <div className="flex flex-col mb-32">
      {topics?.map((topic, index) => (
        <ActiveTopicCard topic={topic} key={index} refresh={refresh} />
      ))}
    </div>
  );
}

export default ActiveTopicList;
