/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import clsx from "clsx";

import {
  countdownState,
  liveSpeakerState,
  podiumMicState,
} from "../../atoms/session.atom";
import { speakerTimeNumberState } from "../../atoms/globals.atom";
import { soloSettingsState } from "../../atoms/settings.atom";

import { Transition } from "@headlessui/react";
import { MdTimer } from "react-icons/md";

function TimerActiveManager({ show, sendEvent }) {
  const countdown = useRecoilValue(countdownState);
  const liveSpeaker = useRecoilValue(liveSpeakerState);
  const speakerTimerNumber = useRecoilValue(speakerTimeNumberState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const [podiumMic, setPodiumMic] = useRecoilState(podiumMicState);

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed bottom-32 left-0 right-0 flex items-end px-4 sm:items-start sm:px-6 max-w-3xl mx-auto"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-center">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx(
                speakerTimerNumber <= 30 &&
                  speakerTimerNumber > 10 &&
                  "bg-yellow-400",
                speakerTimerNumber <= 10 && "bg-red-500",
                "pointer-events-auto w-full max-w-xl overflow-hidden rounded-lg bg-blue-300 shadow-md"
              )}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <MdTimer
                      className="h-6 2xl:h-8 w-6 2xl:w-8 text-indigo-800"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <div className="flex items-start justify-center">
                      <p className="2xl:text-xl hd:text-2xl text-gray-800 font-medium mr-4">
                        {countdown}
                      </p>
                      {liveSpeaker && typeof liveSpeaker === "object" && (
                        <p className="mt-1 2xl:text-xl hd:text-2xl text-gray-800 font-medium">
                          {liveSpeaker.firstName} {liveSpeaker.lastName}
                        </p>
                      )}
                      {liveSpeaker && typeof liveSpeaker === "string" && (
                        <p className="mt-1 2xl:text-xl hd:text-2xl text-gray-800 font-medium">
                          {liveSpeaker}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="items-center justify-center flex flex-nowrap w-full pb-2 mt-4">
                  <button
                    className="px-4 py-2 mr-4 relative inline-flex items-center justify-center rounded-md bg-green-600 text-base xl:text-lg font-medium text-white hover:bg-green-400 transform duration-200 focus:outline-none mt-4"
                    onClick={() => {
                      sendEvent("serverSpeaker", {
                        command: "unpause",
                      });
                    }}
                  >
                    Start
                  </button>
                  <button
                    className="px-4 py-2 mr-4 relative inline-flex items-center justify-center rounded-md bg-zinc-200 text-base xl:text-lg font-medium text-gray-700 hover:bg-zinc-50 transform duration-200 focus:outline-none mt-4"
                    onClick={() => {
                      sendEvent("serverSpeaker", {
                        command: "add",
                      });
                    }}
                  >
                    +1 Minute
                  </button>
                  <button
                    className="px-4 py-2 mr-4 relative inline-flex items-center justify-center rounded-md bg-red-700 text-base xl:text-lg font-medium text-white hover:bg-red-600 transform duration-200 focus:outline-none mt-4"
                    onClick={() => {
                      sendEvent("serverSpeaker", {
                        command: "pause",
                      });
                    }}
                  >
                    Pause
                  </button>
                  <button
                    className="px-4 py-2 mr-4 relative inline-flex items-center justify-center rounded-md bg-zinc-200 text-base xl:text-lg font-medium text-gray-700 hover:bg-zinc-50 transform duration-200 focus:outline-none  mt-4"
                    onClick={() => {
                      sendEvent("serverSpeaker", {
                        command: "minus",
                      });
                    }}
                  >
                    -1 Minute
                  </button>
                  <button
                    className="px-4 py-2 relative inline-flex items-center justify-center rounded-md bg-indigo-500 text-base xl:text-lg font-medium text-white hover:bg-indigo-300 transform duration-200 focus:outline-none mt-4"
                    onClick={() => {
                      sendEvent("serverSpeaker", {
                        command: "reset",
                      });
                    }}
                  >
                    Reset
                  </button>
                </div>
                {soloSettings?.podiumMuteButton && (
                  <div className="flex items-center justify-center">
                    <button
                      className={clsx(
                        podiumMic
                          ? "bg-green-600 text-white hover:bg-green-400"
                          : "bg-gray-400 text-black hover:bg-green-400",
                        "p-4 rounded-md text-base xl:text-lg font-medium transform duration-200 focus:outline-none mt-4"
                      )}
                      onClick={() => {
                        let command = "podiumOn";
                        if (podiumMic) {
                          command = "podiumOff";
                        }
                        setPodiumMic(!podiumMic);
                        sendEvent("customButton", {
                          command,
                        });
                      }}
                    >
                      Podium Mic
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

export default TimerActiveManager;
