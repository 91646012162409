import React, { Fragment } from "react";
import { useRecoilValue, useRecoilState } from "recoil";

import { soloSettingsState } from "../../atoms/settings.atom";
import { motionNotesState } from "../../atoms/notes.atom";

import { userState } from "../../atoms/user.atom";
import { apiUpdateTopic } from "../../api/topics";

import { Dialog, Transition } from "@headlessui/react";
import { AnnotationIcon } from "@heroicons/react/outline";

const NotesModal = ({ topic, isVisible }) => {
  const soloSettings = useRecoilValue(soloSettingsState);
  const [notes, setNotes] = useRecoilState(motionNotesState);
  const user = useRecoilValue(userState);

  async function handleNotesSave() {
    const result = await apiUpdateTopic(
      soloSettings.backendServerUrl,
      { motionNotes: notes },
      topic,
      user?.token
    );
    if (result !== "error") {
      isVisible(false);
    } else {
      console.log("todo show error notification");
    }
  }

  return (
    <Transition.Root show={true} appear={true} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={true}
        onClose={() => isVisible(false)}
      >
        <div className="items-end justify-center min-h-screen text-center block p-0">
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen mt-40"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block space-y-4 bg-gradient-to-b from-gray-200 to-gray-400 rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:max-w-xl sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-white">
                  <AnnotationIcon
                    className="h-6 w-6 text-gray-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="text-center mt-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Motion Notes
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-black">
                      Please enter your motion notes for this action...
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid gap-4">
                <textarea
                  name="details"
                  rows="6"
                  value={notes}
                  className="mt-1 block w-full border-2 border-coolGray-400 bg-coolGray-700 text-coolGray-200 rounded-md py-2 px-3 sm:text-sm"
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                ></textarea>
              </div>
              <div>
                <button
                  type="button"
                  className="mt-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:text-sm"
                  onClick={() => {
                    handleNotesSave();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NotesModal;
