import React from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

const MeetingList = ({ meetings, action }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 xl:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full px-1 xl:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Board
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-1 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Run Date
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 xl:px-6 xl:py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase"
                  ></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {meetings?.map((meeting, id) => {
                  return (
                    <tr key={meeting.id}>
                      <td className="px-4 py-2 xl:px-6 whitespace-nowrap text-sm xl:text-base text-gray-900">
                        {meeting.board}
                      </td>
                      <td className="px-0.5 xl:px-2 xl:py-2 whitespace-nowrap">
                        {format(parseISO(meeting.endTime), "MMM do")}
                      </td>
                      <td className="px-1 py-1 xl:px-2 xl:py-2 whitespace-nowrap text-center text-sm 2xl:text-base font-medium">
                        <div
                          className="text-white hover:bg-opacity-70 cursor-pointer bg-indigo-600 rounded-md py-2 transform duration-300"
                          onClick={() => action(meeting)}
                        >
                          Restore
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingList;
