export function randomPinCode() {
  return Math.floor(1000 + Math.random() * 9000);
}

export function randomTestCode() {
  return Math.floor(1000 + Math.random() * 9000);
}

export function convertSeconds(s) {
  var min = Math.floor(s / 60);
  var sec = s % 60;
  if (sec.toString().length < 2) {
    sec = "0" + sec;
  }
  return min + ":" + sec;
}