import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import TypePlixus from "./TypePlixus";
import TypeConfero from "./TypeConfero";

export default function NewPluginSlideOut({
  isVisible,
  createPlugin,
  pluginType,
  editPlugin,
  removePlugin,
}) {
  function handleClose() {
    isVisible(false);
  }

  return (
    <Transition.Root show={true} appear={true} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={true}
        onClose={handleClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-slate-900 bg-opacity-50 transform duration-300" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-out duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-3xl">
                <div className="h-full flex flex-col bg-slate-800 overflow-y-scroll">
                  <div className="px-6 py-2 border-b border-zinc-600">
                    <div className="flex items-center justify-between">
                      <p className="text-zinc-300">{pluginType?.label}</p>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className="rounded-md text-zinc-400 hover:text-opacity-75 focus:outline-none"
                          onClick={() => handleClose()}
                        >
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Content */}
                  {pluginType?.value === "plixus" && (
                    <TypePlixus
                      create={createPlugin}
                      editPlugin={editPlugin}
                      removePlugin={removePlugin}
                    />
                  )}
                  {pluginType?.value === "confero" && (
                    <TypeConfero
                      create={createPlugin}
                      editPlugin={editPlugin}
                      removePlugin={removePlugin}
                    />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
