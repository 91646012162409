export const calculateTally = (array) => {
  let yea = 0;
  let nay = 0;
  for (let index = 0; index < array.length; index++) {
    const voteValue = array[index].vote;
    switch (voteValue) {
      case "yea":
        yea++;
        break;
      case "nay":
        nay++;
        break;
      default:
        break;
    }
  }
  return `${yea} Yea - ${nay} Nay`;
};
