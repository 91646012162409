import React from "react";
import { useRecoilValue } from "recoil";

import { soloSettingsState } from "../../atoms/settings.atom";
import MemberRow from "./MemberRow";

const MemberList = ({ members, edit, refresh }) => {
  const soloSettings = useRecoilValue(soloSettingsState);
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto 2xl:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  {/* <th
                    scope="col"
                    className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    District
                  </th> */}
                  <th
                    scope="col"
                    className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Default Seat
                  </th>
                  {soloSettings?.memberTimer && (
                    <th
                      scope="col"
                      className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Bypass Timer
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Voting
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Board Admin
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-1 xl:px-6 xl:py-3 bg-gray-50"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {members?.map((member, index) => (
                  <MemberRow
                    member={member}
                    edit={edit}
                    key={member._id}
                    refresh={refresh}
                    index={index}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberList;
