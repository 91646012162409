import React from "react";

function MobileUnSupported() {
  return (
    <div className="bg-white overflow-hidden shadow h-screen w-full md:hidden my-4">
      <div>
        <img
          className="mx-auto h-40 w-auto"
          src="/images/LogoBlue.png"
          alt=""
        />
      </div>
      <div className="mt-4 text-center md:mt-0 md:pt-1 md:text-left">
        <p className="text-xl font-bold text-gray-900 md:text-2xl">
          This feature is only available on larger screens.
        </p>
      </div>
    </div>
  );
}

export default MobileUnSupported;
