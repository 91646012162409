import { atom } from "recoil";

export const importedTopicsState = atom({
  key: "importedItems",
  default: [],
});

export const importReviewState = atom({
  key: "importReview",
  default: false,
});
