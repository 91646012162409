import React from "react";
import { useRecoilValue } from "recoil";
import { endingNotesState } from "../../atoms/session.atom";

import { FiUsers } from "react-icons/fi";

const ConfirmEndModal = ({ action, testMode }) => {
  const endingNotes = useRecoilValue(endingNotesState);
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="bg-white rounded-md text-gray-800 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                action({ type: "cancel" });
              }}
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-300 sm:mx-0 sm:h-10 sm:w-10">
              <FiUsers className="h-6 w-6 text-gray-800" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg 2xl:text-3xl leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {testMode ? "End Test Meeting?" : "End the Meeting?"}
              </h3>
              {!testMode && (
                <div className="mt-2">
                  <p className="text-sm lg:text-lg text-gray-500">
                    This is an active meeting. Once you end this meeting is will
                    be considered completed.
                  </p>
                </div>
              )}

              {endingNotes.length > 0 && (
                <div className="mt-2">
                  <p className="italic">Ending Notes:</p>
                  <p className="text-sm lg:text-lg text-indigo-700 font-medium">
                    {endingNotes}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex justify-between">
            {!testMode && (
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base lg:text-lg font-medium text-gray-900 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  action({ type: "exit" });
                }}
              >
                Logout Only
              </button>
            )}

            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base lg:text-lg font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                action({ type: "confirm" });
              }}
            >
              End the Meeting
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEndModal;
