import React from "react";

import Actions from "./Actions";

const MeetingRow = ({ meeting, action }) => {
  function handleAction(value) {
    action({ type: value, meeting });
  }

  return (
    <tr>
      <td className="px-4 py-2 xl:px-6 whitespace-nowrap text-sm xl:text-base text-gray-900">
        {meeting.scheduleDate}
      </td>
      <td className="px-0.5 xl:px-2 xl:py-2 whitespace-nowrap">
        {meeting.active && (
          <span className="py-1 px-4 inline-flex text-sm xl:text-base font-semibold rounded-full bg-green-500 text-gray-50">
            Active
          </span>
        )}
        {meeting.testMode && (
          <span className="py-1 px-4 inline-flex text-sm xl:text-base font-semibold rounded-full bg-gray-600 text-white">
            Test
          </span>
        )}
        {!meeting.active && !meeting.testMode && (
          <span className="inline-flex text-sm xl:text-base font-semibold rounded-full bg-transparent text-grey-800"></span>
        )}
      </td>
      <td className="px-1 py-1 xl:px-2 xl:py-2 whitespace-nowrap text-center text-sm 2xl:text-base font-medium">
        <div
          className="text-white hover:bg-opacity-70 cursor-pointer bg-indigo-600 rounded-md py-2 transform duration-300"
          onClick={() => action({ type: "details", meeting })}
        >
          Details
        </div>
      </td>
      <td className="px-1 py-1 xl:px-2 xl:py-2 whitespace-nowrap text-center text-sm 2xl:text-base font-medium">
        <Actions action={handleAction} />
      </td>
    </tr>
  );
};

export default MeetingRow;
