import React from "react";

const OptionItem = ({ name, action, active, index }) => {
  if (active === index) {
    return (
      <div className="whitespace-nowrap pt-4 pb-1.5 px-1 border-b-2 border-indigo-500 font-medium text-sm xl:text-base text-indigo-500 transform duration-300">
        {name}
      </div>
    );
  } else {
    return (
      <div
        className="cursor-pointer whitespace-nowrap pt-4 pb-1.5 px-1 border-b-2 border-transparent font-medium text-sm xl:text-base text-gray-500 hover:text-indigo-500 hover:border-indigo-500 transform duration-300"
        onClick={() => action(index)}
      >
        {name}
      </div>
    );
  }
};

export default OptionItem;
