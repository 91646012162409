import React from "react";
import { motion } from "framer-motion";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  topicPreviewState,
  topicPreviewDetailsState,
  menuChoiceState,
  testModeState,
} from "../../atoms/globals.atom";
import {
  activeTopicState,
  activeDetailsState,
  sessionState,
  topicSpeakersState,
} from "../../atoms/session.atom";
import { userState } from "../../atoms/user.atom";

function ModalTopicDetails({ sendEvent }) {
  const setTopicPreview = useSetRecoilState(topicPreviewState);
  const setActiveDetails = useSetRecoilState(activeDetailsState);
  const setTopicSpeakers = useSetRecoilState(topicSpeakersState);
  const setActiveTopic = useSetRecoilState(activeTopicState);
  const setMenuChoice = useSetRecoilState(menuChoiceState);
  const topic = useRecoilValue(topicPreviewDetailsState);
  const sessionData = useRecoilValue(sessionState);
  const user = useRecoilValue(userState);
  const testMode = useRecoilValue(testModeState);

  function handleRollBack() {
    setActiveTopic(topic._id);
    setActiveDetails(topic);
    if (topic.speakers.length > 0) {
      setTopicSpeakers(topic.speakers);
    } else {
      setTopicSpeakers([]);
    }
    setMenuChoice(1);
    // send event to show the current active topic to other members
    sendEvent("agendaRollBack", {
      session: sessionData,
      id: topic._id,
      testMode,
    });
    setTopicPreview(false);
  }

  return (
    <section className="fixed inset-0 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <motion.div
          className="absolute inset-0 bg-gray-100 bg-opacity-50"
          onClick={() => setTopicPreview(false)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        ></motion.div>

        <motion.div
          className="absolute inset-y-0 max-w-full left-0 flex"
          initial={{ x: "-400%" }}
          animate={{ x: 0 }}
          transition={{ duration: 0.25 }}
        >
          <div className="w-screen max-w-lg">
            <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
              <div className="flex-1 h-0 overflow-y-auto">
                <div className="py-6 px-4 bg-gray-700 sm:px-6">
                  <div className="flex items-center justify-between">
                    <h2
                      className="text-lg font-medium text-white"
                      id="slide-over-title"
                    >
                      Details
                    </h2>
                    <div className="ml-3 h-7 flex items-center">
                      <button
                        className="bg-gray-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setTopicPreview(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col justify-between">
                  <div className="px-4 divide-y divide-gray-200 sm:px-6">
                    <div className="space-y-4 pt-6 pb-5">
                      <div>
                        <label className="block text-sm font-medium text-gray-500 italic">
                          Item Number
                        </label>
                        <div className="mt-1">
                          <p className="text-md 2xl:text-lg">
                            {topic?.itemNumber}
                          </p>
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-500 italic">
                          Title
                        </label>
                        <div className="mt-1">
                          <p className="text-md 2xl:text-lg">{topic?.title}</p>
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-500 italic">
                          Details
                        </label>
                        <div className="mt-1">
                          <p className="text-md 2xl:text-lg">{topic?.more}</p>
                        </div>
                      </div>
                      {user?.adminRole && (
                        <div>
                          <label className="block text-sm font-medium text-gray-500 italic">
                            Annotations
                          </label>
                          <div className="mt-1">
                            {topic && topic.notes?.length > 0 && (
                              <p className="text-md 2xl:text-lg">
                                {topic.notes}
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      <div>
                        <label className="block text-sm font-medium text-gray-500 italic">
                          Speakers
                        </label>
                        <div className="mt-1">
                          {topic?.speakers.length > 0 && (
                            <div>
                              {topic?.speakers.map((speaker, index) => {
                                return (
                                  <p
                                    className="text-md 2xl:text-lg"
                                    key={index}
                                  >
                                    {speaker.firstName} {speaker.lastName}
                                  </p>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="pt-4 pb-6"></div>
                  </div>
                </div>
              </div>
              {user?.managerRole && (
                <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button
                    type="button"
                    className="bg-white py-4 px-4 border border-gray-300 rounded-md shadow-sm text-sm 2xl:text-lg font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setTopicPreview(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="ml-4 inline-flex justify-center py-4 px-4 border border-transparent shadow-sm text-sm 2xl:text-lg font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => handleRollBack()}
                  >
                    Roll Back
                  </button>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}

export default ModalTopicDetails;
