import React from "react";

const ControlRow = ({ button, remove, index }) => {
  return (
    <tr>
      <td className="px-4 py-1 2xl:px-6 2xl:py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div className="text-base xl:text-2xl font-medium text-gray-900">
            {button.title}
          </div>
        </div>
      </td>
      <td className="px-4 py-1 2xl:px-6 2xl:py-3 whitespace-nowrap">
        <div className="text-base xl:text-2xl text-gray-900">
          {button.command}
        </div>
      </td>

      <td className="px-4 py-1 2xl:px-6 2xl:py-3 whitespace-nowrap text-right text-base xl:text-2xl font-medium">
        <div
          className="text-red-600 border border-red-600 hover:text-indigo-900 cursor-pointer text-center rounded-md"
          onClick={() => remove(index)}
        >
          Delete
        </div>
      </td>
    </tr>
  );
};

export default ControlRow;
