import React from "react";

const Toggle = ({ value, action }) => {
  if (value) {
    return (
      <button
        type="button"
        aria-pressed="false"
        aria-labelledby="toggleLabel"
        className="bg-indigo-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => action(!value)}
      >
        <span
          aria-hidden="true"
          className="translate-x-5 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        ></span>
      </button>
    );
  } else {
    return (
      <button
        type="button"
        aria-pressed="false"
        aria-labelledby="toggleLabel"
        className="bg-gray-300 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => action(!value)}
      >
        <span
          aria-hidden="true"
          className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        ></span>
      </button>
    );
  }
};

export default Toggle;
